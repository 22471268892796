import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TPCInterface } from '@comp/object/control-cartera/catalogos/productos-credito/tipo-producto-credito.interface';

@Injectable()
export class TPCService {

    constructor(private http: HttpClient) {}

 /**
  * Consulta de Tipos de productos de cresito
  */
 public getTpc(pTPC): Observable<TPCInterface> {
    return this.http.post('api/control-cartera/catalogos/productos-credito/tipos-productos-credito/getTpc', 
        { 'pTPC': pTPC})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  * Agregar de Tipos de productos de cresito
  */
 public agregarTPC(pTPC): Observable<TPCInterface> {
    return this.http.post('api/control-cartera/catalogos/productos-credito/tipos-productos-credito/agregarTpc', 
        { 'pTPC': pTPC})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  * Modificar de Tipos de productos de cresito
  */
 public modificarTPC(pTPC): Observable<TPCInterface> {
    return this.http.post('api/control-cartera/catalogos/productos-credito/tipos-productos-credito/editarTpc', 
        { 'pTPC': pTPC})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  * Eliminar de Tipos de productos de cresito
  */
 public eliminarTPC(pTPC): Observable<TPCInterface> {
    return this.http.post('api/control-cartera/catalogos/productos-credito/tipos-productos-credito/eliminarTpc', 
    { 'pTPC': pTPC})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
}