import { Component, EventEmitter, Input, Output } from "@angular/core";
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
    selector: 'app-speed-dial-fab',
    templateUrl: './speed-dial-fab.component.html',
    styleUrls: ['./speed-dial-fab.component.scss'],
    animations: speedDialFabAnimations
  })
  export class SpeedDialFabComponent {
  
    @Input() fabButtons;

    @Input() icon: string;

    @Output() clickFAB = new EventEmitter<string>();
    
    buttons = [];
    fabTogglerState = 'inactive';
  
    constructor() { }
  
    showItems() {
      this.fabTogglerState = 'active';
      this.buttons = this.fabButtons;
    }
  
    hideItems() {
      this.fabTogglerState = 'inactive';
      this.buttons = [];
    }
  
    onToggleFab() {
      this.buttons.length ? this.hideItems() : this.showItems();
    }

    clickButton(btn){
      this.clickFAB.emit(btn.icon);
    }
  }