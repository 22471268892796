import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayMovComisionGarantia } from "@comp/object/control-cartera/operacion/prestamos/mov-comision-garantia/arraymovcomisiongarantia.interface";


@Injectable()
export class MinistracionesService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getGarantia(jsonMCP): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/ministraciones/DatosDG', { jsonMCP: jsonMCP }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarGarantia(jsonMCP): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/ministraciones/eliminarG', { jsonMCP: jsonMCP }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getFechaVen(jsonPMI, jsonPRC,jsonMP): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/ministraciones/FechaVen', 
        { jsonPMI: jsonPMI, jsonPRC: jsonPRC, jsonMP: jsonMP}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getFechaFin(jsonPMI, jsonPRC): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/ministraciones/FechaFin', { jsonPMI: jsonPMI, jsonPRC: jsonPRC }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}