import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicMaterialFormInputControlComponent } from "../dynamic-material-form-input-control.component";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicInputModel } from "@comp/dynamic-form/core/model/input/dynamic-input.model";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";

@Component({
    selector: "dynamic-material-hidden",
    templateUrl: "./dynamic-material-hidden.component.html"
})
export class DynamicMaterialHiddenComponent extends DynamicMaterialFormInputControlComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicInputModel;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService) {

        super(layoutService, validationService);
    }
}
