import { MatInput } from "@angular/material/input";
import { DynamicFormControlComponent } from "../core/component/dynamic-form-control.component";
import { DynamicFormLayoutService } from "../core/service/dynamic-form-layout.service";
import { DynamicFormValidationService } from "../core/service/dynamic-form-validation.service";
import { DynamicInputControlModel } from "../core/model/dynamic-input-control.model";
import { DynamicInputModel } from "../core/model/input/dynamic-input.model";

export abstract class DynamicMaterialFormInputControlComponent extends DynamicFormControlComponent {

    matInput: MatInput | undefined;

    protected constructor(protected layoutService: DynamicFormLayoutService,
                          protected validationService: DynamicFormValidationService) {

        super(layoutService, validationService);
    }

    get characterCount(): number | null {
        return this.matInput ? this.matInput.value.length : null;
    }

    get characterHint(): string {
        return `${this.characterCount} / ${(this.model as DynamicInputControlModel<string>).maxLength}`;
    }

    get showCharacterHint(): boolean {
        return !!((this.model as DynamicInputModel).maxLength && this.characterCount);
    }
}
