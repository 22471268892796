import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DesconciliacionRapidaService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de pagos 
     */
    public getPagos(jsonPpr, jsonPpge, jsonPot ): Observable<any[]> {
        return this.http.post("api/control-cartera/operacion/extraordinarios/desconciliacionrapida/getPagos", 
        {   "jsonPpr": jsonPpr,
            "jsonPpge": jsonPpge,
            "jsonPot": jsonPot
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     *  Metodo de desconciliación
     */
    public desconciliacion(jsonMp, jsonPpr,jsonPpge): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/extraordinarios/desconciliacionrapida/insertDesconcilia', 
        {  "jsonMp": jsonMp,
            "jsonPpr": jsonPpr,
            "jsonPpge":jsonPpge
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }
}