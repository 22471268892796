'use strict';

export enum Role {
    PERSONAL,
    CLIENTE
}
export class Datos {
    public active: boolean;
    public usuario: string;
    public role: Role;

    constructor(obj: any, role: Role) {
        if (obj !== null) {
            this.role = role;
            this.usuario = obj.usuario;
            this.active = obj.active;
        }
    }
}
