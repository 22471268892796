import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ArrayCreditos } from "@comp/object/inversionistas/operaciones/creditos-fondear/arraycreditos.interface";
import { AgrupacionCreditoFondear } from "@app/inversionistas/models/agrupacion-credito-fondear/agrupacioncreditofondear.model";
import { ArrayAgrupacionCreditosAFondear } from "@comp/object/inversionistas/operaciones/agrupacion-creditos-fondear/arrayagrupacioncreditosafondear.interface";
import { ArrayDetallesInversion } from "@comp/object/inversionistas/operaciones/detalles-inversion/arraydetallesinversion.interface";

@Injectable()
export class AgrupacionCreditosAFondearService {
    procesado: Observable<string>;
    constructor(private http: HttpClient) { }

    consultarAgrupacionCreditosAFondear(financiera: string, nocred:string): Observable<ArrayAgrupacionCreditosAFondear> {
        return this.http.post('api/inversionistas/operaciones/agrupacion-creditos-fondear/consultar', { id: { cdgfin: financiera, nocred:nocred } }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    registrarAgrupacionCredito(agrupacionCreditoFondear: AgrupacionCreditoFondear): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/agrupacion-creditos-fondear/insertar', agrupacionCreditoFondear
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarAgrupacionCredito(agrupacionCreditoAFondear: AgrupacionCreditoFondear): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/agrupacion-creditos-fondear/editar', agrupacionCreditoAFondear
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    eliminarCredito(agrupacionCreditoFondear: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/agrupacion-creditos-fondear/eliminar', {
            id: {
                cdgfin: agrupacionCreditoFondear.cdgfin,
                nocred: agrupacionCreditoFondear.nocred,
                secuencia: agrupacionCreditoFondear.secuencia
            }
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    
    filtrarAgrupacionCreditosAfondear(financiera: string, nocred: string, 
        cdgcl: string, finiciodesde: string, finiciohasta: string, 
        ffindesde: string, ffinhasta: string, montominimo: number, montomaximo: number,
        nombrecl:string): Observable<ArrayCreditos> {
        let montomin = null;
        let montomax = null;
        if (montominimo != null && montomaximo != null) {
            montomin = parseFloat(montominimo.toString());
            montomax = parseFloat(montomaximo.toString());
        }

        return this.http.post('api/inversionistas/operaciones/agrupacion-creditos-fondear/consultar', {
            id: {
                cdgfin: financiera,
                nocred:nocred,
                secuencia:null  
            },
                finiciodesde: finiciodesde,
                finiciohasta: finiciohasta,
                ffindesde: ffindesde,
                ffinhasta: ffinhasta,
                montominimo: montomin,
                montomaximo: montomax,
                cdgcl:cdgcl,
                nombrecl:nombrecl
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }
    public permitirSoloNumeros(event: any) {
        if ((event.which >= 48 && event.which <= 57)) {
            return true;
        } else {
            return false;
        }
    }

    buscarDetallesDeinversion(secuencia:string,cdgcl:string): Observable<ArrayDetallesInversion> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar',{cdgcl: cdgcl ,cdgpif: null, secuencia:secuencia }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    
}