import { DynamicMaterialHiddenComponent } from './hidden/dynamic-material-hidden.component';
import { DynamicHiddenModel, DYNAMIC_FORM_CONTROL_INPUT_TYPE_HIDDEN } from '@comp/dynamic-form/core/model/hidden/dynamic-hidden.model';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ContentChildren,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicMaterialDatePickerComponent } from "./datepicker/dynamic-material-datepicker.component";
import { DynamicMaterialInputComponent } from "./input/dynamic-material-input.component";
import { DynamicMaterialTextAreaComponent } from "./textarea/dynamic-material-textarea.component";
import { DynamicMaterialSlideToggleComponent } from "./slide-toggle/dynamic-material-slide-toggle.component";
import { DynamicMaterialCheckboxComponent } from "./checkbox/dynamic-material-checkbox.component";
import { DynamicMaterialSliderComponent } from "./slider/dynamic-material-slider.component";
import { DynamicMaterialRadioGroupComponent } from "./radio-group/dynamic-material-radio-group.component";
import { DynamicMaterialChipsComponent } from "./chips/dynamic-material-chips.component";
import { DynamicMaterialSelectComponent } from "./select/dynamic-material-select.component";
import { DynamicMaterialFormArrayComponent } from "./form-array/dynamic-material-form-array.component";
import { DynamicMaterialFormGroupComponent } from "./form-group/dynamic-material-form-group.component";
import { DynamicFormControlContainerComponent } from "../core/component/dynamic-form-control-container.component";
import { DynamicTemplateDirective } from "../core/directive/dynamic-template.directive";
import { DynamicFormArrayGroupModel, DYNAMIC_FORM_CONTROL_TYPE_ARRAY } from "../core/model/form-array/dynamic-form-array.model";
import { DynamicFormLayout, DynamicFormLayoutService } from "../core/service/dynamic-form-layout.service";
import { DynamicFormControlModel } from "../core/model/dynamic-form-control.model";
import { DynamicFormControlEvent } from "../core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "../core/service/dynamic-form-validation.service";
import { DynamicFormComponentService } from "../core/service/dynamic-form-component.service";
import { DynamicFormRelationService } from "../core/service/dynamic-form-relation.service";
import { DynamicFormControl } from "../core/component/dynamic-form-control-interface";
import { DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER } from "../core/model/datepicker/dynamic-datepicker.model";
import { DYNAMIC_FORM_CONTROL_TYPE_INPUT, DynamicInputModel } from "../core/model/input/dynamic-input.model";
import { DYNAMIC_FORM_CONTROL_TYPE_SELECT } from "../core/model/select/dynamic-select.model";
import { DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA } from "../core/model/textarea/dynamic-textarea.model";
import { DynamicFormValueControlModel } from "../core/model/dynamic-form-value-control.model";
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX } from "../core/model/checkbox/dynamic-checkbox.model";
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from "../core/model/checkbox/dynamic-checkbox-group.model";
import { DYNAMIC_FORM_CONTROL_TYPE_GROUP } from "../core/model/form-group/dynamic-form-group.model";
import { DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP } from "../core/model/radio/dynamic-radio-group.model";
import { DYNAMIC_FORM_CONTROL_TYPE_SLIDER } from "../core/model/slider/dynamic-slider.model";
import { DYNAMIC_FORM_CONTROL_TYPE_SWITCH } from "../core/model/switch/dynamic-switch.model";
import { DYNAMIC_FORM_CONTROL_TYPE_DIV_GROUP } from '../core/model/div/dynamic-div-group.model';
import { DynamicMaterialFormDivComponent } from './div/dynamic-material-div-group.component';
import { DynamicMaterialDateRangeComponent } from './date-range/dynamic-material-daterange.component';
import { DYNAMIC_FORM_CONTROL_TYPE_DATE_RANGE } from '../core/model/range/dynamic-date-range.model';

@Component({
    selector: "dynamic-material-form-control",
    templateUrl: "./dynamic-material-form-control-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMaterialFormControlContainerComponent extends DynamicFormControlContainerComponent {

    @ContentChildren(DynamicTemplateDirective) contentTemplateList: QueryList<DynamicTemplateDirective>;

    @HostBinding("class") klass;

    @Input() context: DynamicFormArrayGroupModel | null = null;
    @Input() group: FormGroup;
    @Input() hostClass: string[];
    @Input("templates") inputTemplateList: QueryList<DynamicTemplateDirective>;
    @Input() layout: DynamicFormLayout;
    @Input() model: DynamicFormControlModel;

    @Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output("matEvent") customEvent: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();

    @ViewChild("componentViewContainer", {read: ViewContainerRef}) componentViewContainerRef: ViewContainerRef;

    constructor(protected changeDetectorRef: ChangeDetectorRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                protected componentService: DynamicFormComponentService,
                protected relationService: DynamicFormRelationService) {

        super(changeDetectorRef, componentFactoryResolver, layoutService, validationService, componentService, relationService);
        
    }

    get componentType(): Type<DynamicFormControl> | null {
        return this.componentService.getCustomComponentType(this.model) || materialUIFormControlMapFn(this.model);
    }

    get hasMatFormField(): boolean {

        const matFormFieldTypes = [DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DYNAMIC_FORM_CONTROL_TYPE_INPUT,
            DYNAMIC_FORM_CONTROL_TYPE_SELECT, DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA];

        return matFormFieldTypes.some(type => this.model.type === type) || (
            this.model instanceof DynamicFormValueControlModel &&
            this.model.getAdditional("isFormFieldControl")
        );
    }
}

export function materialUIFormControlMapFn(model: DynamicFormControlModel): Type<DynamicFormControl> | null {

    switch (model.type) {

        case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
            return DynamicMaterialFormArrayComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
            return DynamicMaterialCheckboxComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
            return DynamicMaterialFormGroupComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
            return DynamicMaterialDatePickerComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
            return DynamicMaterialFormGroupComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_DIV_GROUP:
            return DynamicMaterialFormDivComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
            const inputModel = model as DynamicInputModel;
            if(inputModel.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_HIDDEN){
                return DynamicMaterialHiddenComponent;
            }

            return inputModel.multiple ? DynamicMaterialChipsComponent : DynamicMaterialInputComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
            return DynamicMaterialRadioGroupComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
            return DynamicMaterialSelectComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:
            return DynamicMaterialSliderComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
            return DynamicMaterialSlideToggleComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:
            return DynamicMaterialTextAreaComponent;

        case DYNAMIC_FORM_CONTROL_TYPE_DATE_RANGE:
            return DynamicMaterialDateRangeComponent;

        default:
            return null;
    }
}
