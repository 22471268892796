import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, from, Subject } from "rxjs";



@Injectable()
export class ComunicacionTabService {


    private subject = new Subject<any>();
    private subjectDeshabilitar = new Subject<any>();
    private subjecInsercion = new Subject<any>();
    private subjecHabilitarPestanas = new Subject<any>();
    private subjecActualizaFechaMaxSolicitud = new Subject<any>();
    public productoCredito: any ;
    public ciclo;
    public plazo: string;
    public cdgcl;
    public nombre;
    public pantalla = "Solicitud"
    public cdgmci;
    public nombrePrograma;
    public nombreFondo;
    public fechaEntrega;
    public cdgTPC;
    public cdgPCr;
    public fondoSeleccionado = false;
    public fValidoHastaFondo
    public insercionJSON = "";
    public periodicidad;
    public diajunta;
    public perigrcap;
    public desfasepago;
    public multper;
    public ultdia = false;
    public periprimpago = false;
    public cobpericero = false;
    public duracion;
    public formaEntrega;
    public domicilia;
    public productoSeleccionado;

    public erroresEnTabCredito = false;
    public erroresEnTabCreditoGeneral = false;
    public erroresEnTabCreditoCalendario = false;
    public erroresEnTabCreditoTasa = false;
    public erroresEnTabCreditoRecargos = false;
    public erroresEnTabCreditoEntrega = false;
    public erroresEnTabCreditoInstCrediticia = false;
    public erroresEnTabCreditoPerfilTraccional = false;


    public erroresEnTabSocioeconomico = false;
    public erroresEnTabSocioeconomicoEmpleo = false;
    public erroresEnTabSocioeconomicoDireccion = false;
    public cdgmo: string;
    public editar: boolean;
    public tabMinistracion: boolean = false;
    public datosBeneficiario;
    public datosMinistraciones;
    public datosGarantias;
    public fijaFen;
    public situacion;
    public borrarComision;
    public codigoPostal;
    public cantsol = 0;
    public productoEdicion;
    public fijapla;
    public fechaSolicitud = '';
    public permisos;
    public cdgemp;


    public selected = new FormControl(0);

    clickEvent() {
        this.subject.next();
    }

    obtenClickEvent() {
        return this.subject.asObservable();
    }

    clickEventHabilitarPestanas() {
        this.subjecHabilitarPestanas.next();
    }

    obtenClickEventHabilitarPestanas() {
        return this.subjecHabilitarPestanas.asObservable();
    }

    clickEventDeshabilitarSelectTCPPCR() {
        this.subjectDeshabilitar.next();
    }

    obtenClickEventDeshabilitarSelectTCPPCR() {
        return this.subjectDeshabilitar.asObservable();
    }

    clickEventCrearJSONInsercion() {
        this.subjecInsercion.next();
    }

    obtenClickEventCrearJSONINsercion() {
        return this.subjecInsercion.asObservable();
    }

    clickEventActualizarFechaMaxSolicitud() {
        this.subjecActualizaFechaMaxSolicitud.next();
    }


    obtenerClickActualizarFechaMaxSolicitud() {
        return this.subjecActualizaFechaMaxSolicitud.asObservable();
    }


    //getters -  setters

    
}