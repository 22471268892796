import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportesAccesoClientesService {

  constructor(private http: HttpClient) {}

  public CreditosGet(body): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/pr/getc', body).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
        
      })
    );
  }

  public estadoDeCuentaCCGetPDF(edoDeCuenta: any): Observable<any> {
    return this.http.post('api/control-cartera/reportes/estadodecuenta/getPDFcl', edoDeCuenta).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public obtenerEmpresa(cdgem: any): Observable<any> {
    return this.http.post('api/general/em/getcl', cdgem).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
}
