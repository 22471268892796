import Base from '@comp/object/base/Base';
import { Toolbar } from '@serv/toolbar.service';
import { Component, ContentChildren, QueryList, AfterContentInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { MatFormField } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';

export type FadeState = 'visible' | 'hidden';
export interface Filtro{
  id:string;
  value:string;
}

/**
 * @class Todo el contenido del pie de pagina
 */
@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
  animations: [
    trigger('state', [
      state(
        'visible',
        style({
          width: '100%',
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      state(
        'hidden',
        style({
          width: '0%',
          transform: 'translateX(-100%)',
          opacity: 0
          
        })
      ),
      transition('* => visible', [animate('500ms ease-out')]),
      transition('visible => hidden', [animate('500ms')])
    ])
  ]
})
export class FiltroComponent extends Base implements AfterContentInit{

  protected state: FadeState;

  private _show: boolean;

  @Output()
  private boton = new EventEmitter<Filtro[]>();

  @Output()
  private limpiar = new EventEmitter();

  get show() {
    return this._show;
  }

  @Input()
  set show(value: boolean) {
    if (value) {
      this._show = value
      setTimeout(()=> this.state = 'visible' , 100)
    } else {
      this.state = 'hidden';
    }
  }

  @ContentChildren(MatFormField) fields: QueryList<MatFormField>

  constructor(toolbar: Toolbar) {
    super(toolbar);
  }
 
  public ngAfterContentInit(): void {
    // this.fields.forEach(mffInstance => console.log(mffInstance));
  }

  animationDone(event: any) {
    if (event.fromState === 'visible' && event.toState === 'hidden') {
      this._show = false;
    }
  }

  animationStart(event: any): void {
    if ((event.fromState === null || event.fromState === 'hidden') && event.toState === 'visible') {
      this._show = true;
    }
  }

  changeState(): void{
    // console.log('changeState')
    if(!this.show){
      this.fields.forEach(mffInstance =>{
        mffInstance._control.value = "";
      });
    }else{
      this.limpiar.emit();
    }
    this.show = !this.show;
  }

  protected buscar(): void{
    const datos: Filtro[] = [];
    this.fields.forEach(mffInstance =>{
      datos.push({id:mffInstance._control.id,value:mffInstance._control.value})
    });
    this.boton.emit(datos);
  }
}
