import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
 
@Injectable()
export class AmortizacionConSolicitudService {

    constructor(private http: HttpClient) {}

    /**
     * Busqueda clientes individuales, grupales y clientes de grupo
     */
    public getBusqueda(cdgcl: string, cdgns:string, clns:string ): Observable<any[]> {
        return this.http.post("api/control-cartera/reportes/amortizacionconsolicitud/buscarP", 
        { "version": "0","cdgcl": cdgcl, "cdgns": cdgns, "nombre":"%", "tipo": clns }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }


    /**
     *  Busqueda del prestamos con solicitud
     */
    public getBuscarPrestamoCS(codigo: string, cdgns:string, ciclo:string, clns:string): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/amortizacionconsolicitud/buscar', 
        { "version": "0", "cdgcl": codigo ,"cdgns": cdgns, "ciclo": ciclo,"clns": clns}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

     /**
     *  Busqueda logo
     */
    public getLogo(): Observable<any[]>{
        return this.http.get('api/ahorros/empresa/logo', { }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    } 


}
