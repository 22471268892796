import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayProductoDeCredito } from "@comp/object/control-cartera/operacion/prestamos/producto-de-credito/arrayproductodecredito.interface";


@Injectable()
export class ProductoDeCreditoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getProductosDeCredito(cdgtpc: string, desglosepago: string, validohasta: string): Observable<ArrayProductoDeCredito> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/producto-credito/consultar', { cdgtpc: cdgtpc, desglosepago: desglosepago, validohasta: validohasta }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}