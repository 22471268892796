import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DatosEmpresaInterface } from '@comp/object/inversionistas/estadodecuenta/empresa.interface';
import { CreditosArrayInterface } from '@comp/object/inversionistas/estadodecuenta/creditos_Array.interface';
import { MovimientosArrayInterface } from '@comp/object/inversionistas/estadodecuenta/movimientos_Array.interface';
import { FondeadoresArrayInterface } from '@comp/object/inversionistas/estadodecuenta/fondeadores_array.service';
import { LineasCreditoArrayInterface } from '@comp/object/inversionistas/estadodecuenta/lineasCredito_array.interface';
import { InversionistasArrayInterface } from '@comp/object/inversionistas/estadodecuenta/inversionistas_array.interface';
import { EstadoDeCuentaResumidoArray } from '@comp/object/inversionistas/estadodecuenta/resumido/resumidoArray.interface'

moment.locale('es');

@Injectable()
export class EstadoCuentaService {

    constructor(private http: HttpClient) {}

    /**
     * Individual
     * Consulta reporte - Activos desde
     */
    public getActivosI(cdgfon: string, fecha:any ): Observable<CreditosArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teSeccion1/get",
        {  "cdgorf": cdgfon,
            "cdglc": "%",
            "fecha": moment(fecha).format('DD/MM/YYYY') }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Individual
     * Consulta reporte Seccion 2 - Movimientos
     */
    public getMovimientosI(cdgfon: string, desde:any, hasta:any ): Observable<MovimientosArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teSeccion2/get",
        { "cdgorf": cdgfon,
            "cdglc": "%",
            "fDesde": moment(desde).format('DD/MM/YYYY'),
            "fHasta": moment(hasta).format('DD/MM/YYYY') }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Consulta Clientes
     */
    public getDatosCl(cdg: string, nombre:string, tipo:string, inicio: number, limite: number): Observable<FondeadoresArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teFondeadores/get",
        { "codigo": cdg,
            "nombre": nombre,
             "tipo": tipo,
             "inicio": inicio,
             "limite": limite }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

     /**
     * Lineas de credito
     */
    public getLineasC(cdgorf: string): Observable<LineasCreditoArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teLineasCredInv/get",
        { "cdgorf": cdgorf }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Inversionistas
     */
    public getInversionistas(codigo: string, tipo: string): Observable<InversionistasArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teInversionistas/get",
        { "codigo": codigo,
            "tipoInv": tipo }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Grupal
     * Consulta reporte - Activos desde
     */
    public getActivosG(cdgfon: string, fecha:any ): Observable<CreditosArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teSeccion1/creditogrp",
        {  "cdggfo": cdgfon,
            "fecha": moment(fecha).format('DD/MM/YYYY') }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Grupal
     * Consulta reporte Seccion 2 - Movimientos
     */
    public getMovimientosG(cdgfon: string, desde:any, hasta:any ): Observable<MovimientosArrayInterface> {
        return this.http.post("api/inversionistas/reportes/estadodecuentas/teSeccion2/movgrp",
        { "cdggfo": cdgfon,
            "fDesde": moment(desde).format('DD/MM/YYYY'),
            "fHasta": moment(hasta).format('DD/MM/YYYY') }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Datos de la empresa
     */
    public getDatosE(): Observable<DatosEmpresaInterface> {
        return this.http.get("api/inversionistas/reportes/estadodecuentas/empresa/get").pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Logo de la empresa
     */
    public getLogoE(): Observable<any[]> {
        return this.http.get("api/inversionistas/reportes/estadodecuentas/empresa/logo"
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     * Formato de fecha
     */
    public getFecha(cdgps: String): Observable<any[]>{
        return this.http.post('api/general/vp/get', {
            "cdgps": cdgps, "desde": null
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
  public getExcel(matriz): Observable<any[]> {
    return this.http.post("api/inversionistas/reportes/estadodecuentas/excel/getXLSX",
    matriz, {responseType: 'blob'}
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)) )
      )
    }
  //  Consuta el estado de cuenta resumido
  public estadoDeCuentaResumido(version: string, cdgorf: string, cdgcl: string, fechadesde: string, fechahasta: string, diacorte: string): Observable<EstadoDeCuentaResumidoArray> {
    return this.http.post('api/reporte/estadodecuenta/resumido', { version: version, cdgorf: cdgorf, cdgcl: cdgcl, fechadesde: fechadesde, fechahasta:fechahasta, diacorte:diacorte })
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }


}
