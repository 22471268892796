import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PeArrayInterface } from '@comp/object/personal/peArray.interface';
import { PersonalInterface } from '@comp/object/general/personal/personal.interface';

@Injectable()
export class PersonalService {

  constructor(private http: HttpClient) {

  }

  findPersonal(
      filter = '', siguiente?: number, limite?: number):  Observable<any[]> {
          const data: any = {};
          data.filter = filter;
          if (siguiente) {
              if (!limite) {
                  limite = 20;
              }
              siguiente = (siguiente * limite) + 1;
              data.siguiente = siguiente;
          }
          if (limite) {
              data.limite = limite;
          }

      return this.http.post('api/monitoreo/personal', data
          ).pipe(
          map(res => JSON.parse(JSON.stringify(res)) )
      );
  }

  //  Regresa el listado de personal
  public PersonalGet(): Observable<PersonalInterface> {
    return this.http.post('api/personal/', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  personalMGet(cdgpe: string, nombre: string, siguiente: number, limite: number): Observable<PeArrayInterface> {
    const data: any = {};
    if (cdgpe) {
      data.id = {};
      data.id.cdgpe = cdgpe;
    }
    if (nombre) {
      data.nombre = nombre;
    }
    data.siguiente = siguiente;
    data.limite = limite;

    return this.http.post('api/monitoreo/personal', data).pipe(
      map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  monitoreoGet(cdgpe: string, codigo: string, fecha: string): Observable<PeArrayInterface> {
    const data = {
      id: {cdgpe: cdgpe, cdgmon: codigo},
      fecha: fecha
    };
    return this.http.post('api/monitoreo/detalle', data).pipe(
      map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  oficialDeCredito(codigo: string, cdgco: string, nombre: string, conombre: string): Observable<PersonalInterface> {
    return this.http.post('api/personal/oficialdecredito', {codigo: codigo, cdgco: cdgco, nombre: nombre, conombre: conombre}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  loginPe(usuario: string, contrasena: string): Observable<any> {
    return this.http.post('api/personal/login', {usuario: usuario, contrasena: contrasena}).pipe(
      map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

}
