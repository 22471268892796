import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material';
import { OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-probabilidad',
  templateUrl: './probabilidad.component.html',
    styleUrls: ['./probabilidad.component.css']
  })

export class ProbabilidadDialogComponent implements OnInit {
  formControl = new FormControl('', [Validators.required]);

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ProbabilidadDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {

  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Este campo es requerido.' : '';
  }

  applyFilter(ev: any) {
    if (ev.keyCode < 49 || ev.keyCode > 51) {
      ev.preventDefault();
    }
  }

  clear() {
    this.data.probabilidad = '';
  }

  aceptar() {
    this.dialogRef.close(this.data);
  }

  cancelar() {
    this.dialogRef.close({});
  }

}
