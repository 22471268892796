import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AnalisisCreditoService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de acreditados
     */
    public getAcred(jsonRAC): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/analisiscredito/getAcre', 
            { "jsonRAC": jsonRAC})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de repote
     */
    public getReporte(jsonRAC): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/analisiscredito/getReporte', 
            { "jsonRAC": jsonRAC})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


}
