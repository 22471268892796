import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VerificarInterface } from '@comp/object/importacionidue/login/verificar.interface';
import { FtrInterface } from '@comp/object/importacionidue/login/ftr.interface';
import { ImportacionIdueInterface } from '@comp/object/control-cartera/operacion/importacion-idue/importacionIDue.interface';
import { ClientesImportadosInterface } from '@comp/object/control-cartera/operacion/importacion-idue/clientes-importados/clientes-importados.interface';
import { PlanesOrganizacionales } from '@comp/object/importacionidue/planes-organizacionales/planesOrg.interface';
import { FTRs } from '@comp/object/importacionidue/tramites/ftrs.interface';

@Injectable()
export class ImportacionidueService {

    constructor(private http: HttpClient) {}

    /**
     * Funcion que verifica si ya esta autenticado o no
     */
    public verificarLogin():  Observable<VerificarInterface> {
        return this.http.get('api/clientes/idue/islogin'
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que realiza el login con el servidor
     * @param {string} email variable que contiene el nombre de usuario
     * @param {string} passw vairbale que contiene la contraseña del usuario
     */
    public doLogin(email: string, passw: string): Observable<VerificarInterface> {
        return this.http.post('api/clientes/idue/login', {'iuser': email, 'ipassword': passw}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que obtinen los ftr
     */
    public getFtr(): Observable<FtrInterface> {
        return this.http.get('api/clientes/idue/ftr').pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
    /**
     * Función que obtiene la información de la cuenta vinculada con IDue.
     */
    public obtenerInformacionDeCuenta(){
      return this.http.post('api/clientes/idue/obtenerInformacionDeCuenta',{}).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Función que elimina la integración de una cuenta con IDue.
     * @param codigo 
     */
    public eliminarIntegracionIdue(codigo: string){
      return this.http.post('api/clientes/idue/eliminarIntegracionIdue',{codigo}).pipe(
        map(res => JSON.parse(JSON.stringify(res)) ));
    }

      /**
     * Función que integra una cuenta con IDue.
     * @param codigo 
     */
    public integrarCuentaConIdue(cuenta: string){
      return this.http.post('api/clientes/idue/integrarCuentaConIdue',{cuenta}).pipe(
        map(res => JSON.parse(JSON.stringify(res)) ));
    }

    // Inserta acreditado
  public InsertaCL(objCl: any): Observable<ImportacionIdueInterface> {
     return this.http.post('api/control-cartera/operacion/acreditados/postClIDUE', objCl).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa los clientes IDue que ya se han importado
  public clientesImportadosIDue(cdgcl: string, cdgtpc: string, cdgpcr: string): Observable<ClientesImportadosInterface> {
    return this.http.post('api/control-cartera/operacion/importacion-idue/sc/idue/get',{cdgcl: cdgcl, cdgtpc: cdgtpc, cdgpcr: cdgpcr}).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  public detalleAtr(keyAtr: string): Observable<any> {
    return this.http.post('api/clientes/idue/detalleAtr',{ key_de_atr: keyAtr }).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  public planesOrg(): Observable<PlanesOrganizacionales> {
    return this.http.get('api/clientes/idue/planes').pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public getFtr_v2(keyPlan: string): Observable<FTRs> {
    return this.http.post('api/clientes/idue/ftr_v2',{keyPlan: keyPlan}).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public setMoffin(cdgcl): Observable<any>{
    return this.http.post('api/clientes/idue/moffin',{cdgcl: cdgcl}).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }
  
}
