import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClienteArrayInterface } from '@comp/object/pld/reportes/evaluacionDeRiesgo/ClienteArray.interface';


@Injectable()
export class EvaluacionDeRiesgoService {

  constructor(private http: HttpClient) {}

  // Regrsa la información para construir el archivo Excel
  public getMatriz(cdgcl: string, fDesde: string, fHasta: string, tipoPersona: string, inicio: number, limite: number): Observable<ClienteArrayInterface> {
    return this.http.post('api/pld/reportes/evaluacion-de-riesgo/get', {cdgcl: cdgcl, fDesde: fDesde, fHasta: fHasta, tipoPersona: tipoPersona, inicio: inicio, limite: limite}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regrsa el archivo Excel
  public getExcel(clArray: any[]): Observable<any> {
    return this.http.post('api/pld/reportes/evaluacion-de-riesgo/getExcel', {clArray: clArray}, {responseType: 'blob'}).pipe(
      map(res => {
        return res;
      })
    );
  }

}
