import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PRCCIService {

    constructor(private http: HttpClient) {}

          /**
     * Consulta bonificaciones
     */
        public getBPC(jsonBPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/bonificaciones/getBPC', 
            {  "jsonBPC": jsonBPC, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Insertar bonificaciones
     */
        public insertarBPC(jsonBPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/bonificaciones/insertarBPC', 
            {  "jsonBPC": jsonBPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Editar bonificaciones
     */
        public editarBPC(jsonBPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/bonificaciones/editarBPC', 
            {  "jsonBPC": jsonBPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar bonificaciones
     */
        public eliminarBPC(jsonBPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/bonificaciones/eliminarBPC', 
            {  "jsonBPC": jsonBPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta cuentas (Comisiones y garantias)
     */
        public getCt(jsonCt): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getCT', 
            {  "jsonCt": jsonCt, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta división (Comisiones y garantias)
     */
     public getDv(jsonDv): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getDV', 
            {  "jsonDv": jsonDv, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta departamentos (Comisiones y garantias)
     */
     public getDe(jsonDe): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getDE', 
            {  "jsonDe": jsonDe, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta cuentas (Comisiones y garantias)
     */
     public getct(jsonCt): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getBPC', 
            {  "jsonCt": jsonCt, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta detalle de tipo de movimiento (Comisiones y garantias)
     */
     public getDtm(jsonDtm): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getDTM', 
            {  "jsonDtm": jsonDtm, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta aseguradoras (Comisiones y garantias)
     */
     public getAsg(jsonAsg): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getASG', 
            {  "jsonAsg": jsonAsg, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta Comisiones y garantias
     */
     public getTmp(jsonTmp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getTMP', 
            {  "jsonTmp": jsonTmp, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


    /**
     * Insertar comisiones y garantias
     */
        public insertarTMP(jsonTmp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/insertarTMP', 
            {  "jsonTmp": jsonTmp, "jsonPgv": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Editar comisiones y garantias
     */
        public editarTMP(jsonTmp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/editarTMP', 
            {  "jsonTmp": jsonTmp, "jsonPgv": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar comisiones y garantias
     */
        public eliminarTMP(jsonTmp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/eliminarTMP', 
            {  "jsonTmp": jsonTmp, "jsonPgv": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta Inicial y Periodico
     */
     public getIniPer(jsonTmp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/prc-ciclos/comisiones/getIniPer', 
            {  "jsonTmp": jsonTmp, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
}