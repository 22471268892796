import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayTipoMovimiento } from "@comp/object/control-cartera/operacion/prestamos/datcomdgpcr/arraytipomovimiento.interface";


@Injectable()
export class TipoMovimientoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getTiposMovimiento(cdgtpc:string,cdgpcr:string,ciclo:string): Observable<ArrayTipoMovimiento> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/datcomdgpcr/consultar', { 
            version:'0',
            cdgtpc:cdgtpc,
            cdgpcr:cdgpcr,
            ciclo:ciclo 
        
        }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    /**
     * Consulta detalle de tipo de movimiento (Comisiones y garantias) TIPO DG
     */
     public getDtm(jsonDtm): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/datcomdgpcr/consultar/getDG', 
            {  "jsonDtm": jsonDtm, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

}