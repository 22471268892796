import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayMovExtraordinarios } from "@comp/object/control-cartera/operacion/mov-extraordinarios/arraymovextraordinarios.interface";


@Injectable()
export class MovExtraordinariosService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    
    getMovExtraordinarios(consultaMpJson: string): Observable<any> {
        return this.http.post('api/control-cartera/operacion/mov-extraordinarios/consultar', { consultaMpJson: consultaMpJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getSaldos(consultaSaldosJson: string): Observable<any> {
        return this.http.post('api/control-cartera/operacion/mov-extraordinarios/consultarsaldos', { consultaSaldosJson: consultaSaldosJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarMovExtraordinarios(insercionMpJson: string, generarPeriodoJson:string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/mov-extraordinarios/insertar', { insercionMpJson: insercionMpJson,generarPeriodoJson:generarPeriodoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarMovExtraordinarios(eliminarMpJson: string): Observable<any> {
        return this.http.post('api/control-cartera/operacion/mov-extraordinarios/eliminar', { eliminarMpJson: eliminarMpJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    consultarCreditoPrcSituacion(): Observable<any> {
        return this.procesado = this.http.post('api/control-cartera/operacion/mov-extraordinarios/consultarprcsituacion', 
        {version:'0',
        arraysituaciones:['E']}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    

    



}