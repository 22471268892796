import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayMpDuplicadosInterface  } from '@comp/object/control-cartera/operacion/movimientos/importaciones/depositosbancarios/mpduplicados/mpduplicados_array.interface';

@Injectable()
export class MpDuplicadosService {

  constructor(private http: HttpClient) { }

  public consultar(version: string, cdgclns: string, fecha: string, cdgco: string, nombreco: string, nombremu: string, cdgcb: string, nombre:string, clave: string, tipo:string): Observable<ArrayMpDuplicadosInterface> {
    return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/mpduplicados/obtiene', {
      'version': version,
      'cdgclns': cdgclns,
      'fecha': fecha,
      'cdgco': cdgco,
      'nombreco': nombreco,
      'nombremu': nombremu,
      'cdgcb': cdgcb,
      'nombre': nombre,
      'clave': clave,
      'tipo': tipo
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }
  public eliminar(jsonMp: string): Observable<boolean> {
    return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/mpduplicados/elimina', {
      'jsonMp': jsonMp,
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

}
