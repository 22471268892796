import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FechaEspecialArrayInterface } from '@comp/object/personal/fecha-especial/fEspArray.interface';

@Injectable()
export class PermisosService {
    constructor(private http: HttpClient) {}

    getPermisos(url: String): Observable<any> {
        const data: any = {url: url.charAt(0) === '/' ? url.substr(1) : url};
        console.log('url permisos:', url);

        return this.http.post('api/general/permisos/get-permisos-url', data
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    setAOM(json: JSON): Observable<any> {
        return this.http.post('api/general/permisos/set-aom', {json}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    getPermisosOM(url: String): Observable<any> {
        const data: any = {url: url.charAt(0) === '/' ? url.substr(1) : url};
        return this.http.post('api/general/permisos/get-permisos-om', data).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    fechaEspecialGet(gStrPermisos: string): Observable<FechaEspecialArrayInterface> {
        return this.http.post('api/general/permisos/fecha-especial', { gStrPermisos: gStrPermisos }).pipe(
            map(res => {
                return JSON.parse(JSON.stringify(res));
            })
        );
    }

}
