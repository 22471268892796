import { DynamicDateRangeModel } from '@comp/dynamic-form/core/model/range/dynamic-date-range.model';
import { DynamicCheckboxModel } from '@comp/dynamic-form/core/model/checkbox/dynamic-checkbox.model';
import { DynamicRadioGroupModel } from '@comp/dynamic-form/core/model/radio/dynamic-radio-group.model';
import { DynamicFormModel } from '@comp/dynamic-form/core/model/dynamic-form.model';
import { DynamicInputModel } from '@comp/dynamic-form/core/model/input/dynamic-input.model';
import { DynamicFormCustomControlModel } from '@comp/dynamic-form/core/model/custom/dynamic-custom.model';
import { DynamicDivGroupModel } from '@comp/dynamic-form/core/model/div/dynamic-div-group.model';
import { DynamicHiddenModel } from '@comp/dynamic-form/core/model/hidden/dynamic-hidden.model';
import { MatTableDataSource } from '@angular/material/table';
import { EstiloColumnas } from '@comp/reporte/pdf/reporte-pdf.service';
import Utils from '@comp/util/utils';

export abstract class AmortizacionBase {

    public result: TA;
    public request: any;
    protected extraInfo: any;
    public taticomp: string = 'Comisiones';
    public comseg : string;
    public tatisegs: string = 'Seguro';
    public tatipsd: string = 'Prima de seguro saldo deudor';
    public tasegvid: string;
    public tatexcom: string = '0';
    public tatiscap: string = 'Saldo capital';
    public clarren: boolean;    

    public abstract getElementos(): DynamicFormModel;

    protected getElementosBase(): DynamicFormModel {
        const flg16 = {
            def: '16px',
            md: '0px'
        };
        const elementos: DynamicFormModel = [

            new DynamicDivGroupModel({
                id: 'root',
                group: [

                    new DynamicFormCustomControlModel({
                        id: 'titulo1'
                    }),

                    new DynamicDivGroupModel({
                        id: 'prestamo',
                        group: [

                            new DynamicRadioGroupModel<string>({

                                id: 'tipos',
                                value: 'I',
                                options: [
                                    {
                                        label: 'Clientes individuales',
                                        value: 'I'
                                    },
                                    {
                                        label: 'Grupales',
                                        value: 'G'
                                    },
                                    {
                                        label: 'Clientes de Grupo',
                                        value: 'IG'
                                    }
                                ]
                            })
                        ],
                        fxLayoutAling: {
                            def: 'space-around center',
                            md: 'space-around center'
                        }
                    }),

                    new DynamicFormCustomControlModel({
                        id: 'titulo3'
                    }),

                    new DynamicDivGroupModel({
                        id: 'reporte',
                        group: [

                            new DynamicInputModel({
                                id: 'cdgcl',
                                label: 'Código',
                                placeholder: '000000',
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'El código es necesario'
                                },
                                mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                            }),

                            new DynamicInputModel({
                                id: 'nombre',
                                label: 'Nombre',
                                placeholder: 'Nombre del cliente',
                                disabled: true,
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'El nombre es necesario'
                                },
                                flex: '400px'
                            }),

                            new DynamicInputModel({
                                id: 'ciclo',
                                label: 'Ciclo',
                                placeholder: '00',
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'El ciclo es necesario'
                                },
                                mask: [/\d/, /\d/]
                            }),

                            new DynamicFormCustomControlModel({
                                id: 'busqueda'
                            }),

                            new DynamicInputModel({
                                id: 'nombre_grupo',
                                label: 'Nombre de grupo',
                                disabled: true,
                                placeholder: 'Nombre de grupo',                                
                                hidden: false
                            })
                        ],
                       fxLayoutGap: flg16
                    }),

                    new DynamicDivGroupModel({
                        id: 'fecha_situacion',
                        group: [

                            new DynamicDivGroupModel({
                                id: 'fecha_ciclo',
                                group: [

                                    new DynamicFormCustomControlModel({
                                        id: 'titulo4'
                                    }),

                                   /* new DynamicDateRangeModel({
                                        id: 'rango'
                                    })*/

                                ],
                                fxLayout: {
                                    def: 'column',
                                    md: 'column'
                                }
                            }),

                        ],
                        fxLayoutAling: {
                            def: 'space-evenly center',
                            md: 'space-evenly center'
                        },
                        fxLayout: {
                            def: 'row',
                            md: 'row'
                        }
                    }),

                    new DynamicFormCustomControlModel({
                        id: 'titulo6'
                    }),

                    new DynamicDivGroupModel({
                        id: 'tasa_sec',
                        label: '',
                        group: [

                            new DynamicCheckboxModel({

                                id: 'imprimir',
                                label: 'Imprimir',
                                value: true
                            }),

                            new DynamicRadioGroupModel<string>({
                                id: 'tasa-tiempo',
                                label: 'Especificar tasa',
                                value: 'M',
                                options: [
                                    {
                                        label: 'Semanal',
                                        value: 'S'
                                    },
                                    {
                                        label: 'Mensual',
                                        value: 'M'
                                    },
                                    {
                                        label: 'Anual',
                                        value: 'A'
                                    }
                                ]
                            }),

                            new DynamicDivGroupModel({

                                id: 'tasa-div',
                                group: [
                                    new DynamicCheckboxModel({

                                        id: 'redondea',
                                        label: 'Redondear',
                                        value: false
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'iva',
                                        label: 'Mostrar IVA',
                                        value: true
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'opccomision',
                                        label: 'Incluir comisiones opcionales',
                                        value: true
                                    })
                                ],
                                fxLayoutAling: {
                                    def: 'start stretch',
                                    md: 'start stretch'
                                },
                                fxLayout: {
                                    def: 'column',
                                    md: 'column'
                                }
                            })

                        ],
                        fxLayoutGap: flg16
                    }),

                    new DynamicDivGroupModel({
                        id: 'tasa_sec_dos',
                        group: [

                            new DynamicRadioGroupModel<string>({

                                id: 't_int',
                                label: 'Tasa de interés',
                                value: 'c_iva',
                                options: [
                                    {
                                        label: 'Con IVA',
                                        value: 'c_iva'
                                    },
                                    {
                                        label: 'Sin IVA',
                                        value: 's_iva'
                                    }
                                ]
                            })
                        ]
                    }),

                ],
                fxLayout: {
                    def: 'column',
                    md: ''
                },
                fxLayoutGap: flg16
            }),

          /*  new DynamicHiddenModel({
                id: 'version',
                value: '0'
            })*/
        ];

        return elementos;
    }

    getLayout() {
        const w100 = {
            grid: {
                control: 'width100'
            }
        };
        const flexdcol = {
            grid: {
                control: 'flexdcol'
            }
        };
        const flexdw100 = {
            grid: {
                control: 'width100',
                children: 'flex'
            }
        };
        return {
            root: {
                grid: {
                    control: 'width100',
                    children: 'width100'
                }
            },
            prestamo: w100,
            tipos: {
                grid: {
                    control: 'width100 flexdrow'
                },
                element: {
                    option: 'lateral-margin'
                }
            },
            'user-data': {
                grid: {
                    control: 'width100',
                    childrennombre: 'flex'
                }
            },
            cdgclns: w100,
            nombre: w100,
            cdgtpc: w100,
            tasa_sec: {
                grid: {
                    control: 'width100',
                    container: 'seccion',
                    children: 'width100'
                }
            },
            tasa_sec_int: w100,
            t_int: flexdcol,
            utilizar_tasa: flexdcol,
            'tasa-tiempo': flexdcol
        };
    }

    public abstract getResult(data: any);

    public abstract getEncData(): Array<Array<EncData>>;

    public getDysplayColumns() {
        const dc = ['periodo', 'vencimiento', 'parcialidadcap', 'parcialidadint', 'parcialidadcom'];
        if (this.comseg == 'S') {
            dc.push('parcialidadseg');
        }
        if (this.result.tasegsd == 'S') {
            dc.push('primaseg');
        }
        dc.push('saldocap');
        dc.push('cantidadtotal');
        return dc;
    }

    public getTituloColumns() {
        const tc = ['No. periodo',
                this.result.tatifven,
                this.result.taticap,
                this.result.tatiin,
                // 'Comisión'
                this.taticomp
            ];
        if (this.comseg == 'S') {
            tc.push(this.tatisegs);
        }
        if (this.result.tasegsd == 'S') {
            tc.push(this.tatipsd);
        }
        // if (this.result.cdgph) {
            tc.push(this.tatiscap);
        // }
        if (this.result.cdgph) {
            tc.push(this.result.tatiparc + ' + ' + this.result.tit_ah);
        } else {
            tc.push(this.result.tatiparc);
        }
        // if (this.result.tasegsd != 'S') {
            // if (this.result.cdgph) {
            //     tc.push(this.result.tit_ah);
            // }
        // }
        //console.log(tc)
        return tc;
    }

    public getStyleColumns(): EstiloColumnas {
        const rig = { halign: 'right' };
        const cen = { halign: 'center' };
        let sc = {
            0: cen,
            1: cen,
            2: rig,
            3: rig,
            4: rig,
            5: rig,
            6: rig,
            7: rig,
            8: rig
        } as any;
        if (this.extraInfo.seguro) {
            sc = { ...sc, 7: rig };
        }
        return sc;
    }

    public getDataSource(arrayName: string) {
        const result = this.result;
        const data = [];
        let comisionTotal = 0;
        let total = 0;
        let intTotal = 0;
        let seguro = 0;
        let primaseg = 0;
        const temporal: ItemTA[] = [];
        let ivaTotal = 0;
        let cantTotal = 0;

        result[arrayName].forEach(val => {
            const clone = { ...val };

            comisionTotal += +val.parcialidadcom;
            total += +val.parcround;//val.parcialidadcap + +val.parcialidadint + +val.parcialidadcom;
            intTotal += +val.parcialidadint;
            seguro += +val.parcialidadseg;
            ivaTotal += +val.ivainteres;
            cantTotal += val.parcialidadcap + +val.ivainteres;

            clone.parcialidadcap = Utils.formatNumber(val.parcialidadcap);
            clone.parcialidadint = Utils.formatNumber(val.parcialidadint);
            clone.parcialidadcom = Utils.formatNumber(val.parcialidadcom);
            clone.parcialidadseg = Utils.formatNumber(val.parcialidadseg);
            clone.saldocap = Utils.formatNumber(val.saldocap);
            if (this.extraInfo.redondea) {
                // val.cantidadtotal = Utils.formatNumber(val.cantidadtotal);
            }
            temporal.push(clone);
        });
        //result.creditoArray[result.creditoArray.length-1].total
        
        const comisionTotal1 = Utils.formatNumber(comisionTotal);
        const total1 = Utils.formatNumber(total);
        const intTotal1 = Utils.formatNumber(intTotal);
        const seguro1 = Utils.formatNumber(seguro);
        const parcialiad = Utils.formatNumber(result.montopr);
        let ahorro = Utils.formatNumber(result.ahorro);

        temporal.forEach((val, index) => {
            let tem = {
                periodo: val.periodo,
                vencimiento: val.vencimiento,
                parcialidadcap: val.parcialidadcap,
                parcialidadint: this.clarren ? val.ivainteres : val.parcialidadint,
                // parcialidadcom: val.parcialidadcom,
                parcialidadcom: val.parcialidadcom == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : val.parcialidadcom,
                saldocap: val.saldocap,
                // cantidadtotal: +result[arrayName][index].parcialidadcap +
                //                +result[arrayName][index].parcialidadint +
                //                +result[arrayName][index].parcialidadcom
                cantidadtotal: +result[arrayName][index].parcround
            } as { [key: string]: any };
            // if (val.hasOwnProperty('parcialidadseg')) {
            //     tem = { ...tem,
            //         parcialidadseg: val['parcialidadseg']};
            // }
            if (this.comseg == 'S') {
                primaseg += Number(val.parcialidadseg);
                tem = { ...tem,
                    parcialidadseg: val.parcialidadseg};
            }
            if (this.result.tasegsd == 'S') {
                tem = { ...tem,
                    primaseg: this.clarren ? Utils.formatNumber(val.cantidadtotal + val.ivainteres) : Utils.formatNumber(primaseg)};
            }
            if (this.result.tasegsd != 'S') {
                if (this.result.cdgph) {
                    tem = { ...tem, ahorro: ahorro};
                }
            }

            if (this.extraInfo.iva) {
                if (this.extraInfo.redondea) {
                    tem.cantidadtotal = Utils.formatNumber(Math.ceil(tem.cantidadtotal));
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: Utils.formatNumber(val.ivainteres),
                        // parcialidadcom: Utils.formatNumber(val.ivacomision),
                        parcialidadcom: val.ivacomision == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : Utils.formatNumber(val.ivacomision),
                        parcialidadseg: Utils.formatNumber(val.ivaseguro),
                        primaseg: '',
                        // saldocap: Utils.formatNumber(val.ivainteres),
                        saldocap: '',
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);

                } else {
                    tem.cantidadtotal = Utils.formatNumber(tem.cantidadtotal);
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: Utils.formatNumber(val.ivainteres),
                        // parcialidadcom: Utils.formatNumber(val.ivacomision),
                        parcialidadcom: val.ivacomision == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : Utils.formatNumber(val.ivacomision),
                        parcialidadseg: Utils.formatNumber(val.ivaseguro),
                        primaseg: '',
                        // saldocap: Utils.formatNumber(val.ivainteres),
                        saldocap: '',
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);
                }
            } else {
                if (this.extraInfo.redondea) {
                    tem.cantidadtotal = Utils.formatNumber(Math.ceil(tem.cantidadtotal));
                    data.push(tem);
                } else {
                    tem.cantidadtotal = Utils.formatNumber(tem.cantidadtotal);
                    data.push(tem);
                }
            }
        });

        data.push({
            periodo: '.', vencimiento: '', parcialidadcap: this.clarren ? total1 : parcialiad, parcialidadint: this.clarren ? Utils.formatNumber(ivaTotal) : intTotal1,
            parcialidadcom: comisionTotal1, parcialidadseg: seguro1, primaseg: this.clarren ? Utils.formatNumber(cantTotal) : '', saldocap: '', 
            cantidadtotal: total1
        });

        return new MatTableDataSource<any>(data);
    }

    public getPeriodicidad() {
        switch (this.result.periodicidad) {
            case 'M':
            case 'mensual':
                return 'Mes(es)';
            case 'S':
            case 'semanal':
                return 'Semana(s)';
            case 'C':
            case 'catorcenal':
                return 'Catorcena(s)';
            case 'Q':
            case 'quincenal':
                return 'Quincena(s)';
            case 'A':
                return 'Al vencimiento';
        }
    }

    public getPeriodicidadT(val: number) {
        switch (this.result.periodicidad) {
            case 'M':
            case 'mensual':
                return val === 1 ? 'Mes(es)' : 'Mes(es)';
            case 'S':
            case 'semanal':
                return val === 1 ? 'Semana(s)' : 'Semana(s)';
            case 'C':
            case 'catorcenal':
                return val === 1 ? 'Catorcena(s)' : 'Catorcena(s)';
            case 'Q':
            case 'quincenal':
                return val === 1 ? 'Quincena(s)' : 'Quincena(s)';
            case 'A':
                return '(días)';
        }
    }

    public setextraInfo(info: any) {
        this.extraInfo = info;
    }

    public getComisionPer(totsegper: number, totcomper: number): string {
        let qrlComPer = Utils.formatNumber(totcomper - totsegper);

        if (qrlComPer == '0.00' && this.tatexcom != '0') {
            // qrlComPer = Utils.formatNumber(Number(this.tatexcom));
            qrlComPer = isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom));
        }
        return qrlComPer;
    }
}

export interface TA {
    cdgmci: string;
    ciclo: string;
    codigo: string;
    comini: number;
    encabeza: string;
    fechainicio: string;
    lycomini: string;
    lysegini: string;
    montopr: number;
    nombre: string;
    periodicidad: string;
    periograciacap: number;
    periograciaint: number;
    plazo: number;
    reca: string;
    tacontra: string;
    tapergrc: string;
    tapergri: string;
    tasa: number;
    tasaanual: number;
    tasaanualsiniva: number;
    tasasiniva: number;
    tatasa: string;
    taticap: string;
    tatifin: string;
    tatifven: string;
    tatiin: string;
    tatiparc: string;
    tatipcr: string;
    tatiper: string;
    tatiscap: string;
    etiquetafecha: string;
    /*tit_ahorro: string; TODO://Remover porque no son genericos
    nmmci: string;
    tatinic: string;
    plazodia: number;
    multper: number;
    comsegin: number;*/
    reca_pcr,
    cdgph: string;
    tit_ah: string;
    tasegsd: string;
    ahorro: number;
    creditoArray: any[]
}

export interface ItemTA {
    ivacomision: number;
    ivainteres: number;
    ivaseguro: number;
    parcialidadcap: number | string;
    parcialidadcom: number | string;
    parcialidadint: number | string;
    periodo: number;
    saldocap: number | string;
    vencimiento: string;
    /*TODO:Eliminar porque no son gnericos
    parcialidad: number;
    ivarecargos: number;*/
    parcialidadseg: number;
    cantidadtotal: number;
    
}

export interface EncData {
    titulo: string;
    valor: string;
}
