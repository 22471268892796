export default class Utils {

  /**
   * Regresa un numbero en formato de pesos
   *
   * @param {number} numero
   * @return {string} número con formato
   */
  static formatNumber(numero: number): string {
    const n: number = (numero === null || numero === undefined) ? 0 : numero;
    return n.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
}
