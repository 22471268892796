// logo.component.ts
import { Component, OnInit } from '@angular/core';
import Base from '@comp/object/base/Base';
import { Toolbar } from '@serv/toolbar.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatosC } from '@comp/object/items/Users/UserCl';
import { AuthService } from '@serv/auth/auth.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent extends Base implements OnInit {
  logoUrl: string;
  logo64: string;
  logocliente = false;
  nombre: String;

  constructor(private toolbar: Toolbar, private httpClient: HttpClient, private auth: AuthService) {
    super(toolbar);
    this.logoUrl = localStorage.getItem('logo');
    this.logo64 = '';
  }

  ngOnInit(): void {
    this.getLogo();
      if (this.logoUrl && this.logoUrl.trim() !== '') {
        // La variable this.logoUrl tiene contenido
        this.logocliente = true;
      } else {
        // La variable this.logoUrl está vacía o es null/undefined
        this.logocliente = false; 
      }
  }
  
  getLogo() {
    const user = this.auth.getUser();
    //console.log("loco context: ", user)
    if (user instanceof DatosC) {
        this.logoUrl = user.logo;
        this.nombre = user.nombre;
    } else {
      this.logoUrl = '';
    }
  }

 
}
