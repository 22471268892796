import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MOInterface, PcrInterface } from '@comp/object/control-cartera/catalogos/productos-credito/producto-credito.interface';

@Injectable()
export class PCRService {

    constructor(private http: HttpClient) {}

     /**
     * Consulta de Tipos de productos de credito
     */
    public getPcr(pPCR): Observable<PcrInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getPcr', 
            { 'pPCR': pPCR})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consutar monedas
     */
     public getMonedas(): Observable<MOInterface> {
        return this.http.post('api/ahorros/listados/moneda/get', {
            
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consulta de Tipos de crédito
     */
     public getTcr(): Observable<PcrInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getTcr', 
            { })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


     /**
     * Consulta de Tipos de contrato Buró
     */
    public getTContratoB(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getTCB', 
            { "consultaTCBMJson": ""})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


     /**
     * Consulta de Tipos de crédito buró
     */
    public getTCreditoB(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getTCBM', 
            {"consultaTCBJson": "" })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


   /**
     * Consulta de Tipos de contrato círculo
     */
    public getTContratoC(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getTCC', 
            {  "consultaTCCJson": "" })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Consulta de Metodo de cobro de interes
     */
      public getMCI(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getMCI', 
            {  })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Consulta de Forma de distribucion de interes
     */
      public getFDI(consultaFDIJson: string): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getFDI', 
            {  consultaFDIJson: consultaFDIJson })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de Instrumentos de credito
     */
     public getTI(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getTI', 
            {  "consultaTIJson": "" })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de Esquemas recargos fijos
     */
     public getERR(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getERR', 
            {  "consultaERRJson": "" })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de Productos de ahorro
     */
     public getPH(codigo: string, nombre:string): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/getPH', 
            {  "codigo": codigo, "nombre": nombre })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }



    // Documentos
    /**
     * Consulta de tipos de documentos
     */
     public getTD(codigo: string): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/documentos/getTD', 
            {  "codigo": codigo})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de tipos de documentos para prc
     */
     public getDpTd(jsonDp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/documentos/getDPTD', 
            {  "jsonDP": jsonDp, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Insertar documentos
     */
     public insertarDp(jsonDp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/documentos/insertarDp', 
            {  "jsonDP": jsonDp, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Editar documentos
     */
     public editarDp(jsonDp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/documentos/editarDp', 
            {  "jsonDP": jsonDp, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar documentos
     */
     public eliminarDp(jsonDp): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/documentos/eliminarDp', 
            {  "jsonDP": jsonDp, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<any[]> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

    /**
     * Consulta de tipos de documentos para las actividades
     */
     public getTA(): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/getTA', 
            {  "jsonTA": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

      /**
     * Consulta de tipos de documentos para las actividades
     */
       public getDi(jsonDi): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/getDI', 
            {  "jsonDI": jsonDi, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

       /**
     * Consulta de tipos de actividades 
     */
        public getAPC(jsonAPC): Observable<any> {
            return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/getAPC', 
                {  "jsonAPC": jsonAPC, "jsonPGV": {} })
            .pipe(
            map(res => {
                return JSON.parse(JSON.stringify(res));
            })
            );
        }
    

    /**
     * Insertar actividades
     */
     public insertarAPC(jsonAPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/insertarAPC', 
            {  "jsonAPC": jsonAPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Editar actividades
     */
     public editarAPC(jsonAPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/editarAPC', 
            {  "jsonAPC": jsonAPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar actividades
     */
     public eliminarAPC(jsonAPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/actividades/eliminarAPC', 
            {  "jsonAPC": jsonAPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


    /* Consulta datos prelacion  */
    public getCIN(jsonFdi): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/prelacion/getCln', 
            {  "jsonFdi": jsonFdi, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
        
    
    /**
     * Consulta prelacion
     */
        public getPpc(jsonPpc): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/prelacion/getPpc', 
            {  "jsonPpc": jsonPpc, "jsonPgv": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Insertar prelacion
     */
        public insertarPpc(jsonPpc): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/prelacion/insertarPpc', 
            {  "jsonPpc": jsonPpc, "jsonPgv": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar prelación
     */
        public eliminarPpc(jsonPPC): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/prelacion/eliminarPpc', 
            {  "jsonPPC": jsonPPC, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de pestaña factores 
     */
        public getCMU(jsonCMU): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/factores/getCmu', 
            {  "jsonCMU": jsonCMU, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
        }
    

    /**
     * Insertar Factores
     */
     public insertarCMU(jsonCMU): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/factores/insertarCmu', 
            {  "jsonCMU": jsonCMU, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Editar factores
     */
     public editarCMU(jsonCMU): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/factores/editarCmu', 
            {  "jsonCMU": jsonCMU, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Eliminar factores
     */
     public eliminarCMU(jsonCMU): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/factores/eliminarCmu', 
            {  "jsonCMU": jsonCMU, "jsonPGV": {}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * BusquedaEpa
     */
    public busquedaEpa(jsonEPA): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/definicion-productos/epa/get', 
            {  "jsonEPA": jsonEPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    public symGet(servicio: string): Observable<any> {
        return this.http.post('api/general/sym/get', {servicio: servicio}).pipe(
          map(res => {
            return JSON.parse(JSON.stringify(res));
          })
        );
      }
}