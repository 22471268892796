import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PsVpArrayInterface } from '@comp/object/general/valorparametro/psvpArray.interface';
import { VpArrayInterface } from '@comp/object/general/valorparametro/vpArray.interface';

@Injectable()
export class ValoresParametro {

  constructor(private http: HttpClient) {}

  // Valores de parametro PS_VP
  public psvpGet(cdgps: string, fecha: string): Observable<PsVpArrayInterface> {
    return this.http.post('api/general/psvp/get', { cdgps: cdgps, fecha: fecha }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  //consultar valor de parámetros array

  public getValorParametros(arraycdgsps: any): Observable<VpArrayInterface> {
    return this.http.post('api/general/vparray/get',
      {
        arraycdgsps: arraycdgsps
      }).pipe(
        map(res => {
          // console.log(res);
          return JSON.parse(JSON.stringify(res));
        })
      );
  }
  // Actualiza Valor de parámetro
  public vpPost(cdgps: string, valor: string): Observable<any> {
    return this.http.post('api/general/vp/post', { cdgps: cdgps, valor: valor }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
