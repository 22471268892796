import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { MovimientoDeInversion } from "@app/inversionistas/models/movimineto-inversion.model";
import { MovimientoInversionInterface } from "@comp/object/inversionistas/operaciones/movimientos-inversion/movimientoInversion.interface";
import { ArrayMovimientosInversion } from "@comp/object/inversionistas/operaciones/movimientos-inversion/arraymovimientosinversion.interface";


@Injectable()
export class MovimientosInversionService {

    movimientosInversion: Observable<MovimientoInversionInterface>;
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getMovimientosInversion(cdgcl: string, secuencia: string, cdgdif: string, cdgtmi: string): Observable<ArrayMovimientosInversion> {
        return this.http.post('api/inversionistas/operaciones/movimientos-inversion/consultar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    filtrarMovimientosInversion(cdgcl: string, secuencia: string, cdgdif: string, cdgtmi: string,
        fMovimientoDesde: string, fMovimientoHasta: string, fTransaccionDesde: string, fTransaccionHasta: string,
        montoMinimo: number, montoMaximo: number, nomApeInversionista: string): Observable<ArrayMovimientosInversion> {
        let montoMin=null;
        let montoMax=null;
        if (montoMinimo != null && montoMaximo != null) {
            montoMin = parseFloat(montoMinimo.toString());
            montoMax = parseFloat(montoMaximo.toString());
        }
        return this.http.post('api/inversionistas/operaciones/movimientos-inversion/consultar', {
            id: { cdgcl: cdgcl, secuencia: secuencia },
            cdgdif: cdgdif,
            cdgtmi: cdgtmi,
            fMovimientoDesde: fMovimientoDesde,
            fMovimientoHasta: fMovimientoHasta,
            fTransaccionDesde: fTransaccionDesde,
            fTransaccionHasta: fTransaccionHasta,
            montoMinimo: montoMin,
            montoMaximo: montoMax,
            nomApeInversionista: nomApeInversionista
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    procesarMovimientoDeInversion(movimientoDeInversion: MovimientoDeInversion, registrar: boolean): Observable<string> {
        if (registrar) {
            return this.procesado = this.http.post('api/inversionistas/operaciones/movimientos-inversion/insertar', {
                id: {
                    cdgcl: movimientoDeInversion.id.cdgcl,
                    secuencia: movimientoDeInversion.id.secuencia,
                    fecha: movimientoDeInversion.id.fecha
                },
                cdgdif: movimientoDeInversion.cdgdif,
                descripcion: movimientoDeInversion.descripcion,
                abono: movimientoDeInversion.abono,
                cargo: movimientoDeInversion.cargo,
                cdgtmi: movimientoDeInversion.cdgtmi,
                ftransac: movimientoDeInversion.ftransac,
                fregistro: movimientoDeInversion.fregistro,
                cdgcb: movimientoDeInversion.cdgcb,
                formapago: movimientoDeInversion.formapago,
                cdgmo: movimientoDeInversion.cdgmo,
                nombremo:movimientoDeInversion.nombremo,
                importado:movimientoDeInversion.importado
            }
            ).pipe(
                map(res => JSON.parse(JSON.stringify(res)))
            );
        } else {
            return this.procesado = this.http.post('api/inversionistas/operaciones/movimientos-inversion/editar', {
                id: {
                    cdgcl: movimientoDeInversion.id.cdgcl,
                    secuencia: movimientoDeInversion.id.secuencia,
                    fecha: movimientoDeInversion.id.fecha
                },
                cdgdif: movimientoDeInversion.cdgdif,
                descripcion: movimientoDeInversion.descripcion,
                abono: movimientoDeInversion.abono,
                cargo: movimientoDeInversion.cargo,
                cdgtmi: movimientoDeInversion.cdgtmi,
                cdgpe: movimientoDeInversion.cdgpe,
                ftransac: movimientoDeInversion.ftransac,
                cdgcb: movimientoDeInversion.cdgcb,
                formapago: movimientoDeInversion.formapago,
                fregistro: movimientoDeInversion.fregistro,
                cdgmo: movimientoDeInversion.cdgmo,
                nombremo:movimientoDeInversion.nombremo
            }
            ).pipe(
                map(res => JSON.parse(JSON.stringify(res)))
            );
        }


    }

    eliminarMovimientoDeInversion(datos): Observable<string> {
        this.procesado = this.http.post('api/inversionistas/operaciones/movimientos-inversion/eliminar', {
            id: {
                cdgcl: datos.cdgcl,
                fecha: datos.fecha,
                secuencia: datos.secuencia
            },
            cdgdif: datos.cdgdif,
            cdgtmi: datos.cdgtmi,
            cdgpe: datos.cdgpe
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
        return this.procesado;

    }

    buscarDetalleDeinversion(vCdgcl: string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar', { cdgcl: vCdgcl, cdgpif: null }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    buscarDetalleDeInversionSec(vCdgcl: string,cdgpif,secuencia:string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar', 
        { cdgcl: vCdgcl, 
          cdgpif:cdgpif, 
          secuencia:secuencia }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getSaldoDif(cdgcl: string, secuendif: string, fecha: string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/movimientos-inversion/saldo/consultar', {
            version:'0',
            cdgcl:cdgcl,
            secuendif:secuendif,
            fecha:fecha
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }
}