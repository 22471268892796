import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonitoreoArrayInterface } from '@comp/object/control-cartera/operacion/monitoreo/get/monitoreoArray.interface';
import { FechasArrayInterface } from '@comp/object/control-cartera/operacion/monitoreo/post/fechasArray.interface';

@Injectable()
export class MonitoreoAgendaService {

  constructor(private http: HttpClient) {}

  // Consultar eventos
  public eventosGet(cdgpe: string, fecha: number): Observable<MonitoreoArrayInterface> {
    return this.http.post('api/control-cartera/operacion/monitoreo/evento/get', {cdgpe: cdgpe, fecha: fecha }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Crear evento
  public eventoPost(evento: any): Observable<any> {
    return this.http.post('api/control-cartera/operacion/monitoreo/evento/post', { evento }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Editar evento
  public eventoPut(evento: any): Observable<any> {
    return this.http.post('api/control-cartera/operacion/monitoreo/evento/put', { evento }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Borra evento
  public eventoDelete(cdgev: string, fecha: number): Observable<any> {
    return this.http.post('api/control-cartera/operacion/monitoreo/evento/delete', { cdgev: cdgev, fecha: fecha }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtener datos
  public obtenerDatos(data: any): Observable<any> {
    return this.http.post('api/monitoreo/buscar', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
