import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProveedorArrayInterface } from '@comp/object/control-cartera/operacion/movimientos/importaciones/depositosbancarios/proveedores/proveedor_array.interface';

@Injectable()
export class ProveedoresService {

    constructor(private http: HttpClient) { }

    /**
     * Funcion obtener los proveedores
     */
    public getProveedor(cdgpv: string, nombre : string): Observable<ProveedorArrayInterface> {
      return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/pv/getpv', {
        'cdgpv': cdgpv,
        'nombre': nombre
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}
