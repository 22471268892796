import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProyectosDeInversionInterface } from '@comp/object/general/proyectosdeinversion/proyectosdeinversion.interface';
import { AEInterface } from '@comp/object/general/actividadeconomica/ae.interface';
import { GiInterface } from '@comp/object/general/giro/gi.interface';
import { SeInterface } from '@comp/object/general/sector/se.interface';
import { UbNInterface } from '@comp/object/general/ubicaciondelnegocio/ubn.interface';
import { ProyectosDeInversionArrayInterface } from '@comp/object/general/proyectosdeinversion/proyectosdeinversionArray.interface';

@Injectable()
export class ProyectosDeInversionService {
  public domicilios: any [] = new Array();

  constructor(private http: HttpClient) {}

    // Muestra Proyectos de Inversión
    public proyectosDeInversionGet(cdgcl: string, clns: string, proyecto: string, giro: string, sector: string, nombrecl: string, cdgns: string, nombrens: string): Observable<ProyectosDeInversionInterface> {
      return this.http.post('api/control-cartera/operacion/proyectosdeinversion/get', {cdgcl: cdgcl, clns: clns, proyecto: proyecto, giro: giro, sector: sector, nombrecl: nombrecl, cdgns: cdgns, nombrens: nombrens}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

    // Crea / Actualiza un Proyecto de Inversón
    proyectoDeInversionPost(proyectoI: any): Observable<ProyectosDeInversionArrayInterface> {
      return this.http.post('api/control-cartera/operacion/proyectosdeinversion/post', { proyectoI: proyectoI }).pipe(
          map(res => JSON.parse(JSON.stringify(res)))
      );
    }

    // Elimina un Proyecto de Inversión
    proyectoDeInversionDelete(cdgclns: string, clns: string, proyecto: string): Observable<ProyectosDeInversionArrayInterface> {
      return this.http.post('api/control-cartera/operacion/proyectosdeinversion/delete', {cdgclns: cdgclns, clns: clns, proyecto: proyecto}).pipe(
        map(res => JSON.parse(JSON.stringify(res)))
      );
    }

    // Catálogo de Actividades Económicas
    public actividadEconomicaGet(codigo: string, cdgse: string, cdggi: string, nombre: string): Observable<AEInterface> {
      return this.http.post('api/general/ae/get', {codigo: codigo, cdgse: cdgse, cdggi: cdggi, nombre: nombre}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

    // Catálogo de Giros
    public giroGet(codigo: string, cdgse: string, nombre: string): Observable<GiInterface> {
      return this.http.post('api/general/gi/get', {codigo: codigo, cdgse: cdgse, nombre: nombre}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

    // Catálogo de Sectores
    public sectorGet(codigo: string, nombre: string): Observable<SeInterface> {
      return this.http.post('api/general/se/get', {codigo: codigo, nombre: nombre}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

    // Catálogo de Ubicación del Negocio
    public ubicacionDelNegocioGet(codigo: string, descripcion: string): Observable<UbNInterface> {
      return this.http.post('api/general/ubn/get', {codigo: codigo, descripcion: descripcion}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

}
