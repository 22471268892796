import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';


@Injectable()
export class PlazoDiaService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getInfoPlazoDia(pPGeJson: string, pPOtJson:string): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/plazodia/consultar', { pPGeJson: pPGeJson, pPOtJson: pPOtJson}

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public getDiaCero(jsonSC): Observable<any>{
        return this.http.post('api/control-cartera/operacion/prestamos/individual/diascero/consultar', 
            { jsonSC: jsonSC}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}