import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PrestamosPorEsquemaService {
    constructor(private http: HttpClient) {}

    /**
         * Consulta de esquemas
    */
    public getBusquedaEsquemas(codigo: string, nombre: string, tipo: string, modulo: string): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/esquemas/esquemasGet', 
            { "version": "0",'codigo': codigo, "nombre": "%", "tipo": tipo, "modulo": modulo})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
   
    /**
     * Consulta de columnas
     */
     public getBusquedaColumnas(cdged: string, fecha: string): Observable<any[]> {
       return this.http.post('api/control-cartera/reportes/prestamosporesquemas/columnasEncGet', 
           { 'cdged': cdged, "fecha": fecha})
       .pipe(
         map(res => {
           return JSON.parse(JSON.stringify(res));
         })
       );
     }

      /**
  * Consulta de columnas
  */
  public getBusquedaColumnasEsq(cdged: string): Observable<any[]> {
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/columnasGet', 
        { 'cdged': cdged, "nombre": "%", "orden":"%"})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
     /**
   * Consulta de acreditados.
   */
  public getBusquedaAcreditados(cdgcl: string, cdgns:string, clns:string ): Observable<any[]>{
    return this.http.post('api/control-cartera/reportes/prestamosporesquemas/acreditadosGet',
        {"version": "0","cdgcl": cdgcl, "cdgns": cdgns, "nombre":"%", "tipo": clns })
    .pipe(
      map(res =>{
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  /**
   * Consulta del reporte.
   */
  public getDatosReporte(cdgcl: string, cdgns:string, clns:string, ciclo:string, fecha: string, inicioD:string, inicioH: string,
        finalD: string, finalH: string, autC: string, autT:string, entr: string, liq: string, dev: string, rees: string): Observable<any[]>{
    return this.http.post('api/control-cartera/reportes/prestamosporesquemas/prestamoGet',
    {"version": "0","clns": clns, "cdgcl": cdgcl, "cdgns": cdgns, "ciclo":ciclo, "fecha": fecha,"inicioD": inicioD, "inicioH": inicioH,
    "finalD": finalD, "finalH": finalH,  "autC":autC, "autT": autT,"entr": entr,"liq": liq, "dev": dev,"rees": rees })
    .pipe(
    map(res =>{
    return JSON.parse(JSON.stringify(res));
    })
    )
  }

}