import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodigoDeVerificacionInterface } from '@comp/object/control-cartera/operacion/acreditados/codigodeverificacion/codigodeverificacion.interface';
import { CodigoDeVerificacionMasivoInterface } from '@comp/object/control-cartera/operacion/acreditados/codigodeverificacion/codigodeverificacionMasivo.interface';
import { TGMInterface } from '@comp/object/control-cartera/operacion/acreditados/codigodeverificacion/tgm.interface';

@Injectable()
export class CodigoDeVerificacionService {

    constructor(private http: HttpClient) {}

    // Genera código de verificación
    public codverifGet(cdgcl: string): Observable<CodigoDeVerificacionInterface> {
        return this.http.post('api/control-cartera/operacion/acreditados/codverif/get', { cdgcl: cdgcl }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    // Genera código de verificación masivo
    public codverifMasivoGet(): Observable<CodigoDeVerificacionMasivoInterface> {
        return this.http.post('api/control-cartera/operacion/acreditados/codverifM/get', {}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    // Consulta estatus de codigo de verificación masivo
    public estatusGet(): Observable<TGMInterface> {
        return this.http.post('api/control-cartera/operacion/acreditados/codverifM/status/get', {}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }
}