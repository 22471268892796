import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalendarioArrayInterface } from '@comp/object/control-cartera/operacion/movimientos/calendariopagos/calendario_array.interface';

@Injectable()
export class CalendarioPagos {
  constructor(private http: HttpClient) { }

  /**
   * Funcion obtener calendario de pagos
   */
  public datos(cdgFo: string, periodicidad: string, fechaEnvio: string, fechaPago: string, pANYO: string): Observable<CalendarioArrayInterface> {
    var pJSONCPN: any = {};

    (cdgFo != undefined && cdgFo != '') ? pJSONCPN.S_CDGFO = cdgFo : null;
    (periodicidad != undefined && periodicidad != '') ? pJSONCPN.S_PERIODICIDAD = periodicidad : null;
    (fechaEnvio != undefined && fechaEnvio != '') ? pJSONCPN.D_ENVIAR = fechaEnvio : null;
    if (fechaPago != undefined && fechaPago != '') {
      pJSONCPN.D_FECHA = fechaPago
      if (pANYO == '' || pANYO == undefined) {
        var s = fechaPago.toString().split('/');
        pANYO = s[2];
      }
    }

    return this.http.post('api/control-cartera/operacion/movimientos/calendariopagos/datos', {
      'pJSONCPN': pJSONCPN,
      'pANYO': pANYO
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  /**
   * Funcion para insertar un registro
   */
  public insertar(cdgPg: string, cdgFo: string, periodicidad: string, periodo: string, fechaEnvio: string, fechaPago: string): Observable<string> {
    var pJSONCPN = {
      S_CDGPG: cdgPg,
      S_CDGFO: cdgFo,
      S_PERIODICIDAD: periodicidad,
      S_PERIODO: periodo,
      D_FECHA: fechaPago,
      D_ENVIAR: fechaEnvio
    };

    return this.http.post('api/control-cartera/operacion/movimientos/calendariopagos/insertar', {
      'pJSONCPN': pJSONCPN
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  /**
   * Funcion para importar masivamente calendario de pagos
   */
  public importar(pJSON: string): Observable<string> {
    return this.http.post('api/control-cartera/operacion/movimientos/calendariopagos/importar', {
      'pJSON': pJSON
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  /**
  * Funcion para eliminar un registro
  */
  public eliminar(cdgPg: string, cdgFo: string): Observable<string> {
    let pJSONCPN = {
      S_CDGPG: cdgPg,
      S_CDGFO: cdgFo
    }

    return this.http.post('api/control-cartera/operacion/movimientos/calendariopagos/eliminar', {
      'pJSONCPN': pJSONCPN
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  /**
  * Funcion para editar calendario de pagos
  */
  public editar(cdgPg: string, cdgFo: string, fechaPago: string, fechaEnvio: string): Observable<string> {
    let pJSONCPN = {
      S_CDGPG: cdgPg,
      S_CDGFO: cdgFo,
      D_FECHA: fechaPago,
      D_ENVIAR: fechaEnvio
    };

    return this.http.post('api/control-cartera/operacion/movimientos/calendariopagos/editar', {
      'pJSONCPN': pJSONCPN
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }
}
