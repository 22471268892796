import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayTasaInteres } from "@comp/object/control-cartera/operacion/prestamos/tasa-interes/arraytasainteres.interface";


@Injectable()
export class TasaInteresService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getTasasInteres(): Observable<ArrayTasaInteres> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/tasa-interes/consultar', {}
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}