
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { FolioInterface } from "@comp/object/control-cartera/operacion/prestamos/folio/folio.interface";


@Injectable()
export class FolioService {

    constructor(private http: HttpClient) { }

    
    getFolio(): Observable<FolioInterface> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/folio/obtiene', {}
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}