import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    /**
     * @param router servicio que contiene el funcionamineto de las rutas
     */
    constructor(private location: Location, private router: Router) {

    }

    /**
     * Funcion para ir a la pantalla principal
     */
    public irInicio(): void {
        this.irA('/');
    }

    /**
     * Funcion para ir a la pantalla de inicio de sesión
     */
    public irLogin(): void {
        this.irA('/login');
    }

    /**
     * Funcion para ir a la pantalla de inicio de sesión admin
     */
    public irAdmin(): void {
        this.irA('/admin','',true);
    }

    /**
     * Funcion para ir a la pantalla de inicio de sesión user
     */
     public irUser(): void {
        this.irA('/user','',true);
    }

    /**
     * Funcion para ir a la pantalla de Registro de Usuario
     */
    public irRegistro(): void {
        this.irA('/registro');
    }

    /**
     * Funcion para ir a la pantalla de Fogotpassword
     */
    public irRecuperar(): void {
        this.irA('/resetpw');
    }

    //Control de cartera

    public irControl(): void{
        this.irAAdmin('controlcartera');
    }

    public irControlOperacionAcreditadoInv(): void{
        this.irAAdmin('controlcartera/operacion/acreditados/individuales');
    }

    public irControlOperacionAcreditadoInvNuevo(): void{
        this.irAAdmin('controlcartera/operacion/acreditados/individuales/nuevo');
    }

    public irControlOperacionAcreditadoInvNuevoIdue(): void{
        this.irAAdmin('controlcartera/operacion/acreditados/individuales/nuevo/idue');
    }

    public irControlOperacionAcreditadoInvEditar(codigo: string): void{
        this.irAAdmin('controlcartera/operacion/acreditados/individuales/editar/'+codigo);
    }

    public irControlOperacionPantallalistadoatr(): void{
        this.irAAdmin('controlcartera/operacion/pantallalistadoatr');
    }

    public irControlOperacionImportacionidue(): void{
        this.irAAdmin('controlcartera/operacion/importacionidue');
    }

    public irControlOperacionMovimientosextraordinariocredito(): void{
        this.irAAdmin('controlcartera/operacion/movimientosextraordinarios-credito');
    }

    public irControlOperacionConciliacionRapida(): void{
        this.irAAdmin('controlcartera/operacion/conciliacionrapida');
    }

    public irControlResportesReporteCS(): void{
        this.irAAdmin('controlcartera/reportes/reporteCS');
    }

    public irControlResportesCirculo(): void{
        this.irAAdmin('controlcartera/reportes/reportecirculocredito');
    }

    public irControlResportesCirculoDetalle(id: string): void{
        this.irAAdmin('controlcartera/reportes/circulocredito/'+id);
    }

    public irParametrosEstadoDeCuenta(): void{
        this.irAAdmin('controlcartera/reportes/parametrosestadodecuenta');
    }

    public irReporteEstadoDeCuenta(): void{
        this.irAAdmin('controlcartera/reportes/reporteestadodecuenta');
    }

    public irParametrosEstadoDeCuentaPeriodo(): void{
        this.irAAdmin('controlcartera/reportes/parametrosestadodecuentaperiodo');
    }

    public irReporteEstadoDeCuentaPeriodo(): void{
        this.irAAdmin('controlcartera/reportes/reporteestadodecuentaperiodo');
    }

    public irReporteSolicitudEsquemas(): void{
        this.irAAdmin('controlcartera/reportes/solicitudporesquema')
    }
    public irReportePrestamosEsquemas(): void{
        this.irAAdmin('controlcartera/reportes/prestamosporesquema')
    }


    public irCatalogosEsquemas(id:string, rep:string): void{
        this.irAAdmin('controlcartera/catalogos/esquemas/'+id+'/' +rep)
    }

    public irCrearAclaraciones(): void {
        this.irAAdmin('controlcartera/reportes/aclaraciones/crear-aclaracion')
    }

    public irListadoAclaraciones(): void {
        this.irAAdmin('controlcartera/reportes/aclaraciones/listado-aclaraciones')
    }
    public irChatAclaraciones(): void {
        this.irAAdmin('controlcartera/reportes/aclaraciones/chat-aclaraciones')
    }
    //solicitudes acreditado
    public irControlOperacionSolicitudesAcreditado(): void{
        this.irAAdmin('controlcartera/operacion/solicitudesacreditado');
    }

    // Administración de tesorería

    public irAdministracionTesoreria(){
        this.irAAdmin('administraciontesoreria');
    }

    public irAdministracionTesoreriaOperacionImportacionA(){
        this.irAAdmin('administracion-tesoreria/operacion/imporatcion-afiliaciones');
    }
    public irTablaAmorCred(): void{
        this.irAAdmin('controlcartera/reportes/amortizacionconcredito/tabla');
    }

    public irTablaAmorSon(): void{
        this.irAAdmin('controlcartera/reportes/amortizacionconsolicitud/tabla');
    }

    public irTablaAmorSinSol(): void{
        this.irAAdmin('controlcartera/reportes/amortizacionsinsolicitud/tabla');
    }

    // Ahorros

    public irAhorros(){
        this.irAAdmin('ahorros');
    }

    public irAhorrosOperacionLiquidacion(){
        this.irAAdmin('ahorros/operacion/liquidacion');
    }

    public irAhorrosOperacionCuenta(){
        this.irAAdmin('ahorros/operacion/cuenta');
    }

    public irAhorrosOperacionCuentaDeAhorro(){
        this.irAAdmin('ahorros/operacion/cuentadeahorro');
    }

    public irAhorrosOperacionEditar(){
        this.irAAdmin('ahorros/operacion/editar');
    }

    public irAhorrosOperacionSolicitud(){
        this.irAAdmin('ahorros/operacion/solicitud');
    }

    public irAhorrosOperacionSolicitudCuenta(id: string){
        this.irAAdmin('ahorros/operacion/solicitud/cuentas/'+id);
    }

    public irAhorrosCatalogosRango(){
        this.irAAdmin('ahorros/catalogos/rango');
    }
  public irAhorrosCatalogosEsquema() {
    this.irAAdmin('/admin/ahorros/catalogos/esquema');
    }

    public irAhorrosReportesSituaciondecuentasdeahorroEO(){
        this.irAAdmin('ahorros/reportes/situaciondecuentasdeahorroEO');
    }

    public irAhorrosReportesRepostesEstadoDeCuenta(){
        this.irAAdmin('ahorros/reportes/reporteestadodecuenta');
    }

    // Bancos

    public irBancos(){
        this.irAAdmin('bancos');
    }

    public irBancosCatalogosPuntosVenta(){
        this.irAAdmin('bancos/catalogos/puntosventa');
    }

    // Inversionistas

    public irInversionistas(){
        this.irAAdmin('inversionistas');
    }

    public irInversionistasOperacionesAgrupacioncreditosafondear(){
        this.irAAdmin('inversionistas/operacion/agrupacioncreditosafondear');
    }

    public irInversionistasOperacionesCredito(){
        this.irAAdmin('inversionistas/operacion/credito');
    }

    public irInversionistasReportesEstadodecuenta(){
        this.irAAdmin('inversionistas/reportes/estadodecuenta');
    }

    public irInversionistasReportesEstadoDeCuentaResumido() {
        this.irAAdmin('inversionistas/reportes/estadodecuentaresumido')
    }

    public irInversionistasOperacionAcreditadoInv(): void{
        this.irAAdmin('inversionistas/operacion/acreditados/individuales');
    }

    public irInversionistasOperacionAcreditadoInvNuevo(): void {
        this.irAAdmin('inversionistas/operacion/acreditados/individuales/nuevo');
    }

    public irInversionistasOperacionAcreditadoInvEditar(codigo: string): void {
        this.irAAdmin('inversionistas/operacion/acreditados/individuales/editar/'+codigo);
    }

    //Pld
    public irPldOperacionesAcumuladas(){
        this.irAAdmin('pld/operacion/operacionesacumuladas');
    }

    public iraraciones(): void {
        this.irA('araciones/cl')
    }

    // Gestión de Cobranza
    public irGestinDeCobranza(){
        this.irAAdmin('gestioncobranza');
    }

    private irAAdmin(to: string, path?: string, skip = false): void {
        this.irA('/admin/'+to, path, skip);
    }

    private irAUser(to: string, path?: string, skip = false): void {
        this.irA('/user/'+to, path, skip);
    }

    /**
     *
     * @param to path a donde navegara
     */
    private irA(to: string, path?: string, skip = false): void {
        if (path === undefined || path === null) {
            path = '';
        }
        this.router.navigateByUrl(to + path, { skipLocationChange: skip });
    }

    public getPath() {
        if (this.location.path() !== '') {
            return this.location.path();
        } else {
            return '';
        }
    }

    public goBack(){
        this.location.back();
    }

    public urlParams() {
        let urlTree = this.router.parseUrl(this.router.url);
        return urlTree;
    }

}
