import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class YuniusMapService {
  config = {
    enableHighAccuracy: true, 
    maximumAge: 0, 
    timeout: Infinity
  };
  latitude: number;
  longitude: number;
  posiblesErrores = {
    0: 'Error desconocido',
    1: 'Permiso de geolocalización denegado por el usuario.',
    2: 'Posición no disponible',
    3: 'Desconexión por tiempo'
  }

  constructor(private http: HttpClient) {}

  // Consulta dirección por Latitud y Longitud
  public getAddress(latLng: string): Observable<{}> {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latLng + '&key=AIzaSyAJ4I0GxT0__5hV7rXHxmi9-uq2W0YJ7Hg').pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene las coordenadas de la app
  getPosition(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      }, error => {
        var mensajeError = this.posiblesErrores[error.code];
        reject({code: error.code, message: mensajeError});
        // resolve({code: error.code, message: mensajeError});
      }, this.config);
    });
  }

}
