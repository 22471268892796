import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ArrayDetallesInversion } from "@comp/object/inversionistas/operaciones/detalles-inversion/arraydetallesinversion.interface";


@Injectable()
export class DetallesInversionService {
   
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }


    guardarDetalleDeInversion(detalleDeInversion: any) {        

        return this.procesado = this.http.post('api/inversionistas/operaciones/detalle-inversion/crear', detalleDeInversion
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarDetalleDeInversion(detalleDeInversion: any) {
        return this.procesado = this.http.post('api/inversionistas/operaciones/detalle-inversion/editar', detalleDeInversion
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    buscarDetallesDeinversion(): Observable<ArrayDetallesInversion> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar',{cdgcl: null ,cdgpif: null }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    buscarDetalleDeinversion(vCdgcl: string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar', { id: { cdgcl: vCdgcl } }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    
    buscarDetalleDeinversionPIF(cdgpif: string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar', {id: { cdgcl: null }, cdgpif: cdgpif }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}