'use strict';

import * as angular from 'angular';
import { Injectable } from '@angular/core';
import { WindowRef } from './windowsRef';

/**
 * The Util service is for thin, globally reusable, utility functions
 */
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private windowref: WindowRef) {}

  /**
   * Return a callback or noop function
   *
   * @param  {Function|*} cb - a 'potential' function
   * @return {Function}
   */
  public safeCb(cb: Function | any): Function {
    return (angular.isFunction(cb)) ? cb : angular.noop;
  }

  /**
   * Parse a given url with the use of an anchor element
   *
   * @param  {string} url - the url to parse
   * @return {object}     - the parsed url, anchor element
   */
  public urlParse(url: string): object {
    const a = document.createElement('a');
    a.href = url;

    // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
    if (a.host === '') {
      a.href = a.href;
    }

    return a;
  }

  /**
   * Test whether or not a given url is same origin
   *
   * @param  {string}           url       - url to test
   * @param  {string|String[]}  [origins] - additional origins to test against
   * @return {boolean}                    - true if url is same origin
   */
  public isSameOrigin(url: string, origins?: string | string[]): boolean {
    const a_url: any = this.urlParse(url);
    let tem_origins: object[] = (origins && [].concat(origins)) || [];
    tem_origins = tem_origins.map(function(obj) {
      return a_url;
    });
    tem_origins.push(this.windowref.nativeWindow.location);
    tem_origins = tem_origins.filter(function(o: any) {
      const hostnameCheck = a_url.hostname === o.hostname;
      const protocolCheck = a_url.protocol === o.protocol;
      // 2nd part of the special treatment for IE fix (see above):
      // This part is when using well-known ports 80 or 443 with IE,
      // when $window.location.port==='' instead of the real port number.
      // Probably the same cause as this IE bug: https://goo.gl/J9hRta
      const portCheck = a_url.port === o.port || (o.port === '' && (a_url.port === '80' || a_url.port === '443'));
      return hostnameCheck && protocolCheck && portCheck;
    });
    return tem_origins.length >= 1;
  }

}
