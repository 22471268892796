import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VisitasDeVerificacionInterface } from '@comp/object/general/visitas-de-verificacion/visitas-de-verificacion.interface';

@Injectable()
export class VisitasDeVerificacionService {

  constructor(private http: HttpClient) {}

  // Valores de parametro PS_VP
  public visitasDeVerificacionGet(cdgclns: string, ciclo: string, clns: string, cdgpi: string): Observable<VisitasDeVerificacionInterface> {
    console.log('visitasDeVerificacionGet')
    return this.http.post('api/general/vv/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, cdgpi: cdgpi}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }


}
