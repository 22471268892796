import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ImportacionAfiliacionesService {

    constructor(private http: HttpClient) {}

    /**
     * Método para importar afiliaciones
     */
    public importar(json: string): Observable<any[]> {
        return this.http.post("api/administracion-tesoreria/operacion/importacion-afiliaciones/set", 
        {  "jsonPpr": {},
            "jsonCbc": json }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    /**
     * Método para generar reporte
     */
    public reporteExcel(data: any){
        return this.http.post("api/administracion-tesoreria/operacion/importacion-afiliaciones/xlsx", data, {responseType: 'blob'});
    }
}