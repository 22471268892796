import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
//import { SatDatepicker, SatDatepickerInputEvent, SatDatepickerRangeValue } from 'saturn-datepicker';
import * as moment from 'moment';
moment.locale('es');

@Component({
    selector: 'mat-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class DateRageComponent implements OnInit{

    public infoselec: string = "Hoy";
    public itemselect: string = "hoy";
    public subitemselect: string = "";
    public date1: Date;
    public date2: Date;
    @Output()
    private rangeselect = new EventEmitter<Range>();
    @ViewChild('picker')
   // public picker: SatDatepicker<Date>;
    public maxDate: Date;

    public pressetList: Array<ItemMenu> = [
        {key:"hoy",text:"Hoy"},
        {key:"ayer",text:"Ayer"},
        {key:"esta_semana",text:"Esta semana", child:"estaSemana"},
        {key:"pasada_semana",text:"La semana pasada", child:"semanaPasada"},
        {key:"dias_7",text:"Los últimos 7 días"},
        {key:"dias_28",text:"Los últimos 28 días"},
        {key:"dias_30",text:"Los últimos 30 días"},
        {key:"dias_90",text:"Los últimos 90 días"},
        {key:"dias_12",text:"Los últimos 12 meses"},
        {key:"ultimo_anio",text:"Último año natural"},
        {key:"anio",text:"Este año (de enero a hoy)"}
    ];

    public thisweek: Array<ItemMenu> = [
        {key:"dom",text:"De dom. a hoy"},
        {key:"lun",text:"De lun. a hoy"},
        {key:"sab",text:"De sáb. a hoy"}
    ];

    public lastweek: Array<ItemMenu> = [
        {key:"doml",text:"De dom. a dom"},
        {key:"lunl",text:"De lun. a lun"},
        {key:"sabl",text:"De sáb. a sáb"}
    ];

    constructor(overlayContainer: OverlayContainer){
        overlayContainer.getContainerElement().classList.add('plan-light-theme');
        this.maxDate = new Date();
        this.date1 = new Date();
        this.date2 = new Date();
    }

    ngOnInit(): void {
        this.rangeselect.emit({first:this.date1,end:this.date2});
    }

    public chosse(selec: string | ItemMenu){
        if(selec === 'custom'){
           // this.picker.open();
        }else{
            const item = (selec as ItemMenu);
            const key = item.key;
            this.infoselec = item.text;
            if(key === 'dom' || key === 'lun' || key === 'sab'){
                this.itemselect = "esta_semana";
                this.subitemselect = key;
            } else
            if(key === 'doml' || key === 'lunl' || key === 'sabl'){
                this.itemselect = "pasada_semana";
                this.subitemselect = key;
            } else {
                this.subitemselect = "";
                this.itemselect = key;
            }
            switch(key){
                case 'hoy':
                    this.addDate(0);
                    break;
                case 'ayer':
                    this.date1 = new Date();
                    this.date1.setDate(this.date1.getDate()-1);
                    this.date2 = new Date();
                    this.date2.setDate(this.date2.getDate()-1);
                    break;
                case 'dias_7':
                    this.addDate(6);
                    break;
                case 'dias_28':
                    this.addDate(27);
                    break;
                case 'dias_30':
                    this.addDate(29);
                    break;
                case 'dias_90':
                    this.addDate(89);
                    break;
                case 'dias_12':
                    this.date1 = new Date();
                    this.date2 = new Date();
                    this.date2.setDate(this.date2.getDate()+1);
                    this.date2.setMonth(this.date2.getMonth()-12);
                    break;
                case 'ultimo_anio':
                    this.date1 = new Date();
                    this.date1.setDate(1);
                    this.date1.setMonth(11);
                    this.date1.setFullYear(this.date1.getFullYear()-1);
                    this.date1.setDate(31);
                    this.date2 = new Date();
                    this.date2.setDate(1);
                    this.date2.setMonth(0);
                    this.date2.setFullYear(this.date2.getFullYear()-1);
                    break;
                case 'anio':
                    this.date1 = new Date();
                    this.date2 = new Date();
                    this.date2.setDate(1);
                    this.date2.setMonth(0);
                    break;
                case 'dom':
                    this.date1 = new Date();
                    this.date2 = new Date();
                    if(this.date2.getDay() != 0){
                        this.date2.setDate(this.date2.getDate() - this.date2.getDay());
                    }
                    break;
                case 'lun':
                    this.date1 = new Date();
                    this.date2 = new Date();
                    if(this.date2.getDay() != 1){
                        let res = 0;
                        if(this.date2.getDay() === 0){
                            res = 6
                        }else{
                            res = this.date2.getDay() - 1;
                        }
                        this.date2.setDate(this.date2.getDate() - res);
                    }
                    break;
                case 'sab':
                    this.date1 = new Date();
                    this.date2 = new Date();
                    if(this.date2.getDay() != 6){
                        let res = 0;
                        if(this.date2.getDay() === 0){
                            res = 1
                        }else{
                            res = this.date2.getDay() + 1;
                        }
                        this.date2.setDate(this.date2.getDate() - res);
                    }
                    break;
                case 'doml':
                    this.date2 = new Date();
                    this.date1 = new Date();
                    if(this.date1.getDay() != 0){
                        this.date1.setDate(this.date1.getDate() - this.date1.getDay());
                    }
                    this.date2.setDate(this.date1.getDate()-7);
                    break;
                case 'lunl':
                    this.date2 = new Date();
                    this.date1 = new Date();
                    if(this.date1.getDay() != 1){
                        let res = 0;
                        if(this.date1.getDay() === 0){
                            res = 6
                        }else{
                            res = this.date1.getDay() - 1;
                        }
                        this.date1.setDate(this.date1.getDate() - res);
                    }
                    this.date2.setDate(this.date1.getDate()-7);
                    break;
                case 'sabl':
                    this.date2 = new Date();
                    this.date1 = new Date();
                    if(this.date2.getDay() != 6){
                        let res = 0;
                        if(this.date1.getDay() === 0){
                            res = 1
                        }else{
                            res = this.date1.getDay() + 1;
                        }
                        this.date1.setDate(this.date1.getDate() - res);
                    }
                    this.date2.setDate(this.date1.getDate()-7);
                    break;

            }
            this.rangeselect.emit({first:this.date2,end:this.date1});
        }
    }

    private addDate(val: number){
        this.date1 = new Date();
        this.date2 = new Date();
        this.date2.setDate(this.date2.getDate()-val);
    }

    /*public onDateChange(date: SatDatepickerInputEvent<Date>){
        this.itemselect = "custom";
        this.subitemselect = "";
        this.date1 = (date.value as SatDatepickerRangeValue<Date>).end;
        this.date2 = (date.value as SatDatepickerRangeValue<Date>).begin;
        this.infoselec = moment(this.date2).format('DD MMM YYYY') + "-" + moment(this.date1).format('DD MMM YYYY');
        this.rangeselect.emit({first:this.date2,end:this.date1});
    }*/
}

export interface ItemMenu{
    text:string; 
    key:string; 
    child?:string
}

export interface Range{
    first:Date; 
    end:Date; 
}