'use strict';

import { Datos, Role } from './Datos';

export class DatosC extends Datos {
    public nombre: string;
    public correo: string;
    public usuario: string;
    public cdgem: string;
    public logo: string;
    public genma: boolean;

    constructor(obj: any, role: Role) {
        super(obj, role);
        this.nombre = obj.nombre;
        this.correo = obj.correo;
        this.usuario = obj.usuario;
        this.cdgem = obj.cdgem;
        this.genma = obj.genma;
        this.logo = obj.logo;
    }
}

export class UserCl {
    public datos: DatosC;
    public token: string;
    public error: string;
    public error_description: string;
    public code: string;
    public active: string;
}
