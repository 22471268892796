import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';


@Injectable()
export class MovInversionistasService {

    //movimientosInversion: Observable<MovimientoInversionInterface>;
    listaInversionistas: Observable<any>;

    constructor(private http: HttpClient) { }

    buscarInversionistas(): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/cliente-inversionista/buscar', {
            id: { cdgcl: "" }, inicio: 0, limite: 600
          }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    buscarInversionistasDif(): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/cliente-inversionista/buscarConDif', {
                     }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}