import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CiclosInterface } from '@comp/object/control-cartera/catalogos/productos-credito/ciclos.interface';

@Injectable()
export class CIService {

    constructor(private http: HttpClient) {}

     /**
     * Consulta de ciclos
     */
    public getCi(pCi): Observable<CiclosInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/ciclos/getCi', 
            { 'pCi': pCi})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Insertar de ciclos
     */
      public agregarCi(jsonCi): Observable<CiclosInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/ciclos/insertarCi', 
        {  "jsonCi": jsonCi, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Editar de ciclos
     */
      public editarCi(jsonCi): Observable<CiclosInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/ciclos/editarCi', 
            {  "jsonCi": jsonCi, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Eliminar de ciclos
     */
      public eliminarCi(jsonCi): Observable<CiclosInterface> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/ciclos/eliminarCi', 
            {  "jsonCi": jsonCi, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
}