import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayArchivoExcel } from '@comp/object/control-cartera/operacion/movimientos/importaciones/depositosbancarios/archivoexcel/archivoexcel_array.interface';

@Injectable()
export class ArchivoExcelService {

    constructor(private http: HttpClient) { }

  public obtenerDatosArchivo(clave: string): Observable<ArrayArchivoExcel> {
      return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/archivoexcel/obtiene', {
        'clave': clave
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}
