import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmiInterface } from '@comp/object/general/programaDeMinistraciones/pmi.interface';

@Injectable()
export class ProgramaDeMinistracionesService {

  constructor(private http: HttpClient) {}

  // Lista registros de Programa de Ministraciones
  public pmiGet(cdgcl: string, ciclo: string, clns: string, cdgclns: string): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/get', {cdgcl: cdgcl, ciclo: ciclo, clns: clns, cdgclns: cdgclns}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Actualiza Programa de Ministraciones
  public pmiSet(ministracion: any): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/set', ministracion).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Se agregan ministraciones
  public pmiInsertar(pmiJson: any): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/insertar', {pmiJson: pmiJson}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Edicion de Ministraciones
  public pmiEditar(pmiJson: any): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/editar', {pmiJson: pmiJson}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina Ministraciones
  public pmiEliminar(pmiJson: any): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/eliminar', {pmiJson: pmiJson}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Validacion de fecha
  public fechaVen(jsonPMI: any, jsonPRC: any, jsonMP: any): Observable<PmiInterface> {
    return this.http.post('api/general/pmi/fechaVen',
     {"jsonPMI":jsonPMI, "jsonPRC":jsonPRC, "jsonMP":jsonMP}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
