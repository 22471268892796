import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayBeneficiarios } from "@comp/object/control-cartera/operacion/prestamos/beneficiario/arraysolicitudes.interface";


@Injectable()
export class AseguradoraService {


    constructor(private http: HttpClient) { }

    /**
     * Consutar aseguradoras
     */
    public getAseguradoras(): Observable<any[]> {
        return this.http.post('api/ahorros/listados/aseguradora/get', {

        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }


}


