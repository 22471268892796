import { Injectable } from '@angular/core';
import { AmortizacionBase, TA, ItemTA, EncData } from './amortizacion-base';
import { DynamicFormModel } from '@comp/dynamic-form/core/model/dynamic-form.model';
import { DynamicDivGroupModel } from '@comp/dynamic-form/core/model/div/dynamic-div-group.model';
import { DynamicCheckboxModel } from '@comp/dynamic-form/core/model/checkbox/dynamic-checkbox.model';
import { DynamicRadioGroupModel } from '@comp/dynamic-form/core/model/radio/dynamic-radio-group.model';
import { DynamicFormCustomControlModel } from '@comp/dynamic-form/core/model/custom/dynamic-custom.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import Utils from '@comp/util/utils';
import { DynamicInputModel } from '@comp/dynamic-form/core/model/input/dynamic-input.model';
import { AmortizacionService } from '../amortizacionconcredito/amortizacionconcredito.service';
import { GeCatService } from './gecat.service';

@Injectable()
export class AmortizacionConCreditoService extends AmortizacionBase {
    tatiper: string = 'Periodicidad';
    public tatisegp: string = 'Seguro periódico';
    public tasegper: string = '';
    public taticomp: string = 'Comisión periódica';
    public tacomper: string = '';
    // public tatisegs: string = 'Seguro';
    // public comseg: string = '';
    public tafegeen: string = 'N';

    constructor(private http: HttpClient,private amorCredito: AmortizacionService, public gecatService: GeCatService) {
        super();
    }

    private situacion = new DynamicDivGroupModel({
        id: 'situacion_div',
        group: [

            new DynamicFormCustomControlModel({
                id: 'titulo5'
            }),

            new DynamicDivGroupModel({
                id: 'situacion',
                group: [
                    new DynamicCheckboxModel({

                        id: 'cartera',
                        label: 'Autorizado por cartera'
                    }),
                    new DynamicCheckboxModel({

                        id: 'tesoreria',
                        label: 'Autorizado por tesorería'
                    }),
                    new DynamicCheckboxModel({

                        id: 'saldo',
                        label: 'Con saldo',
                        value: true
                    }),
                    new DynamicCheckboxModel({

                        id: 'liquidado',
                        label: 'Liquidado'
                    })
                ],
                fxLayoutAling: {
                    def: 'start stretch',
                    md: 'start stretch'
                },
                fxLayout: {
                    def: 'column',
                    md: 'column'
                }
            })
        ],
        fxLayout: {
            def: 'column',
            md: 'column'
        }
    });

    private referencias = new DynamicDivGroupModel({
        id: 'referencias',
        group: [
            new DynamicCheckboxModel({

                id: 'bancomer',
                label: 'Bancomer BBVA',
                value: false
            }),
            new DynamicCheckboxModel({

                id: 'hsbc',
                label: 'HSBC'
            }),
            new DynamicCheckboxModel({

                id: 'base',
                label: 'Base 0'
            })
        ]
    });

    public arrendamientoGroup = new DynamicDivGroupModel({
        id: 'cliente_arrendamiento',
        // hidden: true,
        group: [
            new DynamicCheckboxModel({
                id: 'clarren',
                label: 'Para cliente arrendamiento',
                value: undefined,
                hidden: true
            })     
        ]
    });

    public  versionGroup = new DynamicDivGroupModel({
        id: 'version_group',
        group: [
            new DynamicInputModel({
                id: 'version',
                label: 'Versión',
                disabled: true,
                flex: '150px',
                hidden: false
            }),    
            new DynamicFormCustomControlModel({
                id: 'busquedaV',
                hidden: false
            })      
        ]
    });


    private redGroup = new DynamicCheckboxModel({
        id: 'red_group',
        label: 'Redondeo para grupo',
        value: false,
        disabled: true
    });

    private tasa = new DynamicRadioGroupModel<string>({

        id: 'utilizar_tasa',
        label: 'Utilizar tasa',
        value: 'reg',
        options: [
            {
                label: 'Regular',
                value: 'reg'
            },
            {
                label: 'Con descuento',
                value: 'des'
            }
        ]
    });

    private tituloRef = new DynamicFormCustomControlModel({
        id: 'titulo2'
    });

    public getElementos(): DynamicFormModel {
        const base = this.getElementosBase();
        const root = base[0] as DynamicDivGroupModel;
        const pres = root.get(6) as DynamicDivGroupModel;
        const tipo = pres.get(2) as DynamicDivGroupModel;
        tipo.insert(2, this.redGroup);
        root.insert(2, this.tituloRef);
        root.insert(3, this.referencias);
        const arrendamiento = root.get(6) as DynamicDivGroupModel;
        arrendamiento.insert(0, this.arrendamientoGroup);
        const versiones = root.get(6) as DynamicDivGroupModel;
        versiones.insert(1, this.versionGroup);
        const fecha = root.get(6) as DynamicDivGroupModel;
        fecha.insert(2, this.situacion);
        const tasa = root.get(9) as DynamicDivGroupModel;
        tasa.insert(0, this.tasa);
        return base;
    }

    public getResult(data: any) {
        this.request = data;
        return this.http.post('api/control-cartera/reportes/tablaamortizacion/buscarCredito', data).pipe(map((val: TACC) => {
            this.result = val;
            //console.log("RESULTADO DE API: ", this.result);
            val.pagoAmortizacion = val.creditoArray;
            return val;
        }));
    }

    public getResultcl(data: any) {
        this.request = data;
        return this.http.post('api/control-cartera/reportes/tablaamortizacion/buscarCreditocl', data).pipe(map((val: TACC) => {
            this.result = val;
             //console.log("RESULTADO DE API cl: ", this.result);
            val.pagoAmortizacion = val.creditoArray;
            return val;
        }));
    }

    public getExcell(data: any){
        return this.http.post('api/general/elementos/excell',data,{responseType: 'blob'});
    }

    public getEncData(): Array<Array<EncData>> {
        let check: any = this.arrendamientoGroup.group[0] as DynamicDivGroupModel;
        const result = this.result as TACC;
        // console.log('result: ', result);
        const data: Array<Array<EncData>> = [];
        const data1: Array<EncData> = [];
        data1.push({
            titulo: 'Acreditado ( ' + this.getTipo() + ' )',
            valor: result.codigo + ' ' + result.nombre,
        });
        data1.push({
            titulo: 'Ciclo',
            valor: result.ciclo
        });
        if (this.result.tacontra == 'S') {
            if (check.value == false) {
                data1.push({
                    titulo: result.tinucon,
                    valor: result.contrato,
                });
            }
        }
        data1.push({
            titulo: result.tatiinic,
            valor: result.fechainicio,
        });
        data1.push({
            titulo: result.tatifin,
            valor:  result.ffin
        })       
        data1.push({
            titulo: 'Crédito',
            valor: Utils.formatNumber(result.montopr),
        });
        if (!check.value) {
            data1.push({
                titulo: 'Monto movimiento bancario',
                valor: Utils.formatNumber(result.montocheque),
            });
        }
        data1.push({
            titulo: 'Método de cálculo de interés',
            valor: result.cdgmci /*+ ' ' + result.nombremci*/,
        });
        data1.push({
            titulo: 'Moneda',
            valor: result.nommoneda
        })
        data1.push({
            titulo: 'Producto de crédito',
            valor: result.nombre_pcr,
        });
        data.push(data1);
        const data2: Array<EncData> = [];
        data2.push({
            titulo: 'Plazo',
            valor: `${result.plazo}`,
        });
        var periodicidad;
        if ((result.plazodia >=7) || ((result.finipag !== '') && (result.plazofinipag < 7) && (result.plazofinipag > 0))){
            result.periodicidad = 'A';
            periodicidad = 'Al vencimiento';
            data2.push({
                titulo: 'Número de días:',
                valor: result.plazodia.toString(),
            })
        }else{
            periodicidad = this.getPeriodicidad()
        }
        data2.push({
            titulo: this.tatiper,
            valor: periodicidad
        });
        if (!check.value) {
            if(this.extraInfo.tapergrc == 'S'){
                data2.push({
                    titulo: 'Periodos de gracia al capital',
                    valor: result.periograciacap + ' ' + this.getPeriodicidadT(result.periograciacap)
                });
            } 
            if(this.extraInfo.tapergri == 'S'){
                data2.push({
                    titulo: 'Periodo de gracia al interés',
                    valor: result.periograciaint + ' ' + this.getPeriodicidadT(result.periograciaint)
                });
            }
        }
        if (this.tasegvid == 'S') {
            data2.push({
                titulo: result.lysegini, //Seguro de vida inicial
                valor: Utils.formatNumber(result.cominisolosv),
            });
        }
        data2.push({
            titulo: result.lycomini, //Comisión inicial
            // valor: Utils.formatNumber(result.comini),
            valor: Utils.formatNumber(result.cominisinsv),
        });
        if (this.tasegper == 'S' && !check.value) {
            data2.push({
                titulo: this.tatisegp, //Seguros periódicos
                valor: Utils.formatNumber(result.totsegper)
            });
        }
        if (this.tacomper == 'S') {
            data2.push({
                titulo: this.taticomp, //Comisiones periódicas
                // valor: Utils.formatNumber(result.totcomper)
                valor: this.getComisionPer(result.totsegper, result.totcomper)
            });
        }
        data2.push({
            titulo: 'Garantía',
            valor: Utils.formatNumber(result.garantia),
        });
        data.push(data2);

        const data3: Array<EncData> = [];
        if (this.extraInfo.imprimir) {
            let valTasa = '';
            if (this.extraInfo.t_int === 'c_iva') {
                if (this.extraInfo['tasa-tiempo'] === 'S') {
                    valTasa = ((result.tasaanual / 12) / 4).toFixed(2) + ' % semanal';
                } else if (this.extraInfo['tasa-tiempo'] === 'M') {
                    valTasa = (result.tasaanual / 12).toFixed(2) + ' % mensual';
                } else {
                    valTasa = result.tasaanual.toFixed(2) + ' % anual';
                }
            } else {
                if (this.extraInfo['tasa-tiempo'] === 'S') {
                    valTasa = ((result.tasaanualsiniva / 12) / 4).toFixed(2) + ' % semanal';
                } else if (this.extraInfo['tasa-tiempo'] === 'M') {
                    valTasa = (result.tasaanualsiniva / 12).toFixed(2) + '% mensual';
                } else {
                    valTasa = result.tasaanualsiniva.toFixed(2) + ' % anual';
                }
            }
            var tasa;
            if (this.extraInfo.t_int === 'c_iva') {
                tasa = " con IVA";
            } else {
                tasa = " sin IVA";
            }
            if (!check.value) {
                data3.push({
                    titulo: result.tatasa + tasa,
                    valor: valTasa,
                });
                data3.push({
                    titulo: 'CAT sin IVA',
                    valor: result.cat_bm !== 'null' ? result.cat_bm + '%' : '0 %',
                });
            }
            // data3.push({
            //     titulo: result.lysegini,
            //     valor: result.cominisolosv + '',
            // });
            // data3.push({
            //     titulo: result.lycomini,
            //     valor: Utils.formatNumber(result.comini),
            // });
            if (this.extraInfo.bancomer) {
                data3.push({
                    titulo: 'BBVA',
                    valor: result.ref_banc1,
                });
            }
            if (this.extraInfo.hsbc) {
                data3.push({
                    titulo: 'HSBC',
                    valor: result.ref_banc2,
                });
            }
            if (this.extraInfo.base) {
                data3.push({
                    titulo: 'Base 0',
                    valor: result.ref_banc3,
                });
            }
        }
        data.push(data3);
        return data;
    }

    private getTipo() {
        switch (this.request.clns) {
            case 'I':
                return 'Individual';
            case 'IG':
                return 'Individual de Grupo';
            case 'G':
                return 'Grupal';
        }
    }

}

interface TACC extends TA {
    ahorro: number;
    apagarint: number;
    cat_bm: number|string;
    cat_tir: number;
    cdgns: string;
    cominisinsv: number;
    cominisolosv: number;
    contrato: string;
    montocheque: number;
    nombre_pcr: string;
    nombreem: string;
    nombremci: string;
    pagoAmortizacion: Array<ItemTACC>;
    parcialidad: number;
    parcialidadseg: number;
    ref_banc1: string;
    ref_banc2: string;
    ref_banc3: string;
    tatiinic: string;
    tit_ah: string;
    ffin: string;
    nommoneda: string;
    tinucon: string;
    creditoArray;
    totsegper: number;
    totcomper: number;
    garantia: number;    
    plazodia: number;
    finipag: string;
    plazofinipag: number;
}

interface ItemTACC extends ItemTA {
    cantidadtotal: number;
    comisiontotal: number;
    total: number;
    totalgrp: number;
    etiquetafecha: string;
}
