import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RhInterface } from '@comp/object/general/acreditados/rh/rh.interface';
import { ActividadesVInterface } from '@comp/object/general/acreditados/pld/actividadesvulnerables/actividadesV.interface';
import { DelitosPeInterface } from '@comp/object/general/acreditados/pld/delitosprecedentes/delitosPe.interface';
import { ClArrayInterface } from '@comp/object/general/acreditados/cl/clarray.interface';
import { NsArrayInterface } from '@comp/object/general/acreditados/ns/nsarray.interface';
import { CoordinacionInterface } from '@comp/object/general/coordinacion/coordinacion.interface';
import { DomArrayInterface } from '@comp/object/general/domicilio/domArray.interface';
import { McoArrayInterface } from '@comp/object/general/mediosdecomunicacion/mcoArray.interface';
import { VpArrayInterface } from '@comp/object/general/valorparametro/vpArray.interface';
import { CoordinacionPRInterface } from '@comp/object/general/coordinacion/coordinacion_pr.interface';

//import { DomDetArrayInterface } from '@comp/object/general/domdetalle/array_datdomicilio.interface';


@Injectable()
export class AcreditadosService {
  public cdgcl: string;
  public coordinacion: any = new Object();
  // Valor del parámetro LEYEDO
  public leyenda: string;
  // Valor del parámetro LEY2EDO
  public ley2edo: string = '_';
  // Valor del parámetro Fecha de elaboración
  public fechaGen:string = 'Fecha de elaboración';
  // Valor del parámetro Seguro de vida
  public comisionIni: string = 'Seguro por apertura';
  // Valor del parámetro Comisión inicial
  public seguroVidaA: string = 'Comisión por apertura';
  // Valor de parámetro FINMAR
  public finmar: string = 'N';
  // Valor de parámetro MMCPPP
  public mmcppp: string = 'N';
  // Valor de parámetro SALDOFEC
  public saldofec: string = 'Saldo calculado a la fecha';
  // Valor de parámetro CLASIF1
  public clasif1: string = 'Empresa';
  // Valor de parámetro CLASIF2
  public clasif2: string = 'Sucursal';
  // Valor de parámetro TOTALPAG
  public totalpag: string;
  // Valor de parámetro PAGOPER
  public pagoper: string;
  // Valor de parámetro COMSEGIN
  public comsegin: string;
  // Valor de parámetro TCOEDOCT
  public tcoedoct: string = 'Comisión';
  public usaMinistraciones: boolean;
  public dom: any = new Object();
  public cvecta1: string;
  public cvecta2: string;
  public cvecta3: string;
  public cvecta4: string;
  public cvecta5: string;
  public cvecta6: string;
  public cvecta7: string;
  public cvecta8: string;
  public cvecta9: string;
  public cvecta10: string;

  constructor(private http: HttpClient) {
    this.dom.mco_celular = '';
  }

  /**
   * Rol de hogar
   */
  public rolDelHogar(codigo: string, descripcion: string): Observable<RhInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/otrosdatos/rh/get', { codigo: codigo, descripcion: descripcion }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
   * Rol de hogar
   */
  public actividadesV(codigo: string, nombre: string): Observable<ActividadesVInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/pld/av/get', { codigo: codigo, nombre: nombre }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public delitosP(codigo: string, nombre: string): Observable<DelitosPeInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/pld/dpe/get', { codigo: codigo, nombre: nombre }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta acreditados Individuales
  public acreditadosI(codigo: string, nombre: string): Observable<ClArrayInterface> {
    // console.log("ENTRE ")
    return this.http.post('api/clientes/buscar/individuales', { id: { cdgcl: codigo }, nombre: nombre }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta acreditados de grupo
  public acreditadosG(codigo: string, nombre: string): Observable<NsArrayInterface> {
    return this.http.post('api/clientes/buscar/grupos', { id: { cdgns: codigo }, nombre: nombre }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Datos de la Coordinación
  public coordinacionGet(cdgco: string): Observable<CoordinacionInterface> {
    return this.http.post('api/ahorros/coordinacion/get', {codigo: cdgco}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Domicilios
  public domicilioGet(cdgcl: string, origen: string, secuencia: string): Observable<DomArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/domicilio/get', {codigo: cdgcl, origen: origen, secuencia: secuencia}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Medios de comunicación
  public mediosDeComunicacionGet(cdgcl: string, tipo: string): Observable<McoArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/medios/get', {codigo: cdgcl, origen: tipo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<VpArrayInterface> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta acreditados
  public acreditadosGet(cdgcl: string, tipo: string, nombre: string, cdgns: string, grupo: string, fechaFutura?: string): Observable<ClArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/get', { cdgcl: cdgcl, tipo: tipo, nombre: nombre, cdgns: cdgns, grupo: grupo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta acreditados para Proyectos de Inversión
  public acreditadosPIGet(cdgcl: string, clns: string, nombre: string, cdgns: string, grupo: string): Observable<ClArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/pi/get', { cdgcl: cdgcl, clns: clns, nombre: nombre, cdgns: cdgns, grupo: grupo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta clientes individuales con paginación (Monitoreo)
  public clientesIndGet(cdgcl: string, nombre: string, siguiente: number, limite: number): Observable<any> {
    this.cdgcl = null;
    const data: any = {};
    if (cdgcl) {
      data.id = {};
      data.id.cdgcl = cdgcl;
    }
    if (nombre) {
      data.nombre = nombre;
    }
    data.siguiente = siguiente;
    data.limite = limite;
    return this.http.post('api/clientes/buscar/individuales', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta clientes Grupales con paginación (Monitoreo)
  public clientesGpoGet(cdgns: string, nombre: string, siguiente: number, limite: number): Observable<ClArrayInterface> {
    const data: any = {};
    if (cdgns) {
      data.id = {};
      data.id.codigo = cdgns;
    }
    if (nombre) {
      data.nombre = nombre;
    }
    data.siguiente = siguiente;
    data.limite = limite;
    return this.http.post('api/clientes/buscar/grupos', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta clientes individuales de grupo con paginación (Monitoreo)
  public clientesIndGpoGet(cdgcl: string, cdgns: string, nombre: string, siguiente: number, limite: number): Observable<ClArrayInterface> {
    const data: any = {};
    if (cdgns) {
      data.id = {};
      data.id.cdgns = cdgns;
    }
    if (nombre) {
      data.nombre = nombre;
    }
    data.siguiente = siguiente;
    data.limite = limite;
    return this.http.post('api/clientes/buscar/individualdegrupo', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Examina el acreditado para determinar si puede ser eliminado o solo dar de baja
  public infoClGet(codigo: string): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/infocl', { codigo: codigo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Examina el acreditado
  public acreditadoDelete(codigo: string, vDelete: boolean, vUpdateRCl: boolean, vDeleteDEP: boolean, vDeleteACR: boolean, vDeleteRCCLORG: boolean, vDeleteRCCLDES: boolean, vDeleteCBC: boolean): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/deleteCL', { codigo: codigo, delete: vDelete, updateRCl: vUpdateRCl, deleteDEP: vDeleteDEP, deleteACR: vDeleteACR, deleteRCCLORG: vDeleteRCCLORG, deleteRCCLDES: vDeleteRCCLDES, deleteCBC: vDeleteCBC }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Devuelve la fecha actual del servidor
  public fechaGet(): Observable<any> {
    return this.http.get('api/control-cartera/operacion/acreditados/fecha').pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta el detalle de un acreditado Individual
  public acreditadoDetalleGet(cdgcl: string): Observable<any> {
    return this.http.post('api/clientes/detalle/individual', { cdgcl: cdgcl }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Consulta acreditados - Estado de cuenta
  public acreditadosPrGet(cdgcl: string, clns: string, nombre: string, cdgns: string, grupo: string, fechaFutura: string): Observable<ClArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/pr/get', { cdgcl: cdgcl, clns: clns, nombre: nombre, cdgns: cdgns, grupo: grupo, fechaFutura: fechaFutura }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //información consultada de los acreditados para usar en la solicitud de crédito
  public acreditadosSCGet(consultaClJson:string, filtro:string): Observable<ClArrayInterface> {
    return this.http.post('api/general/solicitud/acreditado/consultar', {consultaClJson:consultaClJson, inicio:'0', limite:'200', filtro:filtro}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
