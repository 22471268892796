import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent{

    public title: string;
    public mensaje: string = "";

    /**
     * @constructor
     * @param {MatDialogRef<ConfirmDialogComponent>} dialogRef referencia a si mismo
     * @param {any} data contiene la información del titulo y mensaje
     */
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      if (data.hasOwnProperty('title')) {
        this.title = data.title;
      }else{
        throw new Error('Titulo no asignado');
      }
      if (data.hasOwnProperty('mensaje')) {
        this.mensaje = data.mensaje;
      }
    }

}
