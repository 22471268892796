import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistribuyeCriInterface } from '@comp/object/general/distribuyeCri/distribuyeCri.interface';

@Injectable()
export class DistribuyeCriService {

  constructor(private http: HttpClient) {}

  // Llama el procedimiento DISTRIBUYECRI para los resportes de estado de cuenta
  public distribuyeCri(cdgcl: string, cdgns: string, ciclo: string, clns: string): Observable<DistribuyeCriInterface> {
    return this.http.post('api/general/distribuyecri/post', { cdgcl: cdgcl, cdgns: cdgns, ciclo: ciclo, clns: clns }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }


}
