import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayMovComisionGarantia } from "@comp/object/control-cartera/operacion/prestamos/mov-comision-garantia/arraymovcomisiongarantia.interface";


@Injectable()
export class MovComisionGarantiaService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getMovComisionGarantia(consultaMCPJson: string): Observable<ArrayMovComisionGarantia> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/consultar', { consultaMCPJson: consultaMCPJson }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarMovComisionGarantia(insercionMCPJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/insertar', { insercionMCPJson: insercionMCPJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarMovComisionGarantia(edicionMCPJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/editar', { edicionMCPJson: edicionMCPJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarMovComisionGarantia(eliminarMCPJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/eliminar', { eliminarMCPJson: eliminarMCPJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

     public getAsg(jsonAsg): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/aseguradora/getASG ', 
            {  "jsonAsg": jsonAsg, "jsonPgv": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    public getDG(jsonMCP): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/consultar/getGarantias', 
            {  "jsonMCP": jsonMCP })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    public eliminarMovGarantia(jsonMCP): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/eliminar/eliminarDG', 
        { jsonMCP: jsonMCP }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }


    public insertarMcp(pScJson, pPPrJson, pPGeJson): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/insertar/insertarMcp', {  pScJson: pScJson, pPPrJson: pPPrJson, pPGeJson: pPGeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public eliminarMcp(pScJson, pPPrJson, pPGeJson): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/eliminar/eliminarMcp', { pScJson: pScJson, pPPrJson: pPPrJson, pPGeJson: pPGeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public eliminarCig(pScJson, pPPrJson, pPGeJson): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/mov-comision-garantia/eliminar/eliminarCig', {  pScJson: pScJson, pPPrJson: pPPrJson, pPGeJson: pPGeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }


}