import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayBeneficiarios } from "@comp/object/control-cartera/operacion/prestamos/beneficiario/arraysolicitudes.interface";


@Injectable()
export class BeneficiarioService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getBeneficiarios(consultaBSJson: string): Observable<ArrayBeneficiarios> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/beneficiario/consultar', { consultaBSJson: consultaBSJson }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getMpcSv(jsonMCP): Observable<any> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/beneficiario/seguro', 
            { "jsonMCP": jsonMCP }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public insertarBS(jsonBS): Observable<ArrayBeneficiarios> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/beneficiario/consultar/insertar', 
        { jsonBs: jsonBS }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public editarBS(jsonBS): Observable<ArrayBeneficiarios> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/beneficiario/consultar/editar', 
        { jsonBs: jsonBS }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public eliminarBS(jsonBS): Observable<ArrayBeneficiarios> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/beneficiario/consultar/eliminar', 
        { jsonBs: jsonBS }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}