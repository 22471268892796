import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutorizacionPrestamosArrayInterface } from '@comp/object/administracion-tesoreria/operacion/autorizacionprestamos/autorizacionprestamosArray.interface';
import { TasaDeInteresInterfaceArrayInterface } from '@comp/object/administracion-tesoreria/operacion/autorizacionprestamos/tasas/tiArray.interface';
import { LineasDeFondeoTipoArrayInterface } from '@comp/object/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeoTipo/lcArray.interface';
import { LineasDeFondeoPoblacionArrayInterface } from '@comp/object/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeoPoblación/lcArray.interface';


@Injectable()
export class AutorizacionPrestamosService {
  public autorizaPrnPrc: any = new Object();
  public procede: boolean;
  public autoriza: boolean;
  // public especial: string = '';
  public revisa_procesos : boolean;
  public simultaneidad: string;
  public tabTasas: boolean = true;
  public tabCalendario: boolean = true;
  public tabGeneral: boolean = true;
  public tabRecargos: boolean = true;
  public tabEntrega: boolean = true;
  public tabComision: boolean = true;
  public tabActividad: boolean = true;
  public tabOtros: boolean = true;
  public tabBonificaciones: boolean;
  public verBonificaciones: boolean;
  public lgEntrarFormaEntChange: boolean;
  public gHayIntegrantesAgrupados: boolean;
  public lgUsoLC3: string;
  public gStrPermisos: string = 'FESC';
  public tabLineadefondeo: boolean = true;
  public tabCuentabancaria: boolean = true;
  public informacionCredInd: any;
  public tipoF: string = '';
  public dlcArray: any[];
  public tabCuentabancariaGpo: boolean;

  constructor(private http: HttpClient) {

  }

  // Consultar Autorizacion de Prestamos
  public autorizacionPrestamosGet(cdgclns: string, clns: string, nombre: string, cdgacpe: string, nombrec: string, coordinacion: string, cdgco: string, ciclo: string): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/get', { cdgclns: cdgclns, clns: clns, nombre: nombre, cdgacpe: cdgacpe, nombrec: nombrec, coordinacion: coordinacion, cdgco: cdgco, ciclo: ciclo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Actualiza Autorizacion de Prestamos
  public autorizacionPrestamosPost(autorizaPrnPrc: any): Observable<any> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/post', autorizaPrnPrc).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Línea de fondeo - Sección General
  public lineaDeFondeoIndGet(cdgclns: string, clns: string, ciclo: string): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineaF/get', { cdgclns: cdgclns, clns: clns,  ciclo: ciclo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Inicia - Sección Líneas de fondeo
  public lineasDeFondeoGet(cdgclns: string, ciclo: string, clns: string, cantidad: number): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/get', { cdgclns: cdgclns, ciclo: ciclo,  clns: clns, cantidad: cantidad }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public lineasDeFondeoIndPost(cdgorf: string, cdgclns: string, cdglc: string, ciclo: string, clns: string, cantautor: number, cdggfo: string, inicio: string, rendimiento: number, participacion: number, pres: any): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/post', { cdgorf: cdgorf, cdgclns: cdgclns, cdglc: cdglc, ciclo: ciclo, clns: clns, cantautor: cantautor, cdggfo: cdggfo, inicio: inicio, rendimiento: rendimiento, participacion: participacion }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public lineasDeFondeoIndDelete(codigo: string, cdggfo: string, cdgorf: string, cdgclns: string, cdglc: string): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/delete', { codigo: codigo, cdggfo: cdggfo, cdgorf: cdgorf, cdgclns: cdgclns, cdglc: cdglc }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa los préstamos de cliente Institución fondeadora / Línea de fondeo.
  public lineasDeFondeoProrfGet(cdgclns: string, ciclo: string, clns: string): Observable<AutorizacionPrestamosArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/prorf/get', { cdgclns: cdgclns, ciclo: ciclo,  clns: clns }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  // Termina - Sección Líneas de fondeo

  // Línea de crédito - Búsqueda por Tipo
  public lineaDeCreditoTipoGet(cdgclns: string, clns: string, ciclo: string, tipo: string): Observable<LineasDeFondeoTipoArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/getTipo', { cdgclns: cdgclns, clns: clns,  ciclo: ciclo, tipo: tipo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Línea de fondeo - Búsqueda de población
  public lineaDeCreditoPoblacionGet(lPoblacionCL: string, lgUsoLC3: string, lcArray: any[]): Observable<LineasDeFondeoPoblacionArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/lineadefondeo/getPoblacion', { lPoblacionCL: lPoblacionCL, lgUsoLC3: lgUsoLC3, lcArray: lcArray }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Inicia - Sección Tasas
  public tasasDeInteresGet(codigo: string): Observable<TasaDeInteresInterfaceArrayInterface> {
    return this.http.post('api/administracion-tesoreria/operacion/autorizacionprestamos/tasas/get', { codigo: codigo }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  // Termina - Sección Tasas

  public convertirFecha(fecha: any) {
    let fechaSplit = fecha.split("/");
    let fechaConv = new Date();
    fechaConv.setDate(fechaSplit[0]);
    fechaConv.setMonth(parseInt(fechaSplit[1]) - 1);
    fechaConv.setFullYear(fechaSplit[2]);

    return fechaConv;
  }

}
