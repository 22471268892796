import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AEInterface } from '@comp/object/general/actividadeconomica/ae.interface';

@Injectable()
export class UbicacionDelNegocioService {

  constructor(private http: HttpClient) {}

  // Obtiene el catálogo de Ubicación del Negocio
  public ubnGet(codigo: string, descripcion: string): Observable<AEInterface> {
    return this.http.post('api/general/ubn/get', {codigo: codigo, descripcion: descripcion}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
