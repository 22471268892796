import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EsquemaColumnaService {

    constructor(private http: HttpClient) {}

 /**
  * Consulta de esquemas
  */
 public getBusquedaEsquemas(codigo: string, nombre: string, tipo: string, modulo: string): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/esquemas/esquemasGet', 
        { "version": "0",'codigo': "%", "nombre": "%", "tipo": tipo, "modulo": modulo})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

 /**
  * Consulta de columnas
  */
  public getBusquedaColumnas(cdged: string): Observable<any[]> {
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/columnasGet', 
        { 'cdged': cdged, "nombre": "%", "orden":"%"})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  * Insertar esquemas
  */
 public insertarEsquema(tipo: string, nombre: string, descripcion: string, registro: string, modificado: string,
                          publico: string, modulo: string): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/esquemas/insertarEd', 
        { 'tipo': tipo, "nombre": nombre, "descripcion": descripcion, "registro": registro, 
        "modificado": modificado, "publico": publico,"modulo": modulo})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

/**
  * Modificar esquemas
  */
 public modificarEsquema(cdged: string, tipo: string, nombre: string, descripcion: string, modificado:string, publico:string): Observable<any[]> {
  return this.http.post('api/control-cartera/catalogos/esquemas/modificarEd', 
      { 'codigo': cdged, "tipo": tipo, "nombre": nombre, "descripcion": descripcion, "modificado": modificado, "publico": publico})
  .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
    })
  );
}

/**
  * Eliminar esquemas
  */
  public eliminarEsquema(cdged: string): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/esquemas/eliminarEd', 
        { 'cdged': cdged})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  * Insertar actividad del modulo
  */
 public insertaAct(entrada: string, cdgom: string, cdgmod: string): Observable<any[]> {
      return this.http.post('api/control-cartera/catalogos/esquemas/registraAct', 
      { 'entrada': entrada, "cdgom": cdgom, "cdgmod": cdgmod})
    .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
    })
    );
  }

  /**
  *  Consultar catálogos
  */
 public consultarCd(fecha: string, tipo: string): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/esquemas/consultarCd', 
      { "version": "0", "fecha": fecha, "tipo": tipo})
    .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
      })
    );
  }

    /**
  *  Insertar columnas a un esquema en especifico
  */
 public insertarEdCd(cdged: string, cdgcd: string, orden: string, registro: string, ordenar: number): Observable<any[]> {
  return this.http.post('api/control-cartera/catalogos/esquemas/insertarEdCd', 
    { "cdged": cdged, "cdgcd": cdgcd, "orden": orden, "registro": registro, "ordenar": ordenar})
    .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  *  Modificar columnas a un esquema en especifico
  */
 public modificarEdCd(orden: string, modificada: string, ordenar: number, id: string): Observable<any[]> {
  return this.http.post('api/control-cartera/catalogos/esquemas/modificarEdCd', 
    { 'orden': orden, "modificada": modificada, "ordenar": ordenar, "id": id})
    .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
      })
    );
  }

    /**
  *  Eliminar columnas a un esquema en especifico
  */
 public eliminarEdCd(id: string): Observable<any[]> {
  return this.http.post('api/control-cartera/catalogos/esquemas/eliminarEdCd', 
    { "id": id})
    .pipe(
    map(res => {
      return JSON.parse(JSON.stringify(res));
      })
    );
  }


}
