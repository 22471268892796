import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirma-cancel-dialog',
    templateUrl: 'confirmaCancelDialog.component.html',
})
export class ConfirmaCancelDialogComponent1 {

    constructor(
        public dialogRef: MatDialogRef<ConfirmaCancelDialogComponent1>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        
        }

    confirma(): void {
        this.data.confirmaCan = true;
        this.dialogRef.close(this.data);
    }

}
export interface DialogData {
    confirmaCan: boolean;
  }
