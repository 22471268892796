import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from './material.module';
import { AlertDialogComponent } from './dialog/alert/alert-dialog.component';
import { LogoComponent } from './logo/logo.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { FiltroComponent } from './filtro/filtro.component';
import { ConfirmDialogComponent } from './dialog/confirm/confirm-dialog.component';
import { EficaciaMitigantesDialogComponent } from './object/matriz-riesgo/dialogs/eficaciamitigantes/eficaciamitigantes.component';
import { ProbabilidadDialogComponent } from './object/matriz-riesgo/dialogs/probabilidad/probabilidad.component';
import { NoDecontratosDialogComponent } from './object/matriz-riesgo/dialogs/nodecontratos/nodecontratos.component';
import { ImpactoDialogComponent } from './object/matriz-riesgo/dialogs/impacto/impacto.component';
import { RiesgoProductoDialogComponent } from './object/matriz-riesgo/dialogs/riesgoproducto-dialog/riesgoproducto-dialog.component';
import { ConfirmaCancelDialogComponent1 } from './object/matriz-riesgo/dialogs/confirmaCancel/confirmaCancelDialog.component';

import { ViewReporteComponent } from "./reporte/view/view.component";
import { MomentModule } from 'ngx-moment';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { BusquedaDialogOverview } from './dialog/busquedaDialog.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ViewClReporteComponent } from './reporte/viewcl/view.component';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MomentModule,
        SatDatepickerModule,
        SatNativeDateModule,
        NgxLoadingModule
    ],
    declarations: [
        FooterComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        LogoComponent,
        SpeedDialFabComponent,
        FiltroComponent,
        EficaciaMitigantesDialogComponent,
        ProbabilidadDialogComponent,
        NoDecontratosDialogComponent,
        ImpactoDialogComponent,
        RiesgoProductoDialogComponent,
        ConfirmaCancelDialogComponent1,
        ViewReporteComponent,
        ViewClReporteComponent,
        BusquedaDialogOverview
    ],
    exports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FooterComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        LogoComponent,
        SpeedDialFabComponent,
        FiltroComponent,
        ViewReporteComponent,
        ViewClReporteComponent,
        BusquedaDialogOverview,
        MomentModule,
        SatDatepickerModule,
        SatNativeDateModule,
        NgxLoadingModule
    ],
    entryComponents:[
        AlertDialogComponent,
        ConfirmDialogComponent,
        EficaciaMitigantesDialogComponent,
        ViewReporteComponent,
        ViewClReporteComponent,
        BusquedaDialogOverview
    ]
  })
export class ComponentsModule {}
