import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsInGrpArrayInterface } from '@comp/object/inversionistas/operaciones/importacion-integrantes-grupo/insingrp_array.interface';

@Injectable()
export class ImportacionInGrpService {

    constructor(private http: HttpClient) {}

    /**
     * Función que inserta los individuos de grupos en la tabla APF
     **/
   
     public setLllenarImpDIF(cdgcl: string, cdgextcl: string, cdgpif: string, cdgextpif: string, cdgextdif: string, aportacion: number,
                tasainv: number, falta: string, identificador: number): Observable<InsInGrpArrayInterface> {
        return this.http.post('api/inversionistas/operaciones/importacionInGrp/setllenaImpDIF', {
            "cdgcl": cdgcl, "cdgextcl": cdgextcl, "cdgpif": cdgpif, "cdgextpif": cdgextpif, "cdgextdif": cdgextdif, "aportacion":aportacion, 
            "tasainv": tasainv, "falta": falta, "identificador":identificador}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /** 
     * Función donde se realizara la importación.
    **/

    public setImpDIF(identificador: number): Observable<any>{
       return this.http.post('api/inversionistas/operaciones/importacionInGrp/setImpDIF',{
        "identificador": identificador}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    /** 
     * Función para llamar el código de la empresa.
    **/

   public setGetCdgem(): Observable<any>{
    return this.http.post('api/inversionistas/operaciones/importacionInGrp/get',{}
     ).pipe(
         map(res => JSON.parse(JSON.stringify(res)))
     );
 }
}