import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FondeadoresArrayInterface } from '@comp/object/inversionistas/estadodecuenta/fondeadores_array.service';
import { LineasCreditoArrayInterface } from '@comp/object/inversionistas/estadodecuenta/lineasCredito_array.interface';

@Injectable()
export class FondeadoresService {

    constructor(private http: HttpClient) {}

    // Fondeadores
    public fondeadoresGet(nombre: string, tipo: string, codigo: string): Observable<FondeadoresArrayInterface> {
        return this.http.post('api/inversionistas/reportes/estadodecuentas/teFondeadores/get', { nombre: nombre, tipo: tipo, codigo: codigo }).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }


    // Líneas de crédito
    public lineasDeCreditoGet(cdgorf: string, cdglc: string): Observable<LineasCreditoArrayInterface> {
        return this.http.post('api/inversionistas/reportes/estadodecuentas/teLineasCredInv/get', { cdgorf: cdgorf, cdglc: cdglc }).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

}