import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChartsModule } from 'ng2-charts';
import { AdminGuard } from '@serv/auth/admin-guard.service';

const routes: Routes = [
  { path: '', loadChildren: './index/index.module#IndexModule'},
  { path: '', loadChildren: './main/main.module#MainModule', canActivate: [AdminGuard]},
  { path: '', loadChildren: './user/user.module#UserModule', canActivate: [AdminGuard]},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes , { enableTracing: false }),
      ChartsModule
   ],
   exports:[
    RouterModule
   ]
})
export class AppRoutingModule { }
