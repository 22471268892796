import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaiArrayInterface } from '@comp/object/general/ubicacion/paiArray.interface';
import { MuArrayInterface } from '@comp/object/general/ubicacion/muArray.interface copy';
import { LoArrayInterface } from '@comp/object/general/ubicacion/loArray.interface copy';
import { ColArrayInterface } from '@comp/object/general/ubicacion/colArray.interface copy';
import { CPArrayInterface } from '@comp/object/general/ubicacion/cpArray.interface copy';
import { EFArrayInterface } from '@comp/object/general/ubicacion/efArray.interface copy';

@Injectable()
export class UbicacionService {

  constructor(private http: HttpClient) {}

  // Obtiene el catálogo de Paises
  public paiGet(codigo: string): Observable<PaiArrayInterface> {
    return this.http.post('api/general/ubicacion/pai/get', {codigo: codigo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene el catálogo de Entidades Federativas
  public efGet(codigo: string, cdgpai: string): Observable<EFArrayInterface> {
    return this.http.post('api/general/ubicacion/ef/get', {codigo: codigo, cdgpai: cdgpai}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene el catálogo de Municipios
  public muGet(codigo: string, cdgpai: string, cdgef: string): Observable<MuArrayInterface> {
    return this.http.post('api/general/ubicacion/mu/get', {codigo: codigo, cdgpai: cdgpai, cdgef: cdgef}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene el catálogo de Localidades
  public loGet(codigo: string, cdgpai: string, cdgef: string, cdgmu: string): Observable<LoArrayInterface> {
    return this.http.post('api/general/ubicacion/lo/get', {codigo: codigo, cdgpai: cdgpai, cdgef: cdgef, cdgmu: cdgmu}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene el catálogo de Colonias
  public colGet(codigo: string, cdgpai: string, cdgef: string, cdgmu: string, cdglo: string): Observable<ColArrayInterface> {
    return this.http.post('api/general/ubicacion/col/get', {codigo: codigo, cdgpai: cdgpai, cdgef: cdgef, cdgmu: cdgmu, cdglo: cdglo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Obtiene el dimicilio por Código postal
  public cpGet(cdgpostal: string): Observable<CPArrayInterface> {
    return this.http.post('api/general/ubicacion/cp/get', {cdgpostal: cdgpostal}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
