import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EstadoCuentasService {

  private parametros: ParametrosEC = {};
  private result;

  constructor(private http: HttpClient) {}

  /**
  * Consulta de esquemas
  */
  public getEstadoCuenta(para: ParametrosEC): Observable<any[]> {
    this.parametros = para;
    return this.http.post('api/ahorros/reportes/estadodecuenta/get', para)
    .pipe(
      map(res => {
        this.result = JSON.parse(JSON.stringify(res))
        return this.result;
      })
    );
  }

  public limpira(){
    this.parametros = {};
  }

  public getResultado(){
    return this.result;
  }

  public getParametros(){
    return this.parametros;
  }

}

export interface ParametrosEC{
    parametros?: ParametrosItem;
    coordinacion?: any;
    empresa?: any;
}

interface ParametrosItem{
    tipo: string;
    cdgah: string;
    ahorrador: string;
    cdgch: string;
    clave: string;
    fDesde: number;
    fHasta: number;
    producto: string;

}