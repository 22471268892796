import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CPAService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de caracteristicas de peso de análisis de crédito
     */
    public getCPA(jsonCPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/cpa/get', 
            { "jsonCPA": jsonCPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Insertar 
     */
    public insertarCPA(jsonCPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/cpa/set', 
            { "jsonCPA": jsonCPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Editar 
     */
    public editarCPA(jsonCPA): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/analisis-credito/cpa/editar', 
        {  "jsonCPA": jsonCPA})
    .pipe(
        map(res => {
        return JSON.parse(JSON.stringify(res));
        })
    );
    }

    /**
         * Eliminar 
     */
    public eliminarCPA(jsonCPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/cpa/eliminar', 
        {  "jsonCPA": jsonCPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Consulta de indicadores
     */
    public getIAC(jsonIAC): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/cpa/getIac', 
            { "jsonIAC": jsonIAC})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

}
