import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayCalificaciones } from "@comp/object/control-cartera/operacion/prestamos/calificacion/arraycalificaciones.interface";


@Injectable()
export class CalificacionService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getCalificaciones(cdgns: string,ciclo:string,cdgcl:string): Observable<ArrayCalificaciones> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/calificacion/consultar', 
        { 
            version:'0',
            cdgns:cdgns,
            ciclo:ciclo,
            clns:'I',
            cdgcl:cdgcl

        }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
   
}