import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ParametrosDelSistemaService {
    public parametros: any = new Object();
    fechaacr: string;
    estacc: string;
    public peArray = new Array();
    public taArray = new Array();

    constructor(private http: HttpClient) {}

    public importar(json: string): Observable<any[]> {
        return this.http.post("api/administracion-tesoreria/operacion/importacion-afiliaciones/set", 
        {  "jsonPpr": {},
            "jsonCbc": json }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}