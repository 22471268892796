import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayMetodosCobroInteres } from "@comp/object/control-cartera/operacion/prestamos/metodos-cobro-interes/arraymetodoscobrointeres.interface";


@Injectable()
export class MetodoCobroInteresService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getMetodosCobroInteres( ): Observable<ArrayMetodosCobroInteres> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/metodo-cobro-interes/consultar', {}
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}