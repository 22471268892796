import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { MedioComunicacion } from "@app/inversionistas/models/mediocomunicacion.model";
import { MovimientoInversionInterface } from "@comp/object/inversionistas/operaciones/movimientos-inversion/movimientoInversion.interface";
import { ArrayFinancieras } from "@comp/object/inversionistas/operaciones/financieras/arrayfinancieras.interface";
import { ArrayDomicilio } from "@comp/object/inversionistas/operaciones/domicilio/arraydomicilios.interface";
import { ArrayMediosComunicacion } from "@comp/object/inversionistas/operaciones/medioscomunicacion/arraymadioscomunicacion.interface";


@Injectable()
export class FinancierasService {

    movimientosInversion: Observable<MovimientoInversionInterface>;
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getFinancieras(codigo: string, nombre: string): Observable<ArrayFinancieras> {
        return this.http.post('api/inversionistas/operaciones/financieras/consultar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    registrarFinanciera(financiera: any): Observable<string> {
        console.log("Datos Financiera", financiera);
        console.log("altaaaaaa", financiera.alta);
        return this.procesado = this.http.post('api/inversionistas/operaciones/financieras/insertar', {
            nombre: financiera.nombre,
            rfc: financiera.rfc,
            alta: financiera.alta,
            nomfiscal: financiera.nomfiscal,
            pertenece: financiera.pertenece
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarFinanciera(financiera: any): Observable<string> {
        console.log("Datos Financiera", financiera);
        return this.procesado = this.http.post('api/inversionistas/operaciones/financieras/editar', {
            id: { cdgfin: financiera.codigo },
            nombre: financiera.nombre,
            rfc: financiera.rfc,
            alta: financiera.alta,
            nomfiscal: financiera.nomfiscal,
            pertenece: financiera.pertenece
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarFinanciera(financiera: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/financieras/eliminar', {
            id: { cdgfin: financiera.codigo, }
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    registrarDireccion(direccion: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/post', direccion
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarDireccion(direccion: any, secuencia: string): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/post', { direccion, secuencia }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    buscarDireccion(codigoFinanciera: string): Observable<ArrayDomicilio> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/get', { codigoFinanciera }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public registrarMedioDeComunicacion(medioComunicacion: any): Observable<string> {
        return this.procesado = this.http.post<string>('api/inversionistas/operaciones/mediosdecomunicacion/post', medioComunicacion).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
   

    public guardarMediosDeComunicacionRegistro(medioComunicacion: Array<MedioComunicacion>) {

        let data: any;
        let arrayOfData = [];
        this.getSomethingFromAnAPI(medioComunicacion).subscribe(response => {
            data.push({
                body: response.body,
                title: response.title,
                id: response.id
            });
        }, error => {
            console.error(error);
        });

    }

    public getSomethingFromAnAPI(medioComunicacion: MedioComunicacion[]): any {
        return from(medioComunicacion).pipe(
            concatMap(medioDeComunicacion => <Observable<any>>this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/post', medioComunicacion)
            ));
    }


    buscarMediosComunicacion(codigoFinanciera: string): Observable<ArrayMediosComunicacion> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/get', { codigoFinanciera }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarMedioComunicacion(medioComunicacion: any, secuencia: string): Observable<any> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/post',
            {
                medioComunicacion, secuencia
            }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    eliminarMedioComunicacion(medioComunicacion: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/delete', { medioComunicacion }


        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }


}