import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ArrayMonedas } from "@comp/object/inversionistas/operaciones/creditos-fondear/arraymonedas.interface";
import { ArrayCreditos } from "@comp/object/inversionistas/operaciones/creditos-fondear/arraycreditos.interface";
import { CreditoFondear } from "@app/inversionistas/models/credito-fondear/creditofondear.model";
import { ArrayCreditosPrc } from "@comp/object/inversionistas/operaciones/credito-prc/arraycreditosprc.interface";

@Injectable()
export class CreditosFondearService {
    procesado: Observable<string>;
    constructor(private http: HttpClient) { }

    getMonedas(): Observable<ArrayMonedas> {
        return this.http.post('api/inversionistas/operaciones/financieras/moneda/get', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    consultarCreditos(financiera: string): Observable<ArrayCreditos> {
        return this.http.post('api/inversionistas/operaciones/creditos-fondear/consultar', { id: { cdgfin: financiera } }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    consultarCreditosAFondear(): Observable<ArrayCreditos> {
        return this.http.post('api/inversionistas/operaciones/creditos-fondear/consultar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    registrarCredito(creditoFondear: CreditoFondear): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/creditos-fondear/insertar', creditoFondear
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarCredito(creditoFondear: CreditoFondear): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/creditos-fondear/editar', creditoFondear
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    eliminarCredito(creditoFondear: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/creditos-fondear/eliminar', {
            id: {
                cdgfin: creditoFondear.cdgfin,
                nocred: creditoFondear.nocred
            }
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    consultarCreditoPrc(nombreFinanciera:string): Observable<ArrayCreditosPrc> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/creditos-fondear/consultarPRC', {cdgem:nombreFinanciera.toUpperCase()}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    filtrarCreditosAfondear(financiera: string, nocred: string, cdgcl: string, ciclo: string, finiciodesde: string, finiciohasta: string, fsolicituddesde: string, fsolicitudhasta: string, montominimo: number, montomaximo: number, situacion: string): Observable<ArrayCreditos> {
        let montomin = null;
        let montomax = null;
        if (montominimo != null && montomaximo != null) {
            montomin = parseFloat(montominimo.toString());
            montomax = parseFloat(montomaximo.toString());
        }

        return this.http.post('api/inversionistas/operaciones/creditos-fondear/consultar', {
            id: {
                cdgfin: financiera,
                nocred:nocred,    
                cdgcl: cdgcl,
                ciclo: ciclo,
                finiciodesde: finiciodesde,
                finiciohasta: finiciohasta,
                fsolicituddesde: fsolicituddesde,
                fsolicitudhasta: fsolicitudhasta,
                montominimo: montomin,
                montomaximo: montomax,
                situacion: situacion,
                nomapecliente: null
            }
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }
    public permitirSoloNumeros(event: any) {
        if ((event.which >= 48 && event.which <= 57)) {
            return true;
        } else {
            return false;
        }
    }
    
}