import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { McoArrayInterface } from '@comp/object/general/mediosdecomunicacion/mcoArray.interface';
import { MediosDeComunicacionInterface } from '@comp/object/general/mediosdecomunicacion/mediosdecomunicacion.interface';

@Injectable()
export class MediosDeComunicacionService {

  constructor(private http: HttpClient) {}

  // Regresa los medios de comunicación del acreditado
  public mediosDeComunicacionGet(origen: string, codigo: string, secuendom: string, clns: string, cdgns: string, cdgpi: string, tipo: string, cdgcl: string, cdgdom: string): Observable<McoArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/medios/get', {origen: origen, codigo: codigo, secuendom: secuendom, clns: clns, cdgns: cdgns, cdgpi: cdgpi, tipo: tipo, cdgcl: cdgcl, cdgdom: cdgdom}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Crea o actualiza un medio de comunicación
  public medioDeComunicacionPost(medioDeComunicacion: MediosDeComunicacionInterface): Observable<MediosDeComunicacionInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/medios/post', {medios: medioDeComunicacion}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina un medio de comunicación
  public medioDeComunicacionDelete(medios): Observable<{}> {
    return this.http.post('api/control-cartera/operacion/acreditados/medios/delete', {medios: medios}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
