import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PeriodosVencidosRangosService {

    constructor(private http: HttpClient) {}

    // Muestra registros Periodos Vencidos Rangos
    public rdrGet(): Observable<any> {
        return this.http.get('api/gestion-cobranza/administracion/parametrosdelsistema/periodosvencidosrangos/get').pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    // Inserta / Actualiza tabla Periodos Vencidos Rangos
    public pvrPost(tabla: any[]): Observable<any> {
        return this.http.post('api/gestion-cobranza/administracion/parametrosdelsistema/periodosvencidosrangos/post/tabla', {tabla: tabla}).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

}