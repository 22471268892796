import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { MedioComunicacion } from "@app/inversionistas/models/mediocomunicacion.model";
import { ArrayDomicilio } from "@comp/object/inversionistas/operaciones/domicilio/arraydomicilios.interface";
import { ArrayMediosComunicacion } from "@comp/object/inversionistas/operaciones/medioscomunicacion/arraymadioscomunicacion.interface";
import { ArrayUsuarios } from "@comp/object/general/seguridad/usuarios/arrayusuarios.interface";
import { ArrayTiposUsuario } from "@comp/object/general/seguridad/tiposusuario/arraytiposusuario.interface";


@Injectable()
export class OperAcumuladasService {

    public parametros;

    constructor(private http: HttpClient) { }

    getReporteOpereraciones(consultaOpAcMpMivJson: string): Observable<any> {
      console.log('getReporteOpereraciones');
        return this.http.post('api/pld/reportes/secciones-monitoreo/operaciones-acumuladas/consultar', { consultaOpAcMpMivJson: consultaOpAcMpMivJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    

   


}