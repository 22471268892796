import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormComponent } from "../core/component/dynamic-form.component";
import { DynamicMaterialFormControlContainerComponent } from "./dynamic-material-form-control-container.component";
import { DynamicFormModel } from "../core/model/dynamic-form.model";
import { DynamicFormLayout } from "../core/service/dynamic-form-layout.service";
import { DynamicFormControlEvent } from "../core/component/dynamic-form-control-event";
import { DynamicTemplateDirective } from "../core/directive/dynamic-template.directive";
import { DynamicFormComponentService } from "../core/service/dynamic-form-component.service";

@Component({
    selector: "dynamic-material-form",
    templateUrl: "./dynamic-material-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMaterialFormComponent extends DynamicFormComponent {

    @Input() group: FormGroup;
    @Input() model: DynamicFormModel;
    @Input() layout: DynamicFormLayout;

    @Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();

    @Output() matEvent: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();

    @ContentChildren(DynamicTemplateDirective) templates: QueryList<DynamicTemplateDirective>;

    @ViewChildren(DynamicMaterialFormControlContainerComponent) components: QueryList<DynamicMaterialFormControlContainerComponent>;

    constructor(protected changeDetectorRef: ChangeDetectorRef,
                protected componentService: DynamicFormComponentService) {
        super(changeDetectorRef, componentService);
    }
}
