import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { McoArrayInterface } from '@comp/object/general/mediosdecomunicacion/mcoArray.interface';
import { MediosDeComunicacionInterface } from '@comp/object/general/mediosdecomunicacion/mediosdecomunicacion.interface';
import { ArrayUsuarios } from '@comp/object/general/seguridad/usuarios/arrayusuarios.interface';

@Injectable()
export class EmpleosService {

  constructor(private http: HttpClient) {}

  //Consultar empleo
  public empleoGet(jsonPPGE,jsonPPOT): Observable<any> {
    return this.http.post('api/general/emp/get', {jsonPPGE: jsonPPGE, jsonPPOT: jsonPPOT}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Consultar empleo
  public empleoFilGet(jsonPPGE,jsonPPOT): Observable<any> {
    return this.http.post('api/general/emp/getFil', {jsonPPGE: jsonPPGE, jsonPPOT: jsonPPOT}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Consultar codigo de empleo
  public empleoCdg(jsonEMP): Observable<any> {
    return this.http.post('api/general/emp/cdgemp', {jsonEMP: jsonEMP}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
    //Insertar empleo
    public empleoSet(jsonEMP,jsonPPGv): Observable<any> {
      return this.http.post('api/general/emp/set', {jsonEMP: jsonEMP, jsonPPGv: jsonPPGv}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }

      //Consultar empleo
  public empleoEditar(jsonEMP,jsonPPGv): Observable<any> {
    return this.http.post('api/general/emp/editar', {jsonEMP: jsonEMP, jsonPPGv: jsonPPGv}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

    //Consultar empleo
    public empleoEliminar(jsonEMP,jsonPPGv): Observable<any> {
      return this.http.post('api/general/emp/eliminar', {jsonEMP: jsonEMP, jsonPPGv: jsonPPGv}).pipe(
        map(res => {
          return JSON.parse(JSON.stringify(res));
        })
      );
    }


  //Domicilios

  public domGet(origen: string, codigo: string, clns: string,  cdgcl: string, cdgemp: string, tipo: string, cdgpi: string): Observable<McoArrayInterface> {
    return this.http.post('api/general/emp/dom/get', {origen: origen, codigo: codigo, clns: clns, cdgcl: cdgcl, cdgemp: cdgemp, secuencia: null, tipo: tipo, cdgpi: cdgpi}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Crea o actualiza un domicilio
  public domicilioPost(codigo, nuevo, secuencia, calle, cdgcol, cdgef, cdglo, cdgmu, cdgpai, clns, comentario, entrecalles, noext, noint, nombre, tipo, verificado, verificape, freside, cdgemp, fregistro, cdgpostal): Observable<{}> {
    return this.http.post('api/general/emp/dom/post', {codigo: codigo, origen: 'E', nuevo: nuevo, secuencia: secuencia, calle: calle, cdgcol: cdgcol, 
                    cdgef: cdgef, cdglo: cdglo, cdgmu: cdgmu, cdgpai: cdgpai, clns: clns, comentario: comentario, entrecalles: entrecalles, noext: noext, 
                    noint: noint, nombre: nombre, tipo: tipo, verificado: verificado, verificape: verificape, freside: freside, cdgemp: cdgemp, fregistro: fregistro, cdgpostal: cdgpostal}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina un domicilio
  public domicilioDelete(codigo, secuencia, origen): Observable<{}> {
    return this.http.post('api/general/emp/dom/eliminar', {
      codigo: codigo, secuencia:secuencia,  origen:origen}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Fecha reside
  public getFreside(numanios): Observable<{}> {
    return this.http.post('api/general/emp/dom/freside', {
      numanios: numanios}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  
  //Numanios
  public getNumAnios(fecha): Observable<{}> {
    return this.http.post('api/general/emp/dom/numanios', {
      fecha: fecha}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

   //Domicilios para dom

   public domDGet(origen: string, codigo: string, clns: string,  cdgcl: string,  tipo: string): Observable<McoArrayInterface> {
    return this.http.post('api/general/emp/mco/dommco', {origen: origen, codigo: codigo, clns: clns, cdgcl: cdgcl, secuencia: null, tipo: tipo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Medios de comunicación 

  //Regresa los medios de comunicación del acreditado
  public mediosDeComunicacionGet(origen: string, codigo: string, cdgemp: string): Observable<McoArrayInterface> {
    return this.http.post('api/general/emp/mco/get', {origen: origen, codigo: codigo, cdgemp: cdgemp}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Crea o actualiza un medio de comunicación
  public medioDeComunicacionPost(codigo, origen, tipo, numero, compania:string, ext, prioridad, pertenece, secuendom, origendom, cdgdom, comentario, verificado, verificape, fregistro, cdgemp,secuencia,nuevo): Observable<MediosDeComunicacionInterface> {
    return this.http.post('api/general/emp/mco/post', {codigo: codigo, origen: origen, tipo: tipo, numero: numero, compania: compania, 
                    ext: ext, prioridad: prioridad, pertenece: pertenece, secuendom: secuendom, origendom: origendom, cdgdom: cdgdom, comentario: comentario, verificado: verificado, verificape: verificape,
                    fregistro: fregistro, cdgemp: cdgemp,secuencia: secuencia, nuevo: nuevo  }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina un medio de comunicación
  public medioDeComunicacionDelete(codigo: string, secuencia: string, origen: string, secuendom:string): Observable<{}> {
    return this.http.post('api/general/emp/mco/eliminar', {codigo: codigo, secuencia: secuencia, origen: origen, secuendom: secuendom}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Domicilios para MCO

  public dommcoGet(origen: string, codigo: string, clns: string,  cdgcl: string, cdgemp: string, tipo: string): Observable<McoArrayInterface> {
    return this.http.post('api/general/emp/mco/dommco', {origen: origen, codigo: codigo, clns: clns, cdgcl: cdgcl, cdgemp: cdgemp, secuencia: null, tipo: tipo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //Obtener puestos en la empresa
  public getPem(): Observable<{}>{
    return this.http.post('api/general/pem/get', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  // Obtener compañias
  public getCtc(codigo: string): Observable<{}>{
    return this.http.post('api/general/emp/mco/ctc', {codigo: codigo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  // Obtener domicilios
  public getDomMco(codigo: string): Observable<{}>{
    return this.http.post('api/general/emp/dom/get', {codigo: codigo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  // Obtener personal
  getUsuarios(): Observable<ArrayUsuarios> {    
    return this.http.post('api/general/seguridad/usuarios/consultar', { consultaPeJson: "'S_ACTIVO':'S'"}
    ).pipe(
        map(res => JSON.parse(JSON.stringify(res)))
    );
}

  // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<any[]> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
