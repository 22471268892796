import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material';
import { OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-riesgoproducto-dialog',
  templateUrl: './riesgoproducto-dialog.component.html',
    styleUrls: ['./riesgoproducto-dialog.component.css']
  })

export class RiesgoProductoDialogComponent implements OnInit {
  formControl = new FormControl('', [Validators.required]);
  valores: boolean;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<RiesgoProductoDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {

  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Este campo es requerido.' : '';
  }

  applyFilter(ev: any) {
    if (ev.keyCode < 49 || ev.keyCode > 51) {
      ev.preventDefault();
    }
    // if (ev.keyCode > 51 && ev.keyCode < 58) {
    //   this.valores = true;
    // } else {
    //   this.valores = false;
    // }
  }

  clear() {
    this.data.riesgo = '';
  }

  aceptar() {
    this.dialogRef.close(this.data);
  }

  cancelar() {
    this.dialogRef.close({});
  }

}
