import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RegistroService implements CanActivate {
  params: any;
  codigo: string;

  constructor(private router: Router, private routeAct: ActivatedRoute) {

  }

// canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Para realizar alguna acción antes de entrar a la ventana de Registro
    return true;
  }

}
