import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConciliacionRapidaService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de pagos 
     */
    public getPagos(jsonPpr, jsonPpge, jsonPot ): Observable<any[]> {
        return this.http.post("api/control-cartera/operacion/movimientos/conciliacionrapida/getPagos", 
        {   "jsonPpr": jsonPpr,
            "jsonPpge": jsonPpge,
            "jsonPot": jsonPot
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     *  Metodo para realizar la conciliación
     */
    public conciliacion(jsonMp, jsonPpr, jsonPge): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/movimientos/conciliacionrapida/setConcilia', 
        {  "jsonMp": jsonMp,
           "jsonPpr": jsonPpr,
           "jsonPge":jsonPge          
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

     /**
     *  Metodo para obtener la infomarción de EM
     */

     public getEmpresa(): Observable<any[]> {
        return this.http.post("api/general/em/get", 
            {}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<any[]> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
}