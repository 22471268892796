import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CirculoCreditoService {

  constructor(private http: HttpClient) {}

  /**
   * Regresa los Pwd de rccficoscore
   */
  public getPwd(): Observable<ResPwd> {
    return this.http.post('api/circuloazul/rccficoscore/obtienePwd', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public consulta(data: ReqConsulta): Observable<any> {
    return this.http.post('api/circuloazul/rccficoscore/consulta', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  public registraConsulta(data: any): Observable<any> {
    return this.http.post('api/circuloazul/historicocc/set', data).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  // Consulta reporte Versión 2
  public consulta_v2(cdgcl: string): Observable<any> {
    return this.http.post('api/circuloazul/reportecirculocredito/consulta_v2', {cdgcl: cdgcl}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}

interface ResPwd{
  circredpwdArray: Array<Password>;
}

interface Password{
  cdgem: string;
  parametro: string;
  valor: string;
}

interface ReqConsulta{
  datos: Datos;
  usuario: string;
  password: string; 
}

interface Datos{
  primerNombre: string;
  segundoNombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  fechaNacimiento: number;
  RFC: string;
  curp: string;
  nacionalidad: string;
  domicilio: DomicilioRCC;
}

interface DomicilioRCC{
  direccion: string;
  coloniaPoblacion: string;
  delegacionMunicipio: string;
  ciudad: string;
  estado: string;
  CP: string;
}
