import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AcreditadosV2Service {
    public circuloCC: any = new Object();

    constructor(private http: HttpClient) {
        this.circuloCC.dom = {};
        this.circuloCC.domDetalle = {};
        this.circuloCC.arrayParametros = [];
    }

    /**
     * Regresa toda la información del cliente
     */
    public getCliente(codigo: string): Observable<Cliente> {
        return this.http.post('api/clientes/detalle/individual', {cdgcl: codigo}).pipe(
            map(res => {
                return JSON.parse(JSON.stringify(res));
            })
        );
    }

    /**
     * Funcion que verifica si ya esta autenticado o no
     */
    public getInformacionCl(codigo: string):  Observable<any> {
        return this.http.post('api/control-cartera/operacion/acreditados/infocl', 
        {codigo: codigo}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que elimina un acreditado
     */
    public eliminarCl(data: EliminarCl):  Observable<any> {
        return this.http.post('api/control-cartera/operacion/acreditados/deleteCL', 
        data).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que regresa la fecha actual
     */
    public fecha():  Observable<any> {
        return this.http.get('api/control-cartera/operacion/acreditados/fecha').pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que actualiza el status del cliente
     */
    public status(data: Status):  Observable<any> {
        return this.http.post('api/control-cartera/operacion/acreditados/estatus', 
        data).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
}

interface EliminarCl{
    codigo: string;
    delete: boolean;
    updateRCl: boolean;
    deleteDEP: boolean;
    deleteACR: boolean;
    deleteRCCLORG: boolean;
    deleteRCCLDES: boolean;
    deleteCBC: boolean;
}

interface Status{
    cdgcl: string;
    motivobaja: string;
    estatuspld: string;
}

interface Cliente{
    cl: InfoCl;
    domArray: Array<Dom>;
    mcoArray: Array<Mco>;
}

interface InfoCl{
    actaconsti: string;
    actualiza: number;
    actualizacpe: string;
    actualizape: string;
    actualizarpe: string;
    alta: number;
    baja: string;
    calle: string;
    cdgav: string;
    cdgclcge: string;
    cdgco: string;
    cdgcol: string;
    cdgdo: string;
    cdgdof: string;
    cdgdpe: string;
    cdgef: string;
    cdgfo: string;
    cdgib: string;
    cdglo: string;
    cdgmu: string;
    cdgocpe: string;
    cdgpai: string;
    cdgpcl: string;
    cdgpg: string;
    cdgpi: string;
    cdgprpe: string;
    cdgrh: string;
    clabe: string;
    clnspi: string;
    condicionm: string;
    curp: string;
    cvncl1: string;
    cvncl2: string;
    cvncl3: string;
    cvncl4: string;
    cvncl5: string;
    discapacidad: string;
    disjudi: string;
    edocivil: string;
    empresa: string;
    entrecalles: string;
    estatuspld: string;
    expuestopld: string;
    factivacion: string;
    fielpld: string;
    id: Id;
    ife: string;
    ifeexp: string;
    inscritorfc: string;
    internet: string;
    ipoliticapld: number;
    issste: string;
    lindigena: string;
    modifica: number;
    motivobaja: string;
    motivorestriccion: string;
    nacimiento: number;
    nacioef: string;
    naciolo: string;
    naciomu: string;
    nacionalidad: string;
    nacionalidadCC: string;
    naciopai: string;
    nfamilia: string;
    nivescolar: string;
    noctabanco: string;
    nodepend: string;
    nofolio: string;
    nombre1: string;
    nombre2: string;
    nombrectah: string;
    nomnota: string;
    notarjeta: string;
    nss: string;
    numescripub: string;
    numnota: string;
    numrpp: string;
    ocupacionpld: string;
    pmfecha: string;
    politicapld: string;
    primape: string;
    recepdoc: string;
    redsocial: string;
    registro: number;
    regmarital: string;
    religion: string;
    restriccion: string;
    rfc: string;
    riesgopld: string;
    segape: string;
    sexo: string;
    telefono: string;
    tpoliticapld: string;
}

interface Id{
    cdgem: string;
    codigo: string;
    origen: String;
    secuencia: string;
    secuendom: string;
}

interface Dom{
    ambulante: string;
    calle: string;
    cdgac: string;
    cdgcl: string;
    cdgcol: string;
    cdgef: string;
    cdgfin: string;
    cdginv: string;
    cdglo: string;
    cdgmu: string;
    cdgns: string;
    cdgpai: string;
    cdgpe: string;
    cdgpi: string;
    cdgpif: string;
    clns: string;
    comentario: string;
    entrecalles: string;
    freside: number;
    id: Id;
    inicio: string;
    noext: string;
    noint: string;
    nombre: string;
    propiedad: string;
    tipo: string;
    verificado: number;
    verificape: string;
    vigencia: string;
}

interface Mco{
    cdgac: string;
    cdgcl: string;
    cdgdom: string;
    cdgfin: string;
    cdginv: string;
    cdgns: string;
    cdgpe: string;
    cdgpi: string;
    clns: string;
    comentario: string;
    compania: string;
    id: Id;
    numero: string;
    origendom: string;
    pertenece: string;
    prioridad: string;
    tipo: string;
    verificado: string;
    verificape: string;
    vigencia: string;
}