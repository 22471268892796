import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BcrInterface } from '@comp/object/general/bonificaciones/bcr.interface';


@Injectable()
export class BonificacionesService {

  constructor(private http: HttpClient) {}

  // Bonificaciones por credito
  public bcrGet(cdgclns: string, ciclo: string, clns: string): Observable<BcrInterface> {
    return this.http.post('api/general/bcr/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
