import { Injectable } from '@angular/core';
import { AmortizacionBase, TA, ItemTA, EncData } from './amortizacion-base';
import { DynamicFormModel } from '@comp/dynamic-form/core/model/dynamic-form.model';
import { DynamicDivGroupModel } from '@comp/dynamic-form/core/model/div/dynamic-div-group.model';
import { DynamicRadioGroupModel } from '@comp/dynamic-form/core/model/radio/dynamic-radio-group.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import Utils from '@comp/util/utils';
import { MatTableDataSource } from '@angular/material';

@Injectable()
export class AmortizacionConSolicitudV2Service extends AmortizacionBase {

    constructor(private http: HttpClient) {
        super();
    }

    private tasa = new DynamicRadioGroupModel<string>({

        id: 'utilizar_tasa',
        label: 'Utilizar tasa',
        value: 'reg',
        options: [
            {
                label: 'Regular',
                value: 'reg'
            },
            {
                label: 'Con descuento',
                value: 'des'
            }
        ]
    });

    public getElementos(): DynamicFormModel {
        const base = this.getElementosBase();
        const root = base[0] as DynamicDivGroupModel;
        const tasa = root.get(7) as DynamicDivGroupModel;
        tasa.insert(0, this.tasa);
        return base;
    }

    public getResult(data: any) {
        this.request = data;
        return this.http.post('api/control-cartera/reportes/amortizacionconsolicitud/buscar', data).pipe(map((val: TACS) => {
            this.result = val;
            return val;
        }));
    }

    public getEncData(): Array<Array<EncData>> {
        const result = this.result as TACS;
        const data: Array<Array<EncData>> = [];
        const data1: Array<EncData> = [];
        data1.push({
            titulo: 'Acreditado',
            valor: result.codigo + ' ' + result.nombre,
        });
        data1.push({
            titulo: 'Ciclo',
            valor: result.ciclo
        });
        data1.push({
            titulo: result.tinucon,
            valor: result.contrato,
        });
        data1.push({
            titulo: result.tatiinic,
            valor: result.fechainicio,
        });
        data1.push({
            titulo: result.tatifin,
            valor: result.ffin,
        })
        data1.push({
            titulo: 'Crédito',
            valor: Utils.formatNumber(result.montopr),
        });
        data1.push({
            titulo: 'Método de cálculo de interés',
            valor: result.cdgmci + ' ' + result.nombremci,
        });
        data1.push({
            titulo: 'Moneda',
            valor: result.nommoneda,
        });
        data1.push({
            titulo: result.tatipcr, 
            valor: result.nombre_pcr,
        });
        data.push(data1);
        const data2: Array<EncData> = [];
        data2.push({
            titulo: 'Plazo:',
            valor: `${result.plazo}`,
        });
        // if (TQAmortiza1PLAZODIA.Value>=7) or ((TQAmortiza1FINIPAG.AsString <> '') 
            //and (TQAmortiza1PLAZOFINIPAG.Value < 7) and (TQAmortiza1PLAZOFINIPAG.Value > 0)) then 
        var periodicidad, valorPer;
       // console.log("Valores", result)
        if ((result.plazodia >=7) || ((result.finipag !== '') && (result.plazofinipag < 7) && (result.plazofinipag > 0))){
            result.periodicidad = 'A';
            periodicidad = 'Al vencimiento';
            data2.push({
                titulo: 'Número de días:',
                valor: result.plazodia.toString(),
            })
        }else{
            periodicidad = this.getPeriodicidad()
        }
        data2.push({
            titulo: result.tatiper,
            valor: periodicidad
        });
        if(this.extraInfo.tapergrc == 'S'){
            data2.push({
                titulo: 'Periodos de gracia al capital:',
                valor: result.periograciacap + ' ' + this.getPeriodicidadT(result.periograciacap)
            });
        } 
        if(this.extraInfo.tapergri == 'S'){
            data2.push({
                titulo: 'Periodo de gracia al interés:',
                valor: result.periograciaint + ' ' + this.getPeriodicidadT(result.periograciaint)
            });
        }         
        
        data.push(data2);
        const data3: Array<EncData> = [];
        if (this.extraInfo.imprimir) {
            let valTasa = '';
            if (this.extraInfo.t_int === 'c_iva') {
                if (this.extraInfo['tasa-tiempo'] === 'S') {
                    valTasa = ((result.tasaanual / 12) / 4).toFixed(2) + '% semanal';
                } else if (this.extraInfo['tasa-tiempo'] === 'M') {
                    valTasa = (result.tasaanual / 12).toFixed(2) + '% mensual';
                } else {
                    valTasa = result.tasaanual.toFixed(2) + '% anual';
                }
            } else {
                if (this.extraInfo['tasa-tiempo'] === 'S') {
                    valTasa = ((result.tasaanualsiniva / 12) / 4).toFixed(2) + '% semanal';
                } else if (this.extraInfo['tasa-tiempo'] === 'M') {
                    valTasa = (result.tasaanualsiniva / 12).toFixed(2) + '% mensual';
                } else {
                    valTasa = result.tasaanualsiniva.toFixed(2) + '% anual';
                }
            }
            data3.push({
                titulo: 'Tasa:',
                valor: valTasa,
            });
            var tasad;
            //console.log(result.tasadesc)
            if(result.tasadesc !== null){
                tasad = result.tasadesc !== null ? result.tasadesc : '' + '% mensual';
            }else{
                tasad = '';
            }
            if(this.extraInfo.utilizar_tasa){
                data3.push({
                    titulo: 'Tasa con descuento:',
                    valor:tasad,
                });
            }
            data3.push({
                titulo: 'CAT sin IVA:',
                valor: result.cat_bm !== 'null' ? result.cat_bm + '%' : '0 %',
            });
            data3.push({
                titulo: result.lysegini,
                valor: result.cominisolosv + '',
            });
            data3.push({
                titulo: result.lycomini,
                valor: Utils.formatNumber(result.comini),
            });
        }
        data.push(data3);
        return data;
    }

    private getTipo() {
        switch (this.request.clnss) {
            case 'I':
                return 'Individual';
            case 'IG':
                return 'Individual de Grupo';
            case 'G':
                return 'Grupal';
        }
    }

    public getDataSource(arrayName: string) {
        const result = this.result;
        const data = [];
        let comisionTotal = 0;
        let total = 0;
        let intTotal = 0;
        let seguro = 0;
        let primaseg = 0;
        const temporal: ItemTA[] = [];
        let ivaTotal = 0;
        let cantTotal = 0;

        result[arrayName].forEach(val => {
            const clone = { ...val };

            comisionTotal += +val.cantidadtotal;
            total += +val.parcialidadcom;//val.parcialidadcap + +val.parcialidadint + +val.parcialidadcom;
            intTotal += +val.parcialidadint;
            seguro += +val.parcialidadseg;
            ivaTotal += +val.ivainteres;
            cantTotal += val.parcialidadcap + +val.ivainteres;

            clone.parcialidadcap = Utils.formatNumber(val.parcialidadcap);
            clone.parcialidadint = Utils.formatNumber(val.parcialidadint);
            clone.parcialidadcom = Utils.formatNumber(val.parcialidadcom);
            clone.parcialidadseg = Utils.formatNumber(val.parcialidadseg);
            clone.saldocap = Utils.formatNumber(val.saldocap);
            if (this.extraInfo.redondea) {
                // val.cantidadtotal = Utils.formatNumber(val.cantidadtotal);
            }
            temporal.push(clone);
        });
        //result.creditoArray[result.creditoArray.length-1].total
        
        const comisionTotal1 = Utils.formatNumber(comisionTotal);
        const total1 = Utils.formatNumber(total);
        const intTotal1 = Utils.formatNumber(intTotal);
        const seguro1 = Utils.formatNumber(seguro);
        const parcialiad = Utils.formatNumber(result.montopr);
        let ahorro = Utils.formatNumber(result.ahorro);

        temporal.forEach((val, index) => {
            let tem = {
                periodo: val.periodo,
                vencimiento: val.vencimiento,
                parcialidadcap: val.parcialidadcap,
                parcialidadint: this.clarren ? val.ivainteres : val.parcialidadint,
                // parcialidadcom: val.parcialidadcom,
                parcialidadcom: val.parcialidadcom == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : val.parcialidadcom,
                saldocap: val.saldocap,
                // cantidadtotal: +result[arrayName][index].parcialidadcap +
                //                +result[arrayName][index].parcialidadint +
                //                +result[arrayName][index].parcialidadcom
                cantidadtotal: +result[arrayName][index].cantidadtotal
            } as { [key: string]: any };
            // if (val.hasOwnProperty('parcialidadseg')) {
            //     tem = { ...tem,
            //         parcialidadseg: val['parcialidadseg']};
            // }
            if (this.comseg == 'S') {
                primaseg += Number(val.parcialidadseg);
                tem = { ...tem,
                    parcialidadseg: val.parcialidadseg};
            }
            if (this.result.tasegsd == 'S') {
                tem = { ...tem,
                    primaseg: this.clarren ? Utils.formatNumber(val.cantidadtotal + val.ivainteres) : Utils.formatNumber(primaseg)};
            }
            if (this.result.tasegsd != 'S') {
                if (this.result.cdgph) {
                    tem = { ...tem, ahorro: ahorro};
                }
            }

            if (this.extraInfo.iva) {
                if (this.extraInfo.redondea) {
                    tem.cantidadtotal = Utils.formatNumber(Math.ceil(tem.cantidadtotal));
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: Utils.formatNumber(val.ivainteres),
                        // parcialidadcom: Utils.formatNumber(val.ivacomision),
                        parcialidadcom: val.ivacomision == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : Utils.formatNumber(val.ivacomision),
                        parcialidadseg: Utils.formatNumber(val.ivaseguro),
                        primaseg: '',
                        // saldocap: Utils.formatNumber(val.ivainteres),
                        saldocap: '',
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);

                } else {
                    tem.cantidadtotal = Utils.formatNumber(tem.cantidadtotal);
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: Utils.formatNumber(val.ivainteres),
                        // parcialidadcom: Utils.formatNumber(val.ivacomision),
                        parcialidadcom: val.ivacomision == 0 ? (isNaN(Number(this.tatexcom)) ? this.tatexcom : Utils.formatNumber(Number(this.tatexcom))) : Utils.formatNumber(val.ivacomision),
                        parcialidadseg: Utils.formatNumber(val.ivaseguro),
                        primaseg: '',
                        // saldocap: Utils.formatNumber(val.ivainteres),
                        saldocap: '',
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);
                }
            } else {
                if (this.extraInfo.redondea) {
                    tem.cantidadtotal = Utils.formatNumber(Math.ceil(tem.cantidadtotal));
                    data.push(tem);
                } else {
                    tem.cantidadtotal = Utils.formatNumber(tem.cantidadtotal);
                    data.push(tem);
                }
            }
        });

        data.push({
            periodo: '.', vencimiento: '', parcialidadcap: this.clarren ? total1 : parcialiad, parcialidadint: this.clarren ? Utils.formatNumber(ivaTotal) : intTotal1,
            parcialidadcom: total1, parcialidadseg: seguro1, primaseg: this.clarren ? Utils.formatNumber(cantTotal) : '', saldocap: '', 
            cantidadtotal: comisionTotal1
        });

        return new MatTableDataSource<any>(data);
    }

}

interface TACS extends TA {
    apagarint: number;
    cat_bm: number|string;
    cat_tir: number;
    cdgns: string;
    cominisinsv: string;
    cominisolosv: number;
    comisiontotal: number;
    contrato: string;
    etiquetafecha: string;
    nombre_pcr: string;
    nombreem: string;
    nombremci: string;
    pagoAmortizacionArray: Array<ItemTACS>;
    parcialidad: number;
    tasadesc: string;
    tatiinic: string;
    tit_ah: string;
    tinucon: string;
    ffin: string;
    nommoneda: string;
    plazodia: number;
    finipag: string;
    plazofinipag: number;


    
}

interface ItemTACS extends ItemTA {
    ahorro: number;
    cantidadtotal: number;
    parcialidadseg: number;
    total: number;
    totalgrp: number;
    totalintord: number;
}
