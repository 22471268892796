import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SituacionDeCreditoRangosService {

    constructor(private http: HttpClient) {

    }

    // Muestra registros Situación de crédito
    public sauGet(): Observable<any> {
        return this.http.get('api/gestion-cobranza/administracion/parametrosdelsistema/situaciondecredito/get').pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    // Inserta registros Situación de crédito
    public sauPost(situacion: string, cdgta: string, cdgpe: string): Observable<any> {
        return this.http.post('api/gestion-cobranza/administracion/parametrosdelsistema/situaciondecredito/post', {situacion: situacion, cdgta: cdgta, cdgpe: cdgpe}).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    // Actualiza registros Situación de crédito
    public sauPut(situacion: string, cdgta: string, cdgpe: string): Observable<any> {
        return this.http.post('api/gestion-cobranza/administracion/parametrosdelsistema/situaciondecredito/put', {situacion: situacion, cdgta: cdgta, cdgpe: cdgpe}).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    // Elimina registros Situación de crédito
    public sauDelete(situacion: string): Observable<any> {
        return this.http.post('api/gestion-cobranza/administracion/parametrosdelsistema/situaciondecredito/delete', {situacion: situacion}).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

}