import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { autoTable as AutoTable, Styles } from 'jspdf-autotable';
import { EncData } from '@serv/control-cartera/cc-reportes/amortizacion/amortizacion-base';
moment.locale('es');

export class ReportePdf {
  private hora = moment().format('h:mm:ss a') + '   ';
  private fecha = moment().format('DD/MM/YYYY') + ' ';

  constructor(private logo: string,
              private nombreEM: string,
              private titulo: string,
              private encabeza: string,
              private reca: string,
              private etiquetafecha: string,
              private consultView: boolean,
              private encdata: Array<Array<EncData>>,
              private tituloColums: Array<string>,
              private dataSource: Array<any>,
              private columnStyle: any,
              private headerTitle: string,
              private nameFecha: string = 'Fecha de generación',
              private nombre: string = '',
              private comini: string = '',
              private infoCoordenadas: Coordenada = new Template(),
              private divisor: Array<number> = [140, 275]) {
  }

  numero(numero: any): number {
    const n: number = (numero === null || numero === undefined) ? 0 : numero;
    return n;
  }

  generarPDF(): void {
    const _c = this.infoCoordenadas;
    const nombreDoc = 'reporte'.concat('_', this.fecha.trim(), '.pdf');
    const pdf = new jsPDF('p', 'pt', 'letter');

    // Fila 1 - Columna 1
    const img = this.logo;
    pdf.addImage(img, 'JPEG', _c.logo.a1, _c.logo.a2, _c.logo.a3, _c.logo.a4);
    pdf.setFontSize(7);
    pdf.setFontStyle('bold');
    pdf.text(_c.empresa.x, _c.empresa.y, this.nombreEM);
    pdf.setFontStyle('normal');
    //console.log(pdf.getTextWidth('Text'));


    // Fila 1 - Columna 2
    pdf.setFontSize(18);
    pdf.text(_c.titulo.x, _c.titulo.y, this.titulo);

    // Fila 1 - Columna 3
    pdf.setFontSize(7);
    //pdf.text(_c.encabecado.x, _c.encabecado.y, this.encabeza);

    pdf.setFontSize(7);
    //pdf.text(pdf.internal.pageSize.getWidth() - 30 - pdf.getTextWidth(this.reca).toFixed(0), _c.reca.y, this.reca);
    if (!this.consultView){
      this.etiquetafecha = this.etiquetafecha ? this.etiquetafecha.concat(' ',this.fecha,this.hora) : '';
    }else{
      // console.log("Ya tiene completa etiquetafecha");
    }
    pdf.autoTable({
      head: [[this.reca]],
      body: [[this.encabeza],
             [this.etiquetafecha]
            ],
      headStyles: {halign: 'right'},
      columnStyles: {0:{halign: 'right'}},
      theme: 'plain',
      margin: {top: 5, right: 30, left: 30},
      styles: {fontSize: 7},
      didDrawCell: body => {
      }
  });

  
  var finalY = pdf.lastAutoTable.finalY

    let margen = 0;
    this.encdata.forEach((part, index) => {
      const dis = _c.infoEncabezado[index];
      let renglon = dis.primero;
      part.forEach(item => {
        pdf.setFontStyle('bold');
        pdf.text(dis.x1, renglon, item.titulo);
        pdf.setFontStyle('normal');
        pdf.text(dis.x2, renglon, item.valor + '', null, null, 'right');
        renglon = renglon + 12;
      });
      if (margen < renglon) {
        margen = renglon;
      }
    });

    pdf.setDrawColor(174, 174, 174);
    this.divisor.forEach(val => {
      pdf.rect(45, val, 530, 1); // 140
    });
    pdf.setDrawColor(0, 0, 0);

    ((pdf as any).autoTable as AutoTable)({
      head: [],
      body: [],
      theme: 'striped',
      margin: { top: margen + 10, right: 40, left: 40 },
      styles: { fontSize: 10 }
    });

    if (this.dataSource.length !== 0) {

      ((pdf as any).autoTable as AutoTable)({
        head: [this.tituloColums],
        body: this.dataSource,
        headStyles: { halign: 'center' },
        columnStyles: this.columnStyle,
        foot: [],
        theme: 'striped',
        margin: { right: 30, left: 30 },
        startY: pdf.autoTable.previous.finalY +210,
        styles: { fontSize: 6 },
        footStyles: { fillColor: [245, 245, 245], textColor: [0, 0, 0], fontSize: 7 },
        willDrawCell: datosArray => {
          var rows = datosArray.table.body;
        if (datosArray.row.index === rows.length - 1) {
            pdf.setFillColor(234, 242, 248);
            pdf.setFontStyle('bold');
        }

        }
      });
    } else {
      ((pdf as any).autoTable as AutoTable)({
        head: [],
        body: [[]],
        theme: 'striped',
        margin: { right: 30, left: 30 },
        startY: pdf.autoTable.previous.finalY +190,
        styles: { fontSize: 10 },
        didDrawCell: body => {
        }
      });
    }

    pdf.text(45, pdf.autoTable.previous.finalY + 20, this.comini);

    if (this.nombre !== '') {
      pdf.rect(200, pdf.autoTable.previous.finalY + 60, 220, 0);
      pdf.text(250, pdf.autoTable.previous.finalY + 80, this.nombre);
    }

    // Pie de pagina

    const pageCount = pdf.internal.getNumberOfPages();
    for (let j = 1; j <= pageCount; j++) {
      pdf.setPage(j);
      pdf.setFontSize(7);
      pdf.setFontType('normal');
      pdf.text(25, 760, this.nameFecha + ': ' + this.fecha + this.hora);
      pdf.text(25, 775, this.headerTitle);
      pdf.text(580, 775, 'Pág. ' + j);
    }

    pdf.save(nombreDoc);
  }
}

class Template implements Coordenada {
  logo: Puntos = {
    a1: 45,
    a2: 50,
    a3: 100,
    a4: 50
  };
  empresa: Origin = {
    x: 45,
    y: 120
  };
  titulo: Origin = {
    x: 200,
    y: 80
  };
  encabecado: Origin = {
    x: 500,
    y: 40
  };
  reca: Origin = {
    x: 300,
    y: 25
  };

  infoEncabezado: Apartado[] = [
    {
      primero: 150,
      x1: 45,
      x2: 290
    },
    {
      primero: 165,
      x1: 305,
      x2: 445
    },
    {
      primero: 165,
      x1: 465,
      x2: 575
    }
  ];
}

export interface Coordenada {
  logo: Puntos;
  empresa: Origin;
  titulo: Origin;
  encabecado: Origin;
  reca: Origin;
  infoEncabezado: Array<Apartado>;
}

export interface Puntos {
  a1: number;
  a2: number;
  a3: number;
  a4: number;
}

export interface Origin {
  x: number;
  y: number;
}

export interface Renglon {
  x1: number;
  x2: number;
}

export interface Apartado {
  primero: number;
  x1: number;
  x2: number;
}

export interface EstiloColumnas {
  [key: string]: Styles;
}
