import Base from '@comp/object/base/Base';
import { Toolbar } from '@serv/toolbar.service';
import { Component } from '@angular/core';
import { RouterService } from '@serv/router.service';

/**
 * @class Todo el contenido del pie de pagina
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends Base {
  today: number = Date.now();

  constructor(private route: RouterService, toolbar: Toolbar) {
    super(toolbar);
  }

  public abrirEnlaces(url1: string, url2: string): void{ 
    if(url2 != ""){
      window.open(url1); 
      window.open(url2);
    }else{
      window.open(url1);
    }
             
  }
}
