import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-alert-dialog',
    templateUrl: 'alert-dialog.component.html',
})
export class AlertDialogComponent {

    public title = 'Advertencia';

    /**
     * @constructor
     * @param {MatDialogRef<AlertDialogComponent>} dialogRef referencia a si mismo
     * @param {any} data contiene la información del titulo y mensaje
     */
    constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      if (data.hasOwnProperty('title')) {
        this.title = data.title;
      }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
