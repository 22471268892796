import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { Domicilio } from "@app/modulo-general/models";
import { ArrayProyectosInversion } from "@comp/object/inversionistas/operaciones/proyectos-inversion/arrayproyectosinversion.interface";


@Injectable()
export class ProyectosInversionService {
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getProyectosInversion(): Observable<ArrayProyectosInversion> {
        return this.http.post('api/inversionistas/operaciones/proyectos-inversion/buscar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    getProyectosInversionCod(cdgpif:string): Observable<ArrayProyectosInversion> {
        return this.http.post('api/inversionistas/operaciones/proyectos-inversion/buscar', {id:{codigo:cdgpif}}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    guardarProyectoDeInversion(proyectoDeInversion: any) {
        return this.procesado = this.http.post('api/inversionistas/operaciones/proyectos-inversion/crear', proyectoDeInversion
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarProyectoDeInversion(proyectoDeInversion: any) {
        return this.procesado = this.http.post('api/inversionistas/operaciones/proyectos-inversion/editar', proyectoDeInversion
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    registrarDomicilio(domicilio:Domicilio){
        return this.procesado = this.http.post('api/inversionistas/operaciones/proyectos-inversion/domicilio/post', domicilio
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}
