import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatRadioGroup } from "@angular/material/radio";
import { DynamicFormControlComponent } from "@comp/dynamic-form/core/component/dynamic-form-control.component";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicRadioGroupModel } from "@comp/dynamic-form/core/model/radio/dynamic-radio-group.model";
import { DynamicFormControlCustomEvent } from "@comp/dynamic-form/core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";

@Component({
    selector: "dynamic-material-radio-group",
    templateUrl: "./dynamic-material-radio-group.component.html"
})
export class DynamicMaterialRadioGroupComponent extends DynamicFormControlComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicRadioGroupModel<string>;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matRadioGroup") matRadioGroup: MatRadioGroup;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService) {

        super(layoutService, validationService);
    }
}
