import { EncData } from './../../../services/control-cartera/cc-reportes/amortizacion/amortizacion-base';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { DatosU } from '@comp/object/items/Users/UserPe';
import { MatTableDataSource } from '@angular/material/table';
import { ReportePdf, EstiloColumnas } from '../pdf/reporte-pdf.service';
import { AuthService } from '@serv/auth/auth.service';
moment.locale('es');

@Component({
  selector: 'app-reporte-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})

export class ViewReporteComponent  implements OnInit {
  public logo = '';
  private logo64 = '';
  public nombreEM = '';
  @Input() titulo = '';
  @Input() encabeza = '';
  @Input() reca = '';
  @Input() etiquetafecha = '';
  @Input() nombre = '';
  @Input() descargar = true;
  @Input() encdata: Array<Array<EncData>> = [];
  @Input() set comicion(val: number) {
    if (val !== undefined && val !== null && val !== 0) {
      this.comini = 'Adicional a los pagos mencionados en la tabla ' +
                    'anterior, se debe considerar el pago de las comisiones iniciales por: ' +
                    this.numero(val).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
  }
  public comini = '';
  public hora = moment().format('h:mm:ss a') + '   ';
  public fecha = moment().format('DD/MM/YYYY') + ' ';
  @Input() tituloFecha = 'Fecha de generación';
  @Input() headerTitle = '';
  @Input() displayedColumns: Array<string> = [];
  @Input() tituloColumns: Array<string> = [];
  @Input() dataSource = new MatTableDataSource<any>();
  @Input() totales: Array<string> = [];
  @Input() styleColumns: EstiloColumnas;
  @Input() reca_pcr = '';
  @Input() tafegeen = '';
  @Input() clarren: string;
  tituloColumns_A = ['No.', 'Fecha', 'Renta', 'IVA', 'Pago total'];
  @Input() dataSource_A = new MatTableDataSource<any>();
  displayedColumns_A = ['periodo', 'vencimiento', 'parcialidadcap', 'parcialidadint', 'cantidadtotal'];

  constructor(private auth: AuthService) {
    this.logoGet();
    this.nombreEM = (this.auth.getAdmi() as DatosU).empresa;   
   // this._toolbar.showToolbarRegreso();
  }
  ngOnInit(): void {
//    console.log(this.displayedColumns)
  //  console.log(this.clarren)
    this.etiquetafecha = this.etiquetafecha.concat(' ',this.fecha,this.hora);
    if (this.clarren == 'S') {
      this.dataSource.data.forEach(element => {
        if (element.periodo) {
          this.dataSource_A.data.push({periodo: element.periodo, vencimiento: element.vencimiento, parcialidadcap: element.parcialidadcap, parcialidadint: element.parcialidadint, cantidadtotal: element.primaseg})
        }
      });
    }
  }

  logoGet() {
    this.auth.getLogo().toPromise().then((result: any) => {
      this.logo = result.logo;
      this.logo64 = result.logo64;
    }, (error) => {
      console.log('Hubo un error con la peticion: ' + error);
    });
  }

  numero(numero: any): number {
    return (numero === null || numero === undefined) ? 0 : numero;
  }

  imprimirReporte() {
    if(this.clarren == undefined){
      this.clarren = 'N';
    }
    const data = [];
    const data_A = [];
    if (this.clarren == 'N') {
      this.dataSource.data.forEach(ren => {
        const rent = [];
        for (const item of this.displayedColumns) {
          rent.push(ren[item]);
        }
        data.push(rent);
      });
    } else {
      this.dataSource_A.data.forEach(ren => {
        const rent = [];
        for (const item of this.displayedColumns_A) {
          rent.push(ren[item]);
        }
        data_A.push(rent);
      });
      data_A.push(this.totales);
    }
    data.push(this.totales);
    const reportePdf = new ReportePdf(this.logo64,
                                      this.nombreEM,
                                      this.clarren == 'S' ? 'CALENDARIZACIÓN DE PAGOS' : this.titulo,
                                      this.clarren == 'S' ? '' : this.encabeza,
                                      this.clarren == 'S' ? '' : this.reca_pcr,
                                      this.tafegeen == 'S' ? this.clarren == 'S' ? '' : this.etiquetafecha : null,
                                      true,
                                      this.encdata,
                                      this.clarren == 'S' ? this.tituloColumns_A : this.tituloColumns,
                                      this.clarren == 'S' ? data_A : data,
                                      this.styleColumns,
                                      this.headerTitle,
                                      this.tituloFecha,
                                      this.nombre,
                                      this.comini);
    reportePdf.generarPDF();

  }

}
