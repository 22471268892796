import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SymService {

  constructor(private http: HttpClient) {}

  // Inserta solicitud de crédito del cliente
  public symGet(servicio: string): Observable<any> {
    return this.http.post('api/general/sym/get', {servicio: servicio}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
