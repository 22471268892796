import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuentaBancariaInterface } from '@comp/object/general/cuentabancaria/cuentabancaria.interface';
import { InstitucionBancariaInterface } from '@comp/object/general/institucionbancaria/institucionBancaria.interface';

@Injectable()
export class CuentaBancariaService {

  constructor(private http: HttpClient) {}

  public cuentaBancariaGet(cdgcl: string): Observable<CuentaBancariaInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/get', { cdgcl: cdgcl }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public cuentaBancariaPost(cuenta: any): Observable<CuentaBancariaInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/post', { cuenta }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public cuentaBancariaDelete(cuenta: any): Observable<CuentaBancariaInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/delete', { codigo: cuenta.id.codigo, cdgcl: cuenta.id.cdgcl }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public institucionBancariaGet(codigo: string, nombre: string): Observable<InstitucionBancariaInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/ib/get', { codigo: codigo, nombre: nombre }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public revisaCuentaBancaria(noctabanco: string): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/noctabanco/get', { noctabanco: noctabanco }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public revisaClabe(clabe: string): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/clabe/get', { clabe: clabe }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  public revisaTarjeta(notarjeta: string): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/cuentabancaria/notarjeta/get', { notarjeta: notarjeta }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    );
  }

  // Cuenta Bancaria por Cliente para traspaso de fondos.
  public traspasoDeFondos(cliente: any): Observable<any> {
    return this.http.post('api/general/cuentasbancarias/get/tf', cliente
        ).pipe(
          map(res => JSON.parse(JSON.stringify(res)))
    );
  }

}
