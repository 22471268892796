import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';


@Injectable()
export class CicloRealService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getNumCicloReal(cdgcl:string,cdgpcr:string,cdgtpc:string): Observable<String> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/cicloreal/consultar', { 
            cdgcl:cdgcl,
            cdgpcr:cdgpcr, 
            cdgtpc:cdgtpc            
        }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
   
}