import { Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocomplete, MatAutocompleteDefaultOptions } from "@angular/material/autocomplete";
import { ErrorStateMatcher, LabelOptions, MAT_LABEL_GLOBAL_OPTIONS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { DynamicMaterialFormInputControlComponent } from "../dynamic-material-form-input-control.component";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicInputModel } from "@comp/dynamic-form/core/model/input/dynamic-input.model";
import { DynamicFormControlCustomEvent } from "@comp/dynamic-form/core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";

@Component({
    selector: "dynamic-material-input",
    templateUrl: "./dynamic-material-input.component.html"
})
export class DynamicMaterialInputComponent extends DynamicMaterialFormInputControlComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicInputModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matAutocomplete") matAutocomplete: MatAutocomplete;
    @ViewChild(MatInput) matInput: MatInput;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(ErrorStateMatcher) public errorStateMatcher: ErrorStateMatcher,
                @Inject(MAT_AUTOCOMPLETE_DEFAULT_OPTIONS) public AUTOCOMPLETE_OPTIONS: MatAutocompleteDefaultOptions,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions,
                @Inject(MAT_RIPPLE_GLOBAL_OPTIONS) @Optional() public RIPPLE_OPTIONS: RippleGlobalOptions) {

        super(layoutService, validationService);
    }

    /**
     * Esta función es para actualizar el controlador de formulario ya que en la mascara se actualiza el valor del input
     * y el valor del control pero existe otro proceso que elimina lo que no pertenece a la mascara del input
     * y en ese momento no se actualiza el contro y agrega basura, para eso es esta función
     * @param eve 
     */
    valueEve(eve){
        this.model.value = eve;
    }

    public onKeyPress(event: any) {
        if(event.target.type == 'number'){
          if ((event.which >= 48 && event.which <= 57) || event.which == 46) {
            return true;
          } else{
            return false;
          }
        }        
      }
}
