import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material';
import { OnInit } from "@angular/core";

@Component({
  selector: 'app-eficacia-mitigantes',
  templateUrl: './eficaciamitigantes.component.html',
    styleUrls: ['./eficaciamitigantes.component.css']
  })

export class EficaciaMitigantesDialogComponent implements OnInit {
  eficaciaMitigantes: any = ['Deficiente', 'Regular', 'Buena'];

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<EficaciaMitigantesDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {

  }

  aceptar(){
    this.dialogRef.close(this.data);
  }

  cancelar() {
    this.dialogRef.close({});
  }

}
