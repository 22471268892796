import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuentaBancariaArrayInterface } from '@comp/object/general/cuentabancaria/cuentabancaria_array.interface';

@Injectable()
export class CuentasBancariasService {

    constructor(private http: HttpClient) { }

    /**
     * Funcion obtener las cuentas bancarias
     */

  public getCB(codigo: string): Observable<CuentaBancariaArrayInterface> {
    return this.http.post('api/general/cuentasbancarias/get', {
          'codigo': codigo
      }
      ).pipe(
          map(res => JSON.parse(JSON.stringify(res)))
      );
  }
}
