import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';



@Injectable()
export class BajaPeService {

  procesado: Observable<string>;

  constructor(private http: HttpClient) { }

  getInfoBajaPe(peBajaJson: string): Observable<any> {
    return this.http.post('api/inversionistas/usuarios/baja/obtiene', { peBajaJson: peBajaJson}

    ).pipe(
        map(res => JSON.parse(JSON.stringify(res)))
    );
}


}
