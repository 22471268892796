import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { DemArrayInterface } from "@comp/object/pld/operacion/matriz-de-riesgo-por-entidad/demArray.interface";
import { EmrArrayInterface } from "@comp/object/pld/operacion/matriz-de-riesgo-por-entidad/emrArray.interface";

@Injectable()
export class MatrizDeRiesgoPorEntidadService {
    public promedioPro_CU = 0;
    public promedioImp_CU = 0;
    public promedioPro_PA = 0;
    public promedioImp_PA = 0;
    public promedioPro_PS = 0;
    public promedioImp_PS = 0;
    public promedioPro_Tr = 0;
    public promedioImp_Tr = 0;
    public promedioPro = 0;
    public promedioImp = 0;
    public promedioNivel: string;
    public cdgemr: string;

    constructor(private http: HttpClient) { }

    matrizDeRiesgoGet(codigo: string, tipo: string): Observable<EmrArrayInterface> {
        return this.http.post('api/pld/operacion/matriz-de-riesgo-por-entidad/emr/get', { codigo: codigo, tipo: tipo }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    detalleMatrizDeRiesgoGet(codigo: string, tipo: string): Observable<DemArrayInterface> {
        return this.http.post('api/pld/operacion/matriz-de-riesgo-por-entidad/dem/get', { codigo: codigo, tipo: tipo }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    matrizDeRiesgoPost(evaluacion: any, matriz: string): Observable<any> {
        return this.http.post('api/pld/operacion/matriz-de-riesgo-por-entidad/post', { evaluacion, matriz }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    matrizDeRiesgoCancel(codigo: string, tipo: string, motivocancela: string): Observable<any> {
        return this.http.post('api/pld/operacion/matriz-de-riesgo-por-entidad/cancel', { codigo: codigo, tipo: tipo, motivocancela: motivocancela }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    detalleMatrizDeRiesgoPost(tipoemr: string, cdgemr: string, matriz: string): Observable<any> {
        return this.http.post('api/pld/operacion/matriz-de-riesgo-por-entidad/dem/post', { tipoemr, cdgemr, matriz }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}
