import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material';
import { OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-nodecontratos',
  templateUrl: './nodecontratos.component.html',
    styleUrls: ['./nodecontratos.component.css']
  })
  
export class NoDecontratosDialogComponent implements OnInit {
  formControl = new FormControl('', [Validators.required]);
  longitud = true;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<NoDecontratosDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log(this.data)
  }

  ngOnInit(): void {
    this.data.contratos = this.data.contratos === 0 ? this.data.contratos = null : this.data.contratos;
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Este campo es requerido.' : '';
  }

  applyFilter(ev: any) {
    if (ev.keyCode  === 101 || ev.keyCode  === 45) {
      ev.preventDefault();
    } else {
      if (!this.longitud) {
        ev.preventDefault();
      }
    }
  }

  clear() {
    this.data.contratos = '';
  }

  aceptar() {
    this.longitud = true;
    this.dialogRef.close(this.data);
  }

  cancelar() {
    this.dialogRef.close({});
  }

  validaNocont(nocont: number): number {
    let sNocont: string;
    let cont = 0;
    let contar = true;
    if (nocont) {
      sNocont = nocont.toString();
      for (let i = 0; i < sNocont.length; i++) {
        if (sNocont[i] !== '.' && contar) {
          cont++;
          contar = true;
        } else {
          contar = false;
        }
      }
    }
    return cont;
  }

  maxLong() {
    let cont = 0;
    if (this.data.contratos) {
      if (this.data.contratos.toString().indexOf('.') !== -1) {
        cont = 1;
      }
      
      if ((this.data.contratos.toString().indexOf('.') === -1)) {
        if (this.data.contratos % 1 === 0) {
          if (this.data.contratos.toString().length < 5) {
            this.longitud = true;
          } else {
            this.longitud = false;
          }
        }
      } else {
        if (this.data.contratos.toString().length < 7) {
          if (cont > 0) {
            this.longitud = false;
          } else {
            this.longitud = true;
          }
        } else {
          this.longitud = false;
        }
      }
    }
  }

}
