'use strict';

import { Toolbar } from '@serv/toolbar.service';
import { environment } from '@env/environment';
import { OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
/**
 * @class Clase base que ayuda a realizar las funciones bases de cualquier pagina web
 */
export default abstract class Base implements OnDestroy {

    /**
     * @property {Toolbar} _toolbar Manejo del comportamiento del toolbar
     */
    public _toolbar: Toolbar;

    /**
     * @property {Observable<Event>} onlineEvent Evento que nos indica cuando esta en linea
     */
    private onlineEvent: Observable<Event>;

    /**
     * @property {Observable<Event>} offlineEvent Evento que nos indica cuando no esta en linea
     */
    private offlineEvent: Observable<Event>;

    /**
     * @property {Subscription[]} subscriptions Colección de suscriptores de eventos internet estatus
     */
    private subscriptions: Subscription[] = [];

    /**
     * Imprimir una log con la información que le digamos
     */
    public log = environment.production?() => {}: console.log.bind(window.console);

    /**
     * Imprimir un error si la exprecion que se evalua es incorrecta
     */
    public assert = environment.production?() => {}: console.assert.bind(window.console);
    
    /**
     * Imprimir el arbol del elemento Dom que le indiquemos
     */
    public dir = environment.production?() => {}: console.dir.bind(window.console);

    /**
     * Imprimir una error
     */
    public error = environment.production?() => {}: console.error.bind(window.console);

    /**
     * Agrupo todos los log que metamos hasta llamar groupEnd asignandoles una etiqueta
     */
    public group = environment.production?() => {}: console.group.bind(window.console);

    /**
     * Ver group
     */
    public groupEnd = environment.production?() => {}: console.groupEnd.bind(window.console);

    /**
     * Imprime un array de objetos en formato de tabla
     */
    public table = environment.production?() => {}: console.table.bind(window.console);

    /**
     * Toma el tiempo que toma una instruccion e imprime el resultado
     * inicia con esta funcion y le indicamos donde dejar de tomar el tiempo con timeEnd
     */
    public time = environment.production?() => {}: console.time.bind(window.console);

    /**
     * Ver time
     */
    public timeEnd = environment.production?() => {}: console.timeEnd.bind(window.console);

    /**
     * Imprimir el arbol completo de llamadas donde se ejecuta el trace
     */
    public trace = environment.production?() => {}: console.trace.bind(window.console);

    /**
     * Imprimir una advertencia
     */
    public warn = environment.production?() => {}: console.warn.bind(window.console);
    
    /**
     * @constructor
     * @param _toolbar
     */
    constructor(_toolbar: Toolbar) {
        this._toolbar = _toolbar;
        // Obtiene los estatus online/offline del navegador desde windows
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
            if (this.onLine) {
                this.onLine(true);
            }
        }));

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            if (this.onLine) {
                this.onLine(false);
            }
        }));
    }

    ngOnDestroy(): void {
        // Desuscripción de eventos para evitar lagos de memoria
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.onDestroy) {
            this.onDestroy();
        }
    }

    //TODO: Remove
    $onInit(){

    }

    public onLine?(event: boolean): void;

    onDestroy?(): void;

    /**
     * Agrega el titulo al toolbar
     * @param title
     * @type string
     */
    public setTitle(title: string): void {
        this._toolbar.setTitle(title);
    }

}
