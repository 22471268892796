import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RevisionProcesosInterface } from '@comp/object/general/revisaProcesos/revisaprocesos.interface';


@Injectable()
export class RevisionProcesosService {

  constructor(private http: HttpClient) {}

  // Regresa el listado clientes traspaso de fondos de un préstamo
  public revisaProcesosGet(cdgclns: string, ciclo: string, clns: string, etapa: string, fecha: string, simultaneidad: string, upago: string, devgar: string, cdgmod: string, cdgcl_cj: string): Observable<RevisionProcesosInterface> {
    return this.http.post('api/general/revisionprocesos/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, etapa: etapa, fecha: fecha, simultaneidad: simultaneidad, upago: upago, devgar: devgar, cdgmod: cdgmod, cdgcl_cj: cdgcl_cj}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  

}
