import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayFormaDistribucion } from "@comp/object/control-cartera/operacion/prestamos/forma-distribucion/formadistribucion.interface";



@Injectable()
export class FormaDistribucionService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getFormasDistribucion(consultaFDIJson: string): Observable<ArrayFormaDistribucion> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/forma-distribucion/consultar', { consultaFDIJson: consultaFDIJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}