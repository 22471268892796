import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PagosDepositoService {

    constructor(private http: HttpClient) { }

  public insertarPreImportacion(jsonMPIMP: string): Observable<boolean> {
      return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/pagosdeposito', {
        'insertarMPIMPJson': jsonMPIMP
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}
