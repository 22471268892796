import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CicloArrayInterface } from '@comp/object/control-cartera/reportes/estadodecuenta/cliclo/cicloarray.interface';
import { EstadoDeCuentaInterface } from '@comp/object/control-cartera/reportes/estadodecuenta/reporte/estadocuenta.interface';
import { EstadoDeCuentaMasivoInterface } from '@comp/object/control-cartera/reportes/estadodecuenta/reporte-masivo/estadocuentaMasivo.interface';

@Injectable()
export class EstadoDeCuentaService {
  public parametros;

  constructor(private http: HttpClient) {}

  /**
   * Regresa los ciclos de un acreditado
   */
  public cicloGet(cdgcl: string): Observable<CicloArrayInterface> {
    return this.http.post('api/reporte/ciclo', {codigo: cdgcl}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }


  /**
   *  Consulta estado de cuenta - Deprecated
   */
  // tslint:disable-next-line:max-line-length
  public estadoDeCuentaGet(cdgclns: string, ciclo: string, clns: string, fecha: string, cdgcl: string): Observable<EstadoDeCuentaInterface> {
    return this.http.post('api/reporte/estadodecuenta', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, fecha: fecha, cdgcl: cdgcl}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //  Consulta estado de cuenta
  public estadoDeCuentaCCGet(cdgclns: string, ciclo: string, cdgcl: string, clns: string, fecha: string, fechafutura: string, comision: string): Observable<EstadoDeCuentaInterface> {
    return this.http.post('api/control-cartera/reportes/estadodecuenta/get', {cdgclns: cdgclns, ciclo: ciclo, cdgcl: cdgcl, clns: clns, fecha: fecha, fechafutura: fechafutura, comision: comision})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //  Consulta masiva de estados de cuenta
  public estadoDeCuentaMasivoCCGet(inputEdoCta: any[]): Observable<EstadoDeCuentaMasivoInterface> {
    return this.http.post('api/control-cartera/reportes/estadodecuenta/mget', {inputEdoCta: inputEdoCta})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  //  Consulta estado de cuenta PDF
  public estadoDeCuentaCCGetPDF(edoDeCuenta: any): Observable<any> {
    return this.http.post('api/control-cartera/reportes/estadodecuenta/getPDF', edoDeCuenta).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
