import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SolicitudPorEsquemaService {

  constructor(private http: HttpClient) {}

 /**
  * Consulta de esquemas
  */
  public getBusquedaEsquemas(codigo: string, nombre: string): Observable<any[]> {
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/esquemasGet', 
        { "version": "0",'codigo': codigo, "nombre": "%"})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

 /**
  * Consulta de columnas
  */
  public getBusquedaColumnas(cdged: string): Observable<any[]> {
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/columnasGet', 
        { 'cdged': cdged, "nombre": "%", "orden":"%"})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
   * Consulta de acreditados.
   */
  public getBusquedaAcreditados(cdgcl: string, cdgns:string,nombre: string, clns:string ): Observable<any[]>{
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/acreditadosGet',
        {"version": "0","cdgcl": cdgcl, "cdgns": cdgns, "nombre":nombre, "tipo": clns })
    .pipe(
      map(res =>{
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  /**
   * Consulta del reporte.
   */
  public getDatosReporte(cdgcl: string, cdgns:string, clns:string, ciclo:string, solicitud: string, sol:string, aut: string, rev: string, dev:string,sitVis,
                          solicitudD: string, solicitudH: string, inicioD: string, inicioH:string,rbDomGar: string): Observable<any[]>{
    return this.http.post('api/control-cartera/reportes/solicitudporesquema/solicitudGet',
        {"version": "0","clns": clns, "cdgcl": cdgcl, "cdgns": cdgns, "ciclo":ciclo, "solicitud": solicitud,"sol": sol, "aut": aut,
        "rev": rev, "dev": dev,  "sitVis":sitVis, "fechaSolD": solicitudD,"fechaSolH": solicitudH,"fechaD": inicioD, "fechaH": inicioH,"rbDomGar": rbDomGar })
    .pipe(
      map(res =>{
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

}
