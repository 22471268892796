import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomicilioInterface } from '@comp/object/general/domicilio/domicilio.interface';
import { DomArrayInterface } from '@comp/object/general/domicilio/domArray.interface';

@Injectable()
export class DomicilioService {

  constructor(private http: HttpClient) {}

  // Regresa los domicilios del acreditado
  public domiciliosGet(origen: string, codigo: string, secuencia: string, tipo: string, cdgpif: string, cdgfin: string): Observable<DomArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/domicilio/get', {origen: origen, codigo: codigo, secuencia: secuencia, tipo: tipo, cdgpif: cdgpif, cdgfin: cdgfin}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa los domicilios del acreditado V2
  public domiciliosGetV2(origen: string, codigo: string, tipo: string, cdgpi: string, clns: string, secuencia: string): Observable<DomArrayInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/domicilio/getV2', {origen: origen, codigo: codigo, tipo: tipo, cdgpi: cdgpi, clns: clns, secuencia: secuencia}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa los datos del domicilio  del acreditado (nombre de colonia, Entidad, Estado, Municipio, CP, etc.)
  public domiciliosDetalleGet(codigo: string, origen: string, tipo: string, clns: string, cdgpi: string, todo: string, cdggb: string, sinacentos: string, primmayuscula: string, infcrediticia: string): Observable<DomArrayInterface> {
    return this.http.post('api/general/dom/get', {codigo: codigo, origen: origen, tipo: tipo, clns: clns, cdgpi: cdgpi, todo: todo, cdggb: cdggb, sinacentos: sinacentos, primmayuscula: primmayuscula, infcrediticia: infcrediticia}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Crea o actualiza un domicilio
  public domicilioPost(domicilio: DomicilioInterface): Observable<DomicilioInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/domicilio/post', {domicilio: domicilio}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina un medio de comunicación
  public domicilioDelete(domicilio: any): Observable<{}> {
    return this.http.post('api/control-cartera/operacion/acreditados/domicilio/delete', {domicilio: domicilio}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public entidadesGet(cdgpai: string): Observable<{}> {
    return this.http.post('api/control-cartera/listados/entidades/get', {cdgpai: cdgpai}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public municipiosGet(cdgpai: string, cdgef: string): Observable<{}> {
    return this.http.post('api/control-cartera/listados/municipios/get', {cdgpai: cdgpai ? cdgpai : '', cdgef: cdgef ? cdgef : '' }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public localidadesGet(cdgpai: string, cdgef: string, cdgmu: string): Observable<{}> {
    return this.http.post('api/control-cartera/listados/localidades/get', {cdgpai: cdgpai ? cdgpai : '', cdgef: cdgef ? cdgef : '', cdgmu: cdgmu ? cdgmu : '' }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public coloniasGet(cdgpai: string, cdgef: string, cdgmu: string, cdglo: string): Observable<{}> {
    return this.http.post('api/control-cartera/listados/colonias/get', {cdgpai: cdgpai ? cdgpai : '', cdgef: cdgef ? cdgef : '', cdgmu: cdgmu ? cdgmu : '', cdglo: cdglo ? cdglo : ''}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public paisesGet(): Observable<{}> {
    return this.http.post('api/control-cartera/listados/paises/get', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public codigoPostalGet(cdgpostal: string): Observable<{}> {
    return this.http.post('api/control-cartera/codigopostal/get', {cdgpostal: cdgpostal}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public getDomicilio(dom: ReqDomDet): Observable<ResDomDet> {
    return this.http.post('api/dom/domdetalle/consulta', dom).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}

interface ReqDomDet{
  version: string;
  cdgem: string;
  codigo: string;
  origen: string;
  tipo: string;
  clns: string;
  cdgpi: string;
  todo: string;
  cdggb: string;
  sinacentos: string;
  primmayuscula: string;
  infcrediticia: string;
}

interface ResDomDet{
  array_datdomicilio: Array<DomItem>;
}

interface DomItem{
  abreviatura: string;
  abreviaturaCC: string;
  calle: string;
  callescaracteres: string;
  cdgcol: string;
  cdgef: string;
  cdglo: string;
  cdgmu: string;
  cdgpai: string;
  cdgpaibc: string;
  cdgpaicc: string;
  cdgpostal: string;
  entrecalles: string;
  freside: string;
  mco_email: string;
  mco_numero: string;
  nombrecol: string;
  nombreef: string;
  nombrelo: string;
  nombremu: string;
  nombrepai: string;
}
