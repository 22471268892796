import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutorizacionEscalonadaInterface } from '@comp/object/general/autorizacionEscalonada/autorizacionEscalonada.interface';


@Injectable()
export class AutorizacionEscalonadaService {

  constructor(private http: HttpClient) {}

  // Regresa el listado clientes de grupo solidario o cliente individual con el estatus PLD.
  public autorizacionEscalonadaGet(cdgns: string, cdgcl: string, clns: string): Observable<AutorizacionEscalonadaInterface> {
    return this.http.post('api/general/autorizacionescalonada/get', {cdgns: cdgns, cdgcl: cdgcl, clns: clns}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  

}
