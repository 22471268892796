import { ArrayFondos } from './../../../components/object/general/fondos/arrayfondos.interface';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';



@Injectable()
export class FondoService {

  procesado: Observable<string>;

  constructor(private http: HttpClient) { }

  getFondos(consultaFOJson: string): Observable<ArrayFondos> {
    return this.http.post('api/general/fondos/consultar', { consultaFOJson: consultaFOJson }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  getFondosFiltrados(cdgpg: string, codigo: string, nombre: string): Observable<ArrayFondos> {
    return this.http.post('api/general/fondos/filtrar', {
      'cdgpg': cdgpg,
      'codigo': codigo,
      'nombre': nombre
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

}
