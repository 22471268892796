import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeInterface } from '@comp/object/general/sector/se.interface';

@Injectable()
export class Sectorervice {

  constructor(private http: HttpClient) {}

  // Regresa el catálogo Sector
  public sectorGet(codigo: string, nombre: string): Observable<SeInterface> {
    return this.http.post('api/general/se/get', {codigo: codigo, nombre: nombre}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
