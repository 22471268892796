import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstadoDeCuentaPerInterface } from '@comp/object/control-cartera/reportes/estadocuentaperiodo/estadocuentaperiodo.interface';
import { EstadoDeCuentaInterface } from '@comp/object/control-cartera/reportes/estadodecuenta/reporte/estadocuenta.interface';

@Injectable()
export class EstadoDeCuentaPerService {

  public parametros;

  constructor(private http: HttpClient) {}

  //  Consulta estado de cuenta por periodo
  // tslint:disable-next-line:max-line-length
  public estadoDeCuentaPerGet(cdgcl: string, ciclo: string, clns: string, cdgns: string, fecha: string, fechafutura: string, comision: string): Observable<EstadoDeCuentaPerInterface> {
    return this.http.post('api/control-cartera/reportes/estadodecuentaperiodo/get', {cdgcl: cdgcl, ciclo: ciclo, clns: clns, cdgns: cdgns, fecha: fecha, fechafutura: fechafutura, comision: comision})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public estadoDeCuentaGet(cdgclns: string, ciclo: string, cdgcl: string, clns: string, fecha: string, fechafutura: string, comision: string): Observable<EstadoDeCuentaInterface> {
    return this.http.post('api/control-cartera/reportes/estadodecuenta/get', {cdgclns: cdgclns, ciclo: ciclo, cdgcl: cdgcl, clns: clns, fecha: fecha, fechafutura: fechafutura, comision: comision})
    .pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
