import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RPAService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de rangos
     */
    public getRPA(jsonRPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/rpa/get', 
            { "jsonRPA": jsonRPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Insertar 
     */
    public insertarRPA(jsonRPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/rpa/set', 
            { "jsonRPA": jsonRPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Editar 
     */
    public editarRPA(jsonRPA): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/analisis-credito/rpa/editar', 
        {  "jsonRPA": jsonRPA})
    .pipe(
        map(res => {
        return JSON.parse(JSON.stringify(res));
        })
    );
    }

    /**
         * Eliminar 
     */
    public eliminarRPA(jsonRPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/rpa/eliminar', 
        {  "jsonRPA": jsonRPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
}
