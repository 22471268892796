import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayDestinoCredito } from "@comp/object/control-cartera/operacion/prestamos/destino-credito/arraydestinocredito.interface";


@Injectable()
export class DestinoCreditoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getDestinoCredito(consultaDCrJson: string): Observable<ArrayDestinoCredito> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/destino-credito/consultar', { consultaDCrJson: consultaDCrJson }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}