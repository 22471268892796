import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PvArrayInterface } from '@comp/object/bancos/catalogos/puntosVenta/pv_array.interface';
import { PveArrayInterface } from '@comp/object/bancos/catalogos/puntosVenta/pve_array.interface';

@Injectable()
export class PuntosVentaService {

    constructor(private http: HttpClient) {}

    /**
     * Funcion obtener proveedores
     */
    public getPV( ): Observable<PvArrayInterface> {
        return this.http.post('api/bancos/catalogos/puntos-venta/GETPV', { }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion obtener puntos de venta
     */
    public getPVE(cdgpv: string ): Observable<PveArrayInterface> {
        return this.http.post('api/bancos/catalogos/puntos-venta/GETPVE', { 
            "cdgpv": cdgpv
        }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion registrar puntos de venta
     */
    public registrarPVE(cdgpv: string, nombre: string, cdgext: string): Observable<string> {
        return this.http.post('api/bancos/catalogos/puntos-venta/REGISTRAR', { 
            "cdgpv": cdgpv,
            "nombre": nombre,
            "cdgext": cdgext
        }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion modificar puntos de venta
     */
    public modificarPVE(cdgpv: string, nombre: string, cdgext: string, cdgpve: string): Observable<string> {
        return this.http.post('api/bancos/catalogos/puntos-venta/MODIFICAR', { 
            "cdgpv": cdgpv,
            "nombre": nombre,
            "cdgext": cdgext,
            "cdgpve": cdgpve,
        }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion eliminar puntos de venta
     */
    public eliminarPVE(cdgpv: string, cdgpve: string): Observable<string> {
        return this.http.post('api/bancos/catalogos/puntos-venta/ELIMINAR', { 
            "cdgpv": cdgpv,
            "cdgpve": cdgpve,
        }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
}