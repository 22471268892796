import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MTFArrayInterface } from '@comp/object/general/movimientoTraspasoFondos/mtfArray.interface';


@Injectable()
export class MovimientoTraspasoFondosService {

  constructor(private http: HttpClient) {
    
  }

  // Regresa un arreglo con los registros de traspaso de fondos
  public mtfGet(cdgclns: string, cdgcl: string, ciclo: string, fechatf: string, secuencia: string): Observable<MTFArrayInterface> {
    return this.http.post('api/general/mtf/get', {cdgclns: cdgclns, cdgcl: cdgcl, ciclo: ciclo, fechatf: fechatf, secuencia: secuencia}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  

}
