import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CatalogoMatrizDeRiesgoService {

  constructor(private http: HttpClient) {}

  // Regresa el Catálogo de la Matriz de Riesgo
  public catalagoMatrizDeRiesgoGet(codigo: string, cdgccm: string, concepto: string, tipo: string): Observable<any> {
    return this.http.post('api/general/cmr/get', {codigo : codigo, cdgccm: cdgccm, concepto: concepto, tipo: tipo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
