import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArraySolicitudes } from "@comp/object/control-cartera/operacion/prestamos/solicitud/arraysolicitudes.interface";


@Injectable()
export class SolicitudPrestamoIndService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getSolicitudes(consultaSCJson: string): Observable<ArraySolicitudes> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/solicitud/consultar', { consultaSCJson: consultaSCJson }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarSolicitud(insercionSCJson: string, pPPrJson: string, pPgeJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/solicitud/insertar', { insercionSCJson: insercionSCJson, pPPrJson: pPPrJson, pPgeJson: pPgeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarSolicitud(edicionSCJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/solicitud/editar', { edicionSCJson: edicionSCJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarSolicitud(eliminarSCJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/solicitud/eliminar', { eliminarSCJson: eliminarSCJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }



}