'use strict';

import { Datos, Role } from './Datos';

export class DatosU extends Datos {
    public empresa: string;
    public permisos: Permisos;

    constructor(obj: any, rol: Role) {
        super(obj, rol);
        this.empresa = obj.empresa;
        this.permisos = obj.permisos;
    }
}

class Permisos {
    public administracion_monitore: string;
    public responder_aclaraciones: string;
    public registro_monitoreo: string;
}

export class UserPe {
    public datos: DatosU;
    public token: string;
    public error: string;
    public error_description: string;
    public code: string;
    public active: string;
}
