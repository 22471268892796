import { Component, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicFormGroupModel, BreakPoint } from "@comp/dynamic-form/core/model/form-group/dynamic-form-group.model";
import { DynamicTemplateDirective } from "@comp/dynamic-form/core/directive/dynamic-template.directive";
import { DynamicFormControlCustomEvent } from "@comp/dynamic-form/core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";
import { DynamicFormGroupDivComponent } from "@comp/dynamic-form/core/component/dynamic-form-group-div.component";

@Component({
    selector: "dynamic-material-div-group",
    templateUrl: "./dynamic-material-div-group.component.html"
})
export class DynamicMaterialFormDivComponent extends DynamicFormGroupDivComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicFormGroupModel;
    @Input() templates: QueryList<DynamicTemplateDirective> | DynamicTemplateDirective[] | undefined;
    @Input () fxLayoutAling: string;
    @Input () fxLayoutGap: BreakPoint;


    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();
/*
    @ViewChildren(forwardRef(() => DynamicMaterialFormControlContainerComponent))
    components: QueryList<DynamicMaterialFormControlContainerComponent>;
*/
    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService) {

        super(layoutService, validationService);
    }
}
