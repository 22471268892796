import { DynamicFormGroupModel, DynamicFormGroupModelConfig } from "../form-group/dynamic-form-group.model";
import { DynamicFormControlLayout } from "../misc/dynamic-form-control-layout.model";
import { serializable } from "../../decorator/serializable.decorator";

export const DYNAMIC_FORM_CONTROL_TYPE_DIV_GROUP = "DIV_GROUP";

export class DynamicDivGroupModel extends DynamicFormGroupModel {

    @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_DIV_GROUP;

    constructor(config: DynamicFormGroupModelConfig, layout?: DynamicFormControlLayout) {
        super(config, layout);
    }
}
