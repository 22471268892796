import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcrInterface } from '@comp/object/general/actividadporcredito/acr.interface';

@Injectable()
export class ActividadPorCreditoService {

  constructor(private http: HttpClient) {}

  // Actividad por credito
  public acrGet(cdgclns: string, ciclo: string, clns: string, solicitud: Date): Observable<AcrInterface> {
    return this.http.post('api/general/acr/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, solicitud: solicitud}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
