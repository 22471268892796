import { DynamicMaterialCustomComponent } from '@comp/dynamic-form/ui-material/custom/dynamic-material-custom.component';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ContentChildren,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicTemplateDirective } from "../core/directive/dynamic-template.directive";
import { DynamicFormArrayGroupModel } from "../core/model/form-array/dynamic-form-array.model";
import { DynamicFormLayout, DynamicFormLayoutService } from "../core/service/dynamic-form-layout.service";
import { DynamicFormControlModel } from "../core/model/dynamic-form-control.model";
import { DynamicFormControlEvent } from "../core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "../core/service/dynamic-form-validation.service";
import { DynamicFormComponentService } from "../core/service/dynamic-form-component.service";
import { DynamicFormRelationService } from "../core/service/dynamic-form-relation.service";
import { DynamicFormControl } from "../core/component/dynamic-form-control-interface";
import { DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER } from "../core/model/datepicker/dynamic-datepicker.model";
import { DYNAMIC_FORM_CONTROL_TYPE_INPUT } from "../core/model/input/dynamic-input.model";
import { DYNAMIC_FORM_CONTROL_TYPE_SELECT } from "../core/model/select/dynamic-select.model";
import { DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA } from "../core/model/textarea/dynamic-textarea.model";
import { DynamicFormValueControlModel } from "../core/model/dynamic-form-value-control.model";
import { DynamicFormContainerComponent } from '../core/component/dynamic-form-container.component';

@Component({
    selector: "dynamic-material-form-container",
    templateUrl: "./dynamic-material-form-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMaterialFormContainerComponent extends DynamicFormContainerComponent {

    @ContentChildren(DynamicTemplateDirective) contentTemplateList: QueryList<DynamicTemplateDirective>;

    @HostBinding("class") klass;

    @Input() context: DynamicFormArrayGroupModel | null = null;
    @Input() group: FormGroup;
    @Input() hostClass: string[];
    @Input("templates") inputTemplateList: QueryList<DynamicTemplateDirective>;
    @Input() layout: DynamicFormLayout;
    @Input() model: DynamicFormControlModel;

    @Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
    @Output("matEvent") customEvent: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();

    @ViewChild("componentViewContainer", {read: ViewContainerRef}) componentViewContainerRef: ViewContainerRef;

    constructor(protected changeDetectorRef: ChangeDetectorRef,
                protected componentFactoryResolver: ComponentFactoryResolver,
                protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                protected componentService: DynamicFormComponentService,
                protected relationService: DynamicFormRelationService) {

        super(changeDetectorRef, componentFactoryResolver, layoutService, validationService, componentService, relationService);
    }

    get componentType(): Type<DynamicFormControl> | null {
        return this.componentService.getCustomComponentType(this.model) || materialUIFormControlMapFn(this.model);
    }

    get hasMatFormField(): boolean {

        const matFormFieldTypes = [DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DYNAMIC_FORM_CONTROL_TYPE_INPUT,
            DYNAMIC_FORM_CONTROL_TYPE_SELECT, DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA];

        return matFormFieldTypes.some(type => this.model.type === type) || (
            this.model instanceof DynamicFormValueControlModel &&
            this.model.getAdditional("isFormFieldControl")
        );
    }
}

export function materialUIFormControlMapFn(model: DynamicFormControlModel): Type<DynamicFormControl> | null {

    switch (model.type) {
        case "CUSTOM":
            return DynamicMaterialCustomComponent;
        default:
            return null;
    }
}
