import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEPInterface } from '@comp/object/general/docuementosEntregados/dep.interface';
import { TDInterface } from '@comp/object/general/tipodedocumentos/td.interface';


@Injectable()
export class DocuementosEntregadosService {

  constructor(private http: HttpClient) {}

  // Regresa el listado de documentos entregados por préstamo
  public documentosEntregadosGet(cdgclns: string, clns: string): Observable<DEPInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/documentos/get', {cdgclns: cdgclns, clns: clns}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Inserta / Actualiza documentos entregados por préstamo
  public documentosEntregadosSet(): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/documentos/post', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Elimina documentos entregados por préstamo
  public documentosEntregadosDelete(): Observable<any> {
    return this.http.post('api/control-cartera/operacion/acreditados/documentos/delete', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa el listado de tipo de documentos
  public tipoDeDocumentosGet(cdgclns: string, codigo: string, clns: string, nombre: string): Observable<TDInterface> {
    return this.http.post('api/control-cartera/operacion/acreditados/documentos/td/get', {cdgclns: cdgclns, codigo: codigo, clns: clns, nombre: nombre}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
