import { ɵlooseIdentical as looseIdentical } from "@angular/core";
import { DynamicFormControlLayout } from "../misc/dynamic-form-control-layout.model";
import { serializable } from "../../decorator/serializable.decorator";
import { isFunction } from "../../utils/core.utils";
import { DynamicFormValueControlModel, DynamicFormValueControlModelConfig } from "../dynamic-form-value-control.model";

export const DYNAMIC_FORM_CONTROL_TYPE_DATE_RANGE = "DATERANGE";

export interface DynamicDateRangeModelConfig<T> extends DynamicFormValueControlModelConfig<T> {

    compareWithFn?: (o1: any, o2: any) => boolean;
}

export class DynamicDateRangeModel<T> extends DynamicFormValueControlModel<T> {

    compareWithFn: (value1: any, value2: any) => boolean;

    @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_DATE_RANGE;

    constructor(config: DynamicDateRangeModelConfig<T>, layout?: DynamicFormControlLayout) {

        super(config, layout);

        this.compareWithFn = isFunction(config.compareWithFn) ? config.compareWithFn : looseIdentical;
    }
}
