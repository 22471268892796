import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClInterface } from '@comp/object/general/estructura-organizacional/cl/cl.interface';
import { RgArrayInterface } from '@comp/object/general/estructura-organizacional/rg/rgArray.interface';
import { CoInterface } from '@comp/object/general/estructura-organizacional/co/co.interface';
import { EstructuraInterface } from '@comp/object/general/estructura-organizacional/estructura.interface';

@Injectable()
export class EstructuraOrganizacionalService {
  arraycdgrg: string[] = [];
  arraycdgco: string[] = [];
  arraycdgrg_Tmp: string[] = [];
  arraycdgco_Tmp: string[] = [];
  arraycdgocpe: string[] = [];
  arrayclEstOrg: any[] = new Array();

  constructor(private http: HttpClient) {}


  // Regiones
  public regiones(): Observable<RgArrayInterface> {
    return this.http.post('api/general/estructura-organizacional/rg', { }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Coordinaciones
  public coordinaciones(pArraycdgrg: string[]): Observable<CoInterface> {
    return this.http.post('api/general/estructura-organizacional/co', { pArraycdgrg: pArraycdgrg }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Estructura organizacional
  public estructuraOrg(arraycdrg: string[], arraycdgco: string[], todos: string): Observable<EstructuraInterface> {
    return this.http.post('api/general/estructura-organizacional', { arraycdrg: arraycdrg, arraycdgco: arraycdgco, todos: todos }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Acreditados
  public acreditadosGet(clns: string, arraycdgorg: string[], arraycdgco: string[], arraycdgocpe: string[]): Observable<ClInterface> {
    return this.http.post('api/general/estructura-organizacional/cl/estorg', { clns: clns, arraycdgorg: arraycdgorg, arraycdgco: arraycdgco, arraycdgocpe: arraycdgocpe }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Filtro acreditados
  public fAcreditadosGet(clns: string, arraycdgrg: string[], arraycdgco: string[], arraycdgocpe: string[]): Observable<ClInterface> {
    return this.http.post('api/general/estructura-organizacional/cl/festorg', { clns: clns, arraycdgrg: arraycdgrg, arraycdgco: arraycdgco, arraycdgocpe: arraycdgocpe }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
