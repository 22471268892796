import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { MedioComunicacion } from "@app/inversionistas/models/mediocomunicacion.model";
import { ArrayDomicilio } from "@comp/object/inversionistas/operaciones/domicilio/arraydomicilios.interface";
import { ArrayMediosComunicacion } from "@comp/object/inversionistas/operaciones/medioscomunicacion/arraymadioscomunicacion.interface";
import { ArrayUsuarios } from "@comp/object/general/seguridad/usuarios/arrayusuarios.interface";
import { ArrayTiposUsuario } from "@comp/object/general/seguridad/tiposusuario/arraytiposusuario.interface";


@Injectable()
export class UsuariosService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getPaises(): Observable<any> {
        return this.http.post('api/control-cartera/listados/paises/get', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getEntidades(cdgpai: string): Observable<any> {
        return this.http.post('api/control-cartera/listados/entidades/get', { cdgpai: cdgpai }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getMunicipios(cdgpai: string,cdgef:string): Observable<any> {
        return this.http.post('api/control-cartera/listados/municipios/get', { cdgpai: cdgpai, cdgef:cdgef }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getLocalidades(cdgpai: string,cdgef:string,cdgmu:string): Observable<any> {
        return this.http.post('api/control-cartera/listados/localidades/get', { cdgpai: cdgpai, cdgef: cdgef, cdgmu: cdgmu }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getColonias(cdgpai: string,cdgef:string,cdgmu:string, cdglo:string): Observable<any> {
        return this.http.post('api/control-cartera/listados/colonias/get', { cdgpai: cdgpai, cdgef: cdgef, cdgmu: cdgmu, cdglo: cdglo }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getUsuarios(consultaPeJson: string): Observable<ArrayUsuarios> {
        return this.http.post('api/general/seguridad/usuarios/consultar', { consultaPeJson: consultaPeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarUsuario(eliminarPeJson: string): Observable<any> {
        return this.http.post('api/general/seguridad/usuarios/eliminar', { eliminarPeJson: eliminarPeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarInformacionUsuario(insercionPeJson: string): Observable<string> {
        return this.http.post('api/general/seguridad/usuarios/insertar', { insercionPeJson: insercionPeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarInformacionUsuario(edicionPeJson: string): Observable<string> {
        return this.http.post('api/general/seguridad/usuarios/editar', { edicionPeJson: edicionPeJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarTiposDeUsuario(insercionUtJson: string): Observable<string> {
        return this.http.post('api/general/tiposusuario/insertar', { insercionUtJson: insercionUtJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarTiposDeUsuario(eliminarUtJson: string): Observable<string> {
        return this.http.post('api/general/tiposusuario/eliminar', { eliminarUtJson: eliminarUtJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarPermisoUsuario(insercionPuJson: string): Observable<string> {
        return this.http.post('api/general/permisos/insertar', { insercionPuJson: insercionPuJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarPermisoDeUsuario(eliminarPuJson: string): Observable<string> {
        return this.http.post('api/general/permisos/eliminar', { eliminarPuJson: eliminarPuJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getTiposUsuario(consultaUtJson: string): Observable<any> {
        return this.http.post('api/general/tiposusuario/consultar', { consultaUtJson: consultaUtJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }


    getPermisosUsuario(data: String, aplicacion: string): Observable<any> {
        return this.http.post('api/general/permisos/get-permisos-om',
            {
                cdgmod: "IN",
                cdgpe: data,
                aplicacion: aplicacion,
                arrayCdgom: null,
                version: 0
            }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );



    }

    getPermisosCoordinacion(consultaPcoJson: string): Observable<any> {
        return this.http.post('api/general/permisoscoordinacion/consultar', { consultaPcoJson: consultaPcoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    insertarPermisosCoordinacion(insercionPcoJson: string): Observable<string> {
        return this.http.post('api/general/permisoscoordinacion/insertar', { insercionPCoJson: insercionPcoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarPermisoCoordinacion(eliminarPCoJson: string): Observable<string> {
        return this.http.post('api/general/permisoscoordinacion/eliminar', { eliminarPCoJson: eliminarPCoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getPermisosSucursal(consultaPfoJson: string): Observable<any> {
        return this.http.post('api/general/permisossucursal/consultar', { consultaPfoJson: consultaPfoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    insertarPermisosSucursal(insercionPfoJson: string): Observable<string> {
        return this.http.post('api/general/permisossucursal/insertar', { insercionPfoJson: insercionPfoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarPermisoSucursal(eliminarPFoJson: string): Observable<string> {
        return this.http.post('api/general/permisossucursal/eliminar', { eliminarPFoJson: eliminarPFoJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    registrarDomicilio(domicilio: any): Observable<string> {
        return this.http.post('api/inversionistas/operaciones/domicilio/post', domicilio
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarDomicilio(direccion: any, secuencia: string): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/post', { direccion, secuencia }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
    buscarDomicilio(codigoUsuario: string): Observable<ArrayDomicilio> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/get', { codigoUsuario }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarDomicilio(domicilio: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/domicilio/delete', { domicilio }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public registrarMedioDeComunicacion(medioComunicacion: any): Observable<any> {

        return this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/post', medioComunicacion).
        pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    buscarMediosComunicacion(codigoUsuario: string): Observable<ArrayMediosComunicacion> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/get', { codigoUsuario }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    editarMedioComunicacion(medioComunicacion: any, secuencia: string): Observable<any> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/post',
            {
                medioComunicacion, secuencia
            }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarMedioComunicacion(medioComunicacion: any): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/mediosdecomunicacion/delete', { medioComunicacion }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    generarCURPRFCUsuario(nombre1: string, n2: string, apellido1: string, apellido2: string, fn: string, sexo: string, cdgef: string): Observable<string> {
        return this.http.post('api/control-cartera/rfccurpife/get', {
            nombre1: nombre1, nombre2: n2, apellido1: apellido1, apellido2: apellido2,
            fNacimiento: fn, sexo: sexo, cdgef: cdgef
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }



}