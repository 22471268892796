import { Component, EventEmitter, forwardRef, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormGroupComponent } from "@comp/dynamic-form/core/component/dynamic-form-group.component";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicFormGroupModel } from "@comp/dynamic-form/core/model/form-group/dynamic-form-group.model";
import { DynamicTemplateDirective } from "@comp/dynamic-form/core/directive/dynamic-template.directive";
import { DynamicFormControlCustomEvent } from "@comp/dynamic-form/core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";

@Component({
    selector: "dynamic-material-form-group",
    templateUrl: "./dynamic-material-form-group.component.html"
})
export class DynamicMaterialFormGroupComponent extends DynamicFormGroupComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicFormGroupModel;
    @Input() templates: QueryList<DynamicTemplateDirective> | DynamicTemplateDirective[] | undefined;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();
/*
    @ViewChildren(forwardRef(() => DynamicMaterialFormControlContainerComponent))
    components: QueryList<DynamicMaterialFormControlContainerComponent>;
*/
    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService) {

        super(layoutService, validationService);
    }
}
