import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComisionArrayInterface } from '@comp/object/control-cartera/operacion/movimientos/importaciones/depositosbancarios/comisiondeposito/comision_array.interface';

@Injectable()
export class CobrarComisionService {

    constructor(private http: HttpClient) { }

    /**
     * Funcion obtener las comisiones
     */
    public getComision(version: string): Observable<ComisionArrayInterface> {
        return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios/comisiondeposito/getcomision', {
            'version': version
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}
