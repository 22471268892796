import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material';
import { OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-impacto',
  templateUrl: './impacto.component.html',
    styleUrls: ['./impacto.component.scss']
  })
  export class ImpactoDialogComponent implements OnInit {
    formControl = new FormControl('', [Validators.required]);
    rateControl = new FormControl('', [Validators.required]);

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ImpactoDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.data.impacto = data.impacto.replace('%', '');
  }

  ngOnInit(): void {
    this.rateControl = new FormControl("", [Validators.max(100), Validators.min(0)])
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Este campo es requerido.' : '';
  }

  applyFilter(ev: any) {
    if (ev.keyCode < 48 || ev.keyCode > 57) {
      ev.preventDefault();
    }
  }

  clear() {
    this.data.impacto = '';
  }

  aceptar() {
    this.dialogRef.close(this.data);
  }

  cancelar() {
    this.dialogRef.close({});
  }

}
