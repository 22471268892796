import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GiInterface } from '@comp/object/general/giro/gi.interface';

@Injectable()
export class GiroService {

  constructor(private http: HttpClient) {}

  // Regresa el catálogo de Giro
  public giroGet(codigo: string, cdgse: string, nombre: string): Observable<GiInterface> {
    return this.http.post('api/general/gi/get', {codigo: codigo, cdgse: cdgse, nombre: nombre}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
