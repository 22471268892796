import { Component, EventEmitter, Inject, Input, Optional, Output } from "@angular/core";
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { FormGroup } from '@angular/forms';

@Component({
    selector: "dynamic-material-buttons",
    templateUrl: "./dynamic-material-buttons.component.html"
})
export class DynamicMaterialButtonsComponent {

    @Input() group: FormGroup;
    @Input() excell: boolean = false;

    @Output() clickview: EventEmitter<any> = new EventEmitter();
    @Output() clickpdf: EventEmitter<any> = new EventEmitter();
    @Output() clickexcell: EventEmitter<any> = new EventEmitter();


    constructor(@Inject(MAT_RIPPLE_GLOBAL_OPTIONS) @Optional() public RIPPLE_OPTIONS: RippleGlobalOptions) {

    }

    public clickbutton(type:string){
        if(type === "PDF"){
            this.clickpdf.emit();
        }else if(type === "E"){
            this.clickexcell.emit();
        }else{
            this.clickview.emit();
        }
        
    }
}
