import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AmortizacionService {

    constructor(private http: HttpClient) {}

    /**
     * Busqueda de tipo individual, grupal e individual de grupo
     */
    public getBusqueda(cdgcl: string, cdgns:string, clns:string ): Observable<any[]> {
        return this.http.post("api/control-cartera/reportes/tablaamortizacion/buscarClns", 
        { "version": "0","cdgcl": cdgcl, "cdgns": cdgns, "nombre":"%", "tipo": clns }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }


    /**
     *  Busqueda del credito 
     */
    public getBusCredito(codigo: string, cdgns:string, ciclo:string, clns:string, arraySituacion, pRedondeo): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/tablaamortizacion/buscarCredito', 
        { "version": "0", "cdgcl": codigo ,"cdgns": cdgns, "ciclo": ciclo,"clns": clns,"situacionArray": arraySituacion,"redondeo": pRedondeo}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     *  Genera excel
     */
    public generaExcel(body): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/tablaamortizacion/getXlsx', 
        body, {responseType: 'blob'}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     *  Busqueda logo
     */
    public getLogo(): Observable<any[]>{
        return this.http.get('api/ahorros/empresa/logo', { }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    } 

    /**
     *  Busqueda logo para cliente
     */
    public getLogoCl(): Observable<any[]>{
        return this.http.get('api/ahorros/empresa/logocl', { }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    } 

    /**
     * Formato de fecha
     */
    public getFecha(cdgps: String): Observable<any[]>{
        return this.http.post('api/general/vp/get', {
            "cdgps": cdgps, "desde": null
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    public getFechacl(cdgps: String): Observable<any[]>{
        return this.http.post('api/general/vp/getcl', {
            "cdgps": cdgps, "desde": null
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    

     /**
     *  Busqueda de versiones 
     */
      public getVersiones(codigo: string, ciclo:string, clns:string): Observable<any[]> {
        //   console.log(codigo, " ", ciclo, "  ", clns)
        return this.http.post('api/control-cartera/reportes/tablaamortizacion/getVersiones', 
        { "version": "0", "cdgclns": codigo , "ciclo": ciclo,"clns": clns}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

      // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<any[]> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public valorParametrocl(cdgps: string, desde: Date): Observable<any[]> {
    return this.http.post('api/general/vp/getcl', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        // console.log(res);
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

    /* Busqueda de tipo individual, grupal e individual de grupo V2*/
    public getBusquedaV2(cdgcl: string, cdgns:string, clns:string ): Observable<any[]> {
        return this.http.post("api/control-cartera/reportes/tablaamortizacion/buscarClnsv2", {cdgcl: cdgcl, cdgns: cdgns, nombre: "%", tipo: clns}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

}
