import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoInterface } from '@comp/object/general/moneda/mo.interface';

@Injectable()
export class MoffinService {

  constructor(private http: HttpClient) {}

  // Regresa PIN para moffin REGISTRO
  public moffinPINGet(cdgcl): Observable<MoInterface> {
    return this.http.post('api/general/moffin/getPIN', 
        {"cdgcl":cdgcl}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  
  // Consulta Buro de Crédito
  public moffinGet(cdgcl, fecha, solicitud): Observable<MoInterface> {
    return this.http.post('api/general/moffin/get', 
        {"cdgcl":cdgcl, "fecha": fecha, "solicitud":solicitud}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
}
