import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SolicitudService {
    constructor(private http: HttpClient) {}

    /**
     * Funcion obtener los clientes
     */
    public getClientes(codigo: string, nombre: string, inicio: number, limite: number): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/buscar', {
            "cdgcl": codigo,
            "nombre": nombre,
            "inicio": inicio,
            "limite":limite
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion obtener las cuentas de ahorro
     */
    public getCtsAhorro(codigo: string): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/cuenta/buscar', {
            id: { cdgcl: codigo}
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion eliminar las cuentas de ahorro
     */
    public eliminarCtaAhorro(cuenta): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/cuenta/eliminar', {
           cuenta
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar comisiones
     */
    public getComisiones(codigo): Observable<any[]> {
        return this.http.post('api/ahorros/catalogos/productos/comisiones/get', {
           codigo: codigo
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar productos
     */
    public getProductos(codigo, nombre): Observable<any[]> {
        return this.http.post('api/ahorros/catalogos/productos/get', {
            codigo:codigo,nombre:"%"
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar monedas
     */
    public getMonedas(): Observable<any[]> {
        return this.http.post('api/ahorros/listados/moneda/get', {
            
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
    
    /**
     * Consutar esquemas
     */
    public getEsquemas(): Observable<any[]> {
        return this.http.post('api/ahorros/catalogos/esquemas/buscar', {
            
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar coordinaciones
     */
    public getCoordinaciones(cdgco: string): Observable<any[]> {
        return this.http.post('api/personal/coordinacion', {
            cdgco: cdgco
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

     /**
     * Consutar documentos
     */
    public getDocumentos(cdgch: string): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/cuenta/docs/getdep', {
            cdgch: cdgch
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar documentos -- Personal
     */
    public getDocumentoPersonal(codigo: string, nombre: string, inicio: number, limite: number): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/documentos/pebuscar', {
            cdgpe: codigo,
            nombre: nombre,
            inicio: inicio,
            limite: limite
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Consutar documentos -- Tipo
     */
    public getDocumentoTipo(codigo): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/documentos/tipobuscar', {
            codigo: codigo
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Agregar y modificar documento
     */
    public agregarDoc(json): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/cuenta/docs/agregardep', {
            json: json
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * 
     * Eliminar documentos
     */

     public eliminarDoc(json):Observable<any[]> {
         return this.http.post('api/ahorros/operacion/solicitud/cuenta/docs/eliminadoc', {
             documento: json
         }).pipe(
             map(res => JSON.parse(JSON.stringify(res)))
         );
     }

    /**
     * Crear cuenta de ahorro
     */
    public crearCuenta(body): Observable<any[]> {
        return this.http.post('api/ahorros/operacion/solicitud/cuenta/crear', {
            body
         }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }



}
