import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayTipoCredito } from "@comp/object/control-cartera/operacion/prestamos/tipo-credito/arraytipocredito.interface";


@Injectable()
export class TipoCreditoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getTiposCredito(consultaTCrJson: string): Observable<ArrayTipoCredito> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/tipo-credito/consultar', { consultaTCrJson: consultaTCrJson }

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

}