import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EPAService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de esquemas de análisis de crédito
     */
    public getEPA(jsonEPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/epa/get', 
            { "jsonEPA": jsonEPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Insertar 
     */
    public insertarEPA(jsonEPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/epa/set', 
            { "jsonEPA": jsonEPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Editar 
     */
    public editarEPA(jsonEPA): Observable<any[]> {
    return this.http.post('api/control-cartera/catalogos/analisis-credito/epa/editar', 
        {  "jsonEPA": jsonEPA})
    .pipe(
        map(res => {
        return JSON.parse(JSON.stringify(res));
        })
    );
    }

    /**
         * Eliminar 
     */
    public eliminarEPA(jsonEPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/epa/eliminar', 
        {  "jsonEPA": jsonEPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
         * Copiar 
     */
    public copiarEPA(jsonEPA): Observable<any[]> {
        return this.http.post('api/control-cartera/catalogos/analisis-credito/epa/copiar', 
        {  "jsonEPA": jsonEPA})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

}
