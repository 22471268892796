import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EXPInterface } from '@comp/object/general/expediente/exp.interface';


@Injectable()
export class ExpedienteService {

  constructor(private http: HttpClient) {}

  // Regresa el listado de expedientes entregados por préstamo
  public expedienteGet(cdgclns: string, ciclo: string, clns: string, solicitud: string): Observable<EXPInterface> {
    return this.http.post('api/general/exp/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, solicitud: solicitud}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public expedienteGetV2(cdgclns: string, ciclo: string, clns: string, solicitud: string): Observable<EXPInterface> {
    return this.http.post('api/general/exp/get_v2', {cdgclns: cdgclns, ciclo: ciclo, clns: clns, solicitud: solicitud}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Actualiza expedientes entregados por préstamo
  public expedientePost(expediente: any): Observable<EXPInterface> {
    return this.http.post('api/general/exp/post', expediente).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }  

}
