import { MatButtonModule } from '@angular/material/button';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { DynamicMaterialFormControlContainerComponent } from "./dynamic-material-form-control-container.component";
import { DynamicMaterialFormComponent } from "./dynamic-material-form.component";
import { DynamicMaterialCheckboxComponent } from "./checkbox/dynamic-material-checkbox.component";
import { DynamicMaterialChipsComponent } from "./chips/dynamic-material-chips.component";
import { DynamicMaterialDatePickerComponent } from "./datepicker/dynamic-material-datepicker.component";
import { DynamicMaterialFormArrayComponent } from "./form-array/dynamic-material-form-array.component";
import { DynamicMaterialFormGroupComponent } from "./form-group/dynamic-material-form-group.component";
import { DynamicMaterialInputComponent } from "./input/dynamic-material-input.component";
import { DynamicMaterialRadioGroupComponent } from "./radio-group/dynamic-material-radio-group.component";
import { DynamicMaterialSelectComponent } from "./select/dynamic-material-select.component";
import { DynamicMaterialSlideToggleComponent } from "./slide-toggle/dynamic-material-slide-toggle.component";
import { DynamicMaterialSliderComponent } from "./slider/dynamic-material-slider.component";
import { DynamicMaterialTextAreaComponent } from "./textarea/dynamic-material-textarea.component";
import { DynamicFormsCoreModule } from "../core/core.module";
import { DynamicMaterialCustomComponent } from "./custom/dynamic-material-custom.component";
import { DynamicMaterialFormContainerComponent } from './dynamic-material-form-container.component';
import { DynamicMaterialFormDivComponent } from './div/dynamic-material-div-group.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ErrorStateMatcher, MatMenuModule, MatDividerModule } from '@angular/material';
import { DynamicMaterialButtonsComponent } from './buttons/dynamic-material-buttons.component';
import { DynamicMaterialHiddenComponent } from './hidden/dynamic-material-hidden.component';
import { DynamicMaterialDateRangeComponent } from './date-range/dynamic-material-daterange.component';
import { MomentModule } from 'ngx-moment';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DateRageComponent } from '@comp/date-range/date-range.component';
import { TextMaskModule } from '@comp/text-mask/text-mask.module';

class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatMenuModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatButtonModule,
        TextMaskModule,
        DynamicFormsCoreModule,
        MomentModule,
        SatDatepickerModule,
        SatNativeDateModule
    ],
    declarations: [
        DynamicMaterialCheckboxComponent,
        DynamicMaterialButtonsComponent,
        DynamicMaterialChipsComponent,
        DynamicMaterialDatePickerComponent,
        DynamicMaterialFormArrayComponent,
        DynamicMaterialFormComponent,
        DynamicMaterialFormControlContainerComponent,
        DynamicMaterialFormGroupComponent,
        DynamicMaterialFormDivComponent,
        DynamicMaterialInputComponent,
        DynamicMaterialHiddenComponent,
        DynamicMaterialRadioGroupComponent,
        DynamicMaterialSelectComponent,
        DynamicMaterialSlideToggleComponent,
        DynamicMaterialSliderComponent,
        DynamicMaterialTextAreaComponent,
        DynamicMaterialCustomComponent,
        DynamicMaterialFormContainerComponent,
        DynamicMaterialDateRangeComponent,
        DateRageComponent
    ],
    entryComponents: [
        DynamicMaterialCheckboxComponent,
        DynamicMaterialButtonsComponent,
        DynamicMaterialChipsComponent,
        DynamicMaterialDatePickerComponent,
        DynamicMaterialFormArrayComponent,
        DynamicMaterialFormGroupComponent,
        DynamicMaterialFormDivComponent,
        DynamicMaterialInputComponent,
        DynamicMaterialHiddenComponent,
        DynamicMaterialRadioGroupComponent,
        DynamicMaterialSelectComponent,
        DynamicMaterialSlideToggleComponent,
        DynamicMaterialSliderComponent,
        DynamicMaterialTextAreaComponent,
        DynamicMaterialCustomComponent,
        DynamicMaterialDateRangeComponent,
        DateRageComponent
    ],
    exports: [
        DynamicFormsCoreModule,
        DynamicMaterialCheckboxComponent,
        DynamicMaterialButtonsComponent,
        DynamicMaterialChipsComponent,
        DynamicMaterialDatePickerComponent,
        DynamicMaterialFormArrayComponent,
        DynamicMaterialFormComponent,
        DynamicMaterialFormControlContainerComponent,
        DynamicMaterialFormGroupComponent,
        DynamicMaterialFormDivComponent,
        DynamicMaterialInputComponent,
        DynamicMaterialHiddenComponent,
        DynamicMaterialRadioGroupComponent,
        DynamicMaterialSelectComponent,
        DynamicMaterialSlideToggleComponent,
        DynamicMaterialSliderComponent,
        DynamicMaterialTextAreaComponent,
        DynamicMaterialCustomComponent,
        DynamicMaterialFormContainerComponent,
        DynamicMaterialDateRangeComponent,
        DateRageComponent,
        TextMaskModule
    ],
    providers: [
        {provide: ErrorStateMatcher, useClass: MyErrorStateMatcher}
    ]
})
export class DynamicFormsMaterialUIModule {
}
