import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from, ObservableInput } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayEsquemaRecargo } from "@comp/object/control-cartera/operacion/prestamos/esquema-recargo/arrayesquemarecargo.interface";


@Injectable()
export class EsquemaRecargoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getEsquemasRecargo(consultaERRJson: string): Observable<ArrayEsquemaRecargo> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/esquema-recargo/consultar', { consultaERRJson: consultaERRJson }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    getMontoFijo(jsonErr): Observable<any>{
        return this.http.post('api/control-cartera/operacion/prestamos/individual/esquema-recargo/consultar/getMonto', 
        { jsonErr: jsonErr }
                               
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }


}