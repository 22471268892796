import { DynamicFormControlModelConfig, DynamicFormControlModel } from "../dynamic-form-control.model";import { DynamicFormControlLayout } from "../misc/dynamic-form-control-layout.model";


export interface DynamicFormCustomControlModelConfig extends DynamicFormControlModelConfig {


}

export class DynamicFormCustomControlModel extends DynamicFormControlModel {
    type: string = "CUSTOM";

    constructor(config: DynamicFormCustomControlModelConfig, layout?: DynamicFormControlLayout) {
        super(config, layout);

    }

}
