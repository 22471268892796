import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modulos preinstalados
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from '@serv/auth/token.interceptor';
import { UtilService } from '@comp/util/util.service';
import { AuthService } from '@serv/auth/auth.service';
import { WindowRef } from '@comp/util/windowsRef';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ServiceModule } from '@serv/service.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Servicio para cambiar idioma en componente MatPaginator
import { getSpanishPaginatorIntl } from './locale/spanish-paginator-intl';
import { MatPaginatorIntl } from '@angular/material';

// Servicio para agregar un overload y mostrar un loading mientras se carga la pagina o termina algún proceso
import { NgxLoadingModule } from 'ngx-loading';

// Servicio para mostrar un spinner de carga
import { NgxSpinnerModule } from 'ngx-spinner';

import { ChartsModule } from 'ng2-charts';
import { AlertSnackBarComponent } from '@comp/snackbar/alert/alert-snackbar.component';

import { AgmCoreModule } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { ComponentsModule } from '@comp/components.module';
import { HttpClientModule } from '@angular/common/http';
import { AgmDirectionModule } from 'agm-direction';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialModule } from '@comp/material.module';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceModule,
    NgxSpinnerModule,
    NgxLoadingModule.forRoot({}),
    HttpClientModule,
    ChartsModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MaterialModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA2_A3CTvLMaCfRA_HJIOYSziQgLAKixUA'
      //apiKey: 'AIzaSyBrhY3Pd9O7_kghLXBEY-VZH7TEwqxswa8' // Android
      // apiKey: 'AIzaSyAJ4I0GxT0__5hV7rXHxmi9-uq2W0YJ7Hg' // Yunius web
      // apiKey: 'AIzaSyBoJOnWbjjLZWlINHoIJLLA73-nfYBh8UE' // De Prueba (ya no funciona)
    }),
    ComponentsModule,
    AgmDirectionModule 
  ],
  providers: [
    AuthService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    WindowRef,
    UtilService,
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    GoogleMapsAPIWrapper
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }

}
