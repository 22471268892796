import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    QueryList,
    SimpleChanges,
    Type,
    ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
    DynamicFormControlEvent
} from "./dynamic-form-control-event";
import { DynamicFormControlModel } from "../model/dynamic-form-control.model";
import { DynamicFormValueControlModel } from "../model/dynamic-form-value-control.model";
import {
    DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
    DynamicFormArrayGroupModel
} from "../model/form-array/dynamic-form-array.model";
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX } from "../model/checkbox/dynamic-checkbox.model";
import {
    DynamicFormControlLayout,
    DynamicFormControlLayoutContext,
    DynamicFormControlLayoutPlace
} from "../model/misc/dynamic-form-control-layout.model";
import { DynamicFormControl } from "./dynamic-form-control-interface";
import { DynamicTemplateDirective } from "../directive/dynamic-template.directive";
import { DynamicFormLayout, DynamicFormLayoutService } from "../service/dynamic-form-layout.service";
import { DynamicFormValidationService } from "../service/dynamic-form-validation.service";
import { DynamicFormComponentService } from "../service/dynamic-form-component.service";
import { isString } from "../utils/core.utils";
import { DynamicFormRelationService } from "../service/dynamic-form-relation.service";
import { DynamicFormGroupComponent } from "./dynamic-form-group.component";
import { DynamicFormArrayComponent } from "./dynamic-form-array.component";

export abstract class DynamicFormContainerComponent {

    protected _hasFocus = false;

    context: DynamicFormArrayGroupModel | null = null;
    group: FormGroup;
    hostClass: string[];
    klass: string;
    layout: DynamicFormLayout;
    model: DynamicFormControlModel;

    contentTemplateList: QueryList<DynamicTemplateDirective> | undefined;
    inputTemplateList: QueryList<DynamicTemplateDirective> | undefined;

    blur: EventEmitter<DynamicFormControlEvent>;
    change: EventEmitter<DynamicFormControlEvent>;
    customEvent: EventEmitter<DynamicFormControlEvent> | undefined;
    focus: EventEmitter<DynamicFormControlEvent>;

    componentViewContainerRef: ViewContainerRef;

    protected componentRef: ComponentRef<DynamicFormControl>;
    protected componentSubscriptions: Subscription[] = [];
    protected controlLayout: DynamicFormControlLayout;
    protected subscriptions: Subscription[] = [];

    protected constructor(protected changeDetectorRef: ChangeDetectorRef,
                          protected componentFactoryResolver: ComponentFactoryResolver,
                          protected layoutService: DynamicFormLayoutService,
                          protected validationService: DynamicFormValidationService,
                          protected componentService: DynamicFormComponentService,
                          protected relationService: DynamicFormRelationService) {
    }

    ngOnChanges(changes: SimpleChanges) {

        const layoutChange = (changes as Pick<SimpleChanges, "layout">).layout;
        const modelChange = (changes as Pick<SimpleChanges, "model">).model;

        if (layoutChange || modelChange) {
            this.onLayoutOrModelChange();
        }

        if (modelChange) {
            this.onModelChange();
        }

    }

    ngOnDestroy() {

        this.destroyFormControlComponent();
        this.unsubscribe();
    }

    abstract get componentType(): Type<DynamicFormControl> | null;

    get id(): string {
        return this.layoutService.getElementId(this.model);
    }

    get hasFocus(): boolean {
        return this._hasFocus;
    }

    get hasLabel(): boolean {
        return isString(this.model.label);
    }

    get hasHint(): boolean {
        return isString((this.model as DynamicFormValueControlModel<any>).hint);
    }

    get hint(): string | null {
        return (this.model as DynamicFormValueControlModel<any>).hint || null;
    }

    get isCheckbox(): boolean {
        return this.model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX;
    }

    get templates(): QueryList<DynamicTemplateDirective> | undefined {
        return this.inputTemplateList !== undefined ? this.inputTemplateList : this.contentTemplateList;
    }

    get startTemplate(): DynamicTemplateDirective | undefined {
        return this.model.type !== DYNAMIC_FORM_CONTROL_TYPE_ARRAY ?
            this.layoutService.getStartTemplate(this.model, this.templates) : undefined;
    }

    get endTemplate(): DynamicTemplateDirective | undefined {
        return this.model.type !== DYNAMIC_FORM_CONTROL_TYPE_ARRAY ?
            this.layoutService.getEndTemplate(this.model, this.templates) : undefined;
    }

    getClass(context: DynamicFormControlLayoutContext, place: DynamicFormControlLayoutPlace): string {
        return this.layoutService.getClass(this.controlLayout, context, place);
    }

    markForCheck(): void {

        this.changeDetectorRef.markForCheck();

        const component = this.componentRef.instance;

        if (component && (component instanceof DynamicFormGroupComponent || component instanceof DynamicFormArrayComponent)) {
            component.markForCheck();
        }
    }

    protected createFormControlComponent(): void {

        const componentType = this.componentType;

        if (componentType !== null) {

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

            this.componentViewContainerRef.clear();
            this.componentRef = this.componentViewContainerRef.createComponent(componentFactory);

            const component = this.componentRef.instance;

            component.formLayout = this.layout;
            component.group = this.group;
            component.layout = this.controlLayout;
            component.model = this.model;

            if (this.templates) {
                component.templates = this.templates;
            }

            this.registerFormControlComponentRef(this.componentRef);
        }
    }

    protected destroyFormControlComponent(): void {

        if (this.componentRef) {

            this.componentSubscriptions.forEach(subscription => subscription.unsubscribe());
            this.componentSubscriptions = [];

            this.unregisterFormControlComponentRef();
            this.componentRef.destroy();
        }
    }

    unsubscribe(): void {

        // this.componentSubscriptions.forEach(subscription => subscription.unsubscribe());
        // this.componentSubscriptions = [];

        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    onControlValueChanges(value: any): void {
        if (this.model instanceof DynamicFormValueControlModel && this.model.value !== value) {
            this.model.value = value;
        }
    }

    onLayoutOrModelChange(): void {
        this.controlLayout = this.layoutService.findByModel(this.model, this.layout) || this.model.layout as DynamicFormControlLayout;
        this.klass = `${Array.isArray(this.hostClass) ? this.hostClass.join(" ") : ""} ${this.layoutService.getHostClass(this.controlLayout)}`;
    }

    onModelChange(): void {
        this.destroyFormControlComponent();
        this.createFormControlComponent();
    }

    protected registerFormControlComponentRef(ref: ComponentRef<DynamicFormControl>): void {

        if (this.context instanceof DynamicFormArrayGroupModel) {

            this.componentService.registerFormControl(this.model, ref, this.context.index);

        } else {
            this.componentService.registerFormControl(this.model, ref);
        }
    }

    protected unregisterFormControlComponentRef(): void {

        if (this.context instanceof DynamicFormArrayGroupModel) {

            this.componentService.unregisterFormControl(this.model.id, this.context.index);

        } else {
            this.componentService.unregisterFormControl(this.model.id);
        }
    }
}
