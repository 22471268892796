import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TipoDeActividadService {

  constructor(private http: HttpClient) {}

  // Regresa el catálogo de Tipo de Actividad
  public taGet(cdgta: string): Observable<any> {
    return this.http.post('api/general/ta/get', {cdgta: cdgta}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
