import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogoInterface } from '@comp/object/general/empresa/logo.interface';
import { DatosEmpresaInterface } from '@comp/object/general/empresa/datosempresa.interface';
import { CoordinacionPRInterface } from '@comp/object/general/coordinacion/coordinacion_pr.interface';

@Injectable()
export class EmpresaService {
  public datosEM: any = new Object();
  public domicilio: any = new Object();

  constructor(private http: HttpClient) {}

  /**
   * Regresa el logo de la empresa en url y base 64
   */
  public logoGet(): Observable<LogoInterface> {
    return this.http.get('api/ahorros/empresa/logo', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
   * Regresa los datos de la empresa con la que se ingreso al sistema
   */
  public datosEmpresa(): Observable<DatosEmpresaInterface> {
    return this.http.get('api/ahorros/empresa/req', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa el código de la empresa
  public cdgEmpresa(): Observable<any> {
    return this.http.get('api/general/em/cdg/get', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Regresa los datos de la coordinación para los reportes de estado de cuenta
  public coordinacionPR(cdgcl: string, cdgclns: string, clns: string, ciclo: string): Observable<CoordinacionPRInterface> {
    return this.http.post('api/general/em/pr_get', {cdgcl: cdgcl, cdgclns: cdgclns, clns: clns, ciclo: ciclo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
  *  Metodo para obtener la infomarción de EM
  */

  public getEmpresa(): Observable<any[]> {
    return this.http.post("api/general/em/get", 
        {}).pipe(
        map(res => JSON.parse(JSON.stringify(res)) )
    )
  }

}
