import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { exit } from 'process';

@Injectable()
export class GeCatService {

  constructor(private http: HttpClient) {}

  /**
   * Regresa los Pwd de rccficoscore
   */
  public getPagos(jsonSC,jsonPPOT): Observable<any> {
    return this.http.post('api/general/gecat/get', {jsonSC: jsonSC, jsonPGV: {},jsonPPOT: jsonPPOT}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public edicion(jsonSC, jsonPGV,jsonPPOT): Observable<any> {
    return this.http.post('api/general/gecat/editar', {jsonSC: jsonSC, jsonPGV: jsonPGV,jsonPPOT: jsonPPOT}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }

  public roundD(x: number, d: number): number {
    let n: number, a: number, b: number, c: number;
    n = Math.pow(10, d);
    x = x * n;
    return (Math.floor(x) + Math.floor((x % 1) * 2)) / n;
  }

  public regresaValor(multper, valor){
    if(valor == 'M' && multper <=1){
        return 12;
    }else if(valor == 'M' && multper >1){
        return 12/multper;
    }

    if(valor == 'C' && multper <= 1){
        return 26;
    }else if(valor == 'C' && multper >1){
        return 26/multper;
    }
  }

  publicCAT(x: number, MontoT: number, pagos: number[], periodos: number, periodoa: string, multper: number): number {
    let rcat: number, cat2: number, total_optimo: number, delta: number;
    let i: number, p: number;
    let vPeriodoa: number;
  
    // Calculamos el X Optimo para  ahorrar iteraciones.
    total_optimo = 0;
    if (MontoT < 1) {
      return 0;
    }
    for (i = 0; i < periodos; i++) {
      total_optimo += pagos[i];
    }
  
    x = ((total_optimo * 100) / MontoT) / 2;
  
    if (periodoa === 'M') {
      vPeriodoa = this.regresaValor(multper, periodoa);
    } else if (periodoa === 'Q') {
      vPeriodoa = 24;
    } else if (periodoa === 'S') {
      vPeriodoa = 52;
    } else {
      vPeriodoa = this.regresaValor(multper, periodoa);
    }
  
    while (true) {
      rcat = 0;
      for (i = 0; i < periodos; i++) {
        p = i + 1;
        rcat += pagos[i] / Math.pow(1 + (x / 100), p / vPeriodoa);
      }
      cat2 = rcat - MontoT;
  
      if (Math.abs(cat2) <= 0.1) {
        return Math.round(x * 10) / 10;
      }
  
      if (cat2 <= 0) {
        x = x / 2;
      } else {
        x = x + (x / 2);
      }
      if (x === 0) {
        return 0;
      }
    }
  }

  TIR(x: number, MontoT: number, pagos: number[], periodos: number, periodoa: string, multper: number): number {
    let VAN: number, TIR2: number;
    let i: number;
    let vPeriodoa: number;
  
    TIR2 = 0;
    if (MontoT < 1) {
      return 0;
    }
  
    if (periodoa === 'M') {
      vPeriodoa = this.regresaValor(multper, periodoa);
    } else if (periodoa === 'Q') {
      vPeriodoa = 24;
    } else if (periodoa === 'S') {
      vPeriodoa = 52;
    } else {
      vPeriodoa = this.regresaValor(multper, periodoa);
    }
  
    while (true) {
      VAN = 0;
      for (i = 0; i < periodos; i++) {
        VAN += pagos[i] / Math.pow(1 + (x / 100), i + 1);
      }
      TIR2 = VAN - MontoT;
  
      if (Math.abs(TIR2) <= 0.0099) {
        return Math.round(x * 10000) / 10000 * vPeriodoa;
      }
  
      if (TIR2 <= 0) {
        x /= 2;
      } else {
        x += x / 2;
      }
  
      if (x === 0) {
        return 0;
      }
    }
  }



  public Regresa_Cat(x: number, MontoT: number, pagos: number[], periodos: number, periodoa: string, valor: string, multper:number, plazodia: number = 0, interes: number = 0, ComIniSinIva: number = 0): number {
    if (valor === 'C') {
        if (MontoT !== 0) {
            return this.cat2(x, MontoT, pagos, periodos, periodoa, multper, plazodia, interes, ComIniSinIva);
        }
    } else {
        if (MontoT !== 0) {
            return this.TIR2(x, MontoT, pagos, periodos, periodoa, multper);
        }
    }
    return 0;
}

  public  cat2(x: any, MontoT: number, pagos: number[], periodos: number, periodoa: string, multper: number, plazodia: number = 0, interes: number = 0, ComIniSinIva: number = 0): number {
    // NOTA: PAGOS DEBEN IR SIN IVA
    // Se obtiene el número de periodos en un año
    let vPeriodoa: number;
    if (periodoa === 'M') {
      vPeriodoa = this.regresaValor(multper, periodoa);
    } else if (periodoa === 'Q') {
      vPeriodoa = 24;
    } else if (periodoa === 'S') {
      vPeriodoa = 52;
    } else {
      vPeriodoa = this.regresaValor(multper, periodoa);
    }
    // Se establece el límite inferior
    let i0 = 0;
    // Se establece el límite superior
    let i1 = 7;
    if ((plazodia > 0 && plazodia >= 7) || (plazodia > 0 && plazodia < 7) || (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag > 0)) {
      // suma1 := suma(i0,pagos,periodos,MontoT); //CDM 26/01/2018 se cambió el cálculo del CAT para créditos de plazo en días CM35055
      let suma1 = pagos[0];
      if (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag > 0) {
        return this.roundD((Math.pow(((interes + suma1 + MontoT) / (MontoT - ComIniSinIva)), (360 / this.result[0].plazofinipag)) - 1) * 100, 1);
      } else {
        return this.roundD((Math.pow(((interes + suma1 + MontoT) / (MontoT - ComIniSinIva)), (360 / plazodia)) - 1) * 100, 1);
      }
    } else {
      var q = true;
      while (q = true) {
        // Se obtiene la suma para i0
        let suma1 = this.Suma(i0, pagos, periodos, MontoT);
        // Se obtiene la suma para i1
        let suma2 = this.Suma(i1, pagos, periodos, MontoT);
  
        if (Math.abs(suma1) < 0.01) {
          q = false;
          return this.roundD((Math.pow(1 + i0, vPeriodoa) - 1) * 100, 1);
        } else {
          if (Math.abs(suma2) < 0.01) {
            q= false;
            return this.roundD((Math.pow(1 + i1, vPeriodoa) - 1) * 100, 1);
          } else {
            // i0 e i1 han dado una suma mayor a 0.01 por lo que se obtiene un valor promedio entre i0 e i1
            let prom = (i0 + i1) / 2;
            // Se obtiene la suma con el promedio
            let sumaT = this.Suma(prom, pagos, periodos, MontoT);
            // Se verifica si la suma con el promedio es menor a 0.01
            if (Math.abs(sumaT) < 0.01) {
              q = false;
              return this.roundD((Math.pow(1 + prom, vPeriodoa) - 1) * 100, 1);
            }
  
            if (sumaT > 0) {
              i0 = prom;
            } else if (sumaT < 0) {
              i1 = prom;
            }
            if (prom === 0) {
              q= false;
              return 0;
            }
          }
        }
      }
    }
  }

  public Suma(i: number, pagos: number[], periodos: number, MontoT: number): number {
    let sumaP: number = 0;

    sumaP = (MontoT * -1) / (Math.pow(1 + i, 0));
    for (let k = 0; k < periodos; k++) {
      sumaP += pagos[k] / (Math.pow(1 + i, k + 1));
    }
    return sumaP;
  }

  public TIR2(x: number, MontoT: number, pagos: number[], periodos: number, periodoa: string, multper: number): number {
    let vPeriodoa;
    let i0 = 0; // JPEM Lìmite inferior
    let i1 = 7; // JPEM Limite superior
    let prom: number;
    let suma1: number;
    let suma2: number;
    let sumaT: number;
  
    if (MontoT < 1) {
      return 0;
    }
  
    if (periodoa === 'M') {
      vPeriodoa = this.regresaValor(multper, periodoa);
    } else if (periodoa === 'Q') {
      vPeriodoa = 24;
    } else if (periodoa === 'S') {
      vPeriodoa = 52;
    } else {
      vPeriodoa = this.regresaValor(multper, periodoa);
    }
      var q = true;
    while ( q == true) {
      // Càlculo del TIR
      suma1 = this.Suma(i0, pagos, periodos, MontoT);
      suma2 = this.Suma(i1, pagos, periodos, MontoT);
      
      if (Math.abs(suma1) < 0.01) {
        q = false;

        return this.roundD(i0 * 100, 3);
        
      } else {
        if (Math.abs(suma2) < 0.01) {
          q = false;
          return this.roundD(i1 * 100, 3);
        
        } else {
          prom = (i0 + i1) / 2;
          sumaT = this.Suma(prom, pagos, periodos, MontoT);
  
          if (Math.abs(sumaT) < 0.01) {
            q= false;
            return this.roundD(prom * 100, 3);
          }
  
          if (sumaT > 0) {
            i0 = prom;
          } else if (sumaT < 0) {
            i1 = prom;
          }
  
          if (prom === 0) {
            q = false;
            return 0;
          }
        }
      }
    }
  }
  public result=[];
  public async actualiza_cat (pCdgclns, pCdgcl, pClns, pCiclo, pParametro, pSolicitud){ 

   if(pParametro == 'P'){
    if(pClns == 'G' && pCdgcl == ''){
      await this.obtenerDatos(pCdgclns, pCdgcl, pClns, pCiclo, 'P', '');
    }
    if(pClns == 'I'){
     await this.obtenerDatos('', pCdgcl, pClns, pCiclo, 'P', '');
    }

    if(pClns == 'G' && pCdgcl !== ''){
      await this.obtenerDatos(pCdgclns, pCdgcl, pClns, pCiclo, 'P', '');
    }
  }else if(pParametro == 'S'){
    if(pClns == 'G' && pCdgcl == ''){
      await this.obtenerDatos(pCdgclns, pCdgcl, pClns, pCiclo, 'S', pSolicitud);
    }
    if(pClns == 'I'){
     await this.obtenerDatos('', pCdgcl, pClns, pCiclo, 'S', pSolicitud);
    }

    if(pClns == 'G' && pCdgcl !== ''){
      await this.obtenerDatos(pCdgclns, pCdgcl, pClns, pCiclo, 'S', pSolicitud);
    }
  }
}


  public async obtenerDatos(pCdgclns, pCdgcl, pClns, pCiclo, pParametro, pSolicitud){
    var CAT, TIR, aPagarInt, acumInt;
    var pagos = [];
    var j, Lista, vINTERES, wPlazo, wTotalReg; 

    this.result = [];
    var jsonSC = {'S_CICLO':pCiclo, 'S_CDGCL':pCdgcl, 'S_CLNS':pClns, 'D_SOLICITUD':pSolicitud};
      //var jsonPGV = {'S_CODIGO_EM': ''};
      var jsonPPOT = {"S_NUEVOCDGCLNS":pCdgclns,"S_FIL01":pParametro};

      this.getPagos(jsonSC, jsonPPOT).toPromise().then(async (result: any)=> {
        if(result){

          this.result = result.array;
          if(pParametro == 'P'){
            if(pClns == 'G' && pCdgcl == ''){
              let j: number = 0;
             
              while (this.result.length) {
                if (j !== this.result.length - 1) {
                  pagos[j] = this.result[j].pagos;
                  j++;
                 // pagos.length = j + 1;
                } else {
                  pagos[j] = this.result[j].pagos +this.result[j].garantia;
                  j++;
                 // pagos.length = j + 1;
                }
              }

              j = 0;
              pagos = [];
              let wTotalReg: number = 1;
              while (!this.result.length) {
                if (j !== this.result.length - 1) {
                  pagos[j] = this.result[j].pagos;
                  j++;
                //  pagos.length = j + 1;
                  wTotalReg++;
                  ;
                } else {
                  pagos[j] = this.result[j].pagos + this.result[j].garantia;
                  j++;
                //  pagos.length = j + 1;
                  wTotalReg++;
                  ;
                }
              }

              TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'T',this.result[0].multer); 
              CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'C',this.result[0].multer);
              await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);
            }
        
            if(pClns == 'I'){
              wPlazo = this.result[0].plazo;
                wTotalReg = 1;
                j=0;

                while(wPlazo >= wTotalReg){
                  if (!(j === this.result.length - 1)) {
                    if (((this.result[0].plazodia > 7) && (this.result[0].interes === 0)) || 
                        ((this.result[0].finipag !== null) && (this.result[0].plazofinipag < 7) && (this.result[0].plazofinipag > 0))) {
                      pagos[j] = this.result[j].pagos;
                      j = j + 1;
                     // pagos.length = j + 1;
                      wTotalReg = wTotalReg + 1;
                    } else {
                      if (((wPlazo - this.result[0].duracion) > 0)) {
                        if (wTotalReg < wPlazo) {
                          pagos[j] = 0;
                        } else {
                          pagos[j] = this.result[j].pagos;
                        }
                      } else {
                        pagos[j] = this.result[j].pagos;
                      }
                      wTotalReg = wTotalReg + 1;
                      j = j + 1;
                     // pagos.length = j + 1;
                    }

                  }else{
                    if (this.result[0].plazodia > 7 || 
                      (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag.value > 0)) {
                      pagos[j] = 0;
                      j++;
                     // pagos.length = j + 1;
                      wTotalReg++;
                    } else {
                        if ((wPlazo - this.result[0].duracion) > 0) {
                            if (wTotalReg < wPlazo) {
                                pagos[j] = 0;
                            } else {
                                pagos[j] = this.result[j].pagos + this.result[j].garantia;
                            }
                        } else {
                            pagos[j] = this.result[j].pagos + this.result[j].garantia;
                        }
                        wTotalReg++;
                        j++;
                      //  pagos.length = j + 1;
                    }
                  }                
                  
                }
                let vINTERES: number = 0;
                  if( (this.result[0].plazodia > 7) || 
                    ((this.result[0].finipag !== null) && (this.result[0].plazofinipag < 7))) {                     
                    for (let i = 0; i < this.result.length; i++) {        
                        vINTERES += this.result[i].intsiniva;
                    }
                }
             //   x: number, MontoT: , pagos: number[], periodos: , periodoa: , valor: , multper: , plazodia: number = 0, interes: number = 0, ComIniSinIva: number = 0): number {
                TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,wPlazo,this.result[0].periodicidad,'T',this.result[0].multer); 
                CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,wPlazo,this.result[0].periodicidad,'C',this.result[0].multer,this.result[0].plazodia, vINTERES, this.result[0].cominisiniva);
                
             await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);
  
                //this.edicionGetCat(pCdgclns, pCdgcl, pClns, pCiclo, pParametro, pSolicitud, TIR, CAT);
           }

           if(pClns == 'G' && pCdgcl !== ''){
            let j: number = 0;
            let acumInt: number = 0;

            while (!this.result.length) {
              if (j !== this.result.length - 1) {
                pagos[j] = this.result[j].pagos;
                j++;
                //pagos.length = j + 1; // Resize the pagos array
                
              } else {
                pagos[j] = this.result[j].pagos + this.result[j].garantia;
                j++;
                //pagos.length = j + 1; // Resize the pagos array
                
              }
            }

            TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'T',this.result[0].multer); 
            CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'C',this.result[0].multer);
            await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);
           }
          }else if(pParametro == 'S'){

            if(pClns == 'G' && pCiclo == ''){
              let j: number = 0;
              let acumInt: number = 0;
            
              while (!this.result.length) {
                if (j !== this.result.length - 1) {
                  pagos[j] = this.result[j].pagos;
                  j++;
                 // pagos.length = j + 1; // Resize the pagos array
                 
                } else {
                  pagos[j] =  this.result[j].pagos + this.result[j].garantia;
                  j++;
                 // pagos.length = j + 1; // Resize the pagos array
                 
                }
              }
            
              TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'T',this.result[0].multer); 
              CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'C',this.result[0].multer);
              await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);                          
            }

            if(pClns == 'I'){
              let j: number = 0;
              wPlazo = this.result[0].plazo;
              wTotalReg = 1;

              while (wPlazo >= wTotalReg) {
                if (j !== this.result.length - 1) {
                  if ((this.result[0].plazodia > 7 && this.result[0].interes === 0) || 
                      (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag > 0)) { 
                    pagos[j] = this.result[j].pagos;
                    j++;
                    //pagos.length = j + 1;
                    wTotalReg++;
                  } else { 
                    if ((wPlazo - this.result[0].duracion) > 0) {
                      if (wTotalReg < wPlazo) {
                        pagos[j] = 0;
                      } else {
                        pagos[j] = this.result[j].pagos;
                      }
                    } else {
                      pagos[j] = this.result[j].pagos;
                    }
                    wTotalReg++;
                    j++;
                    //pagos.length = j + 1;
                  }
                } else {
                  if (this.result[0].plazodia > 7 || 
                  (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag > 0)) { 
                  pagos[j] = 0;
                  j++;
                  //pagos.length = j + 1;
                  wTotalReg++;
              } else { 
                  
                  if ((wPlazo - this.result[0].duracion) > 0) {
                      if (wTotalReg < wPlazo) {
                          pagos[j] = 0;
                      } else {
                          pagos[j] = this.result[j].pagos + this.result[j].garantia;
                      }
                    } else {
                        pagos[j] = this.result[j].pagos + this.result[j].garantia;
                    }
                    wTotalReg++;
                    j++;
                   // pagos.length = j + 1;
                  }
                }
              }

              if (this.result[0].plazodia > 7 || 
                  (this.result[0].finipag !== null && this.result[0].plazofinipag < 7 && this.result[0].plazofinipag > 0)) { 
  
                    for (let i = 0; i < this.result.length; i++) {        
                      vINTERES += this.result[i].intsiniva;
                  }
              }

            //  pagos.length = j;

              // CALCULO DE CAT
              TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,wPlazo,this.result[0].periodicidad,'T',this.result[0].multer); 
                CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,wPlazo,this.result[0].periodicidad,'C',this.result[0].multer,this.result[0].plazodia, vINTERES, this.result[0].cominisiniva);

                await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);
            }

            if(pClns == 'G' && pCdgcl !== ''){
              while (!this.result.length) {
                if (j !== this.result.length - 1) {
                  pagos[j] = this.result[j].pagos;
                  j++;
                 // pagos.length = j + 1;
                  
                } else {
                  pagos[j] = this.result[j].pagos + this.result[0].garantia;
                  j++;
                  pagos.length = j + 1;
                  
                }
              }

              TIR = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'T',this.result[0].multer); 
              CAT = this.Regresa_Cat(200,this.result[0].cantautor,pagos,this.result[0].duracion,this.result[0].periodicidad,'C',this.result[0].multer);
              await   this.edicionGetCat(pCdgclns, pCdgcl, pClns,pCiclo, pParametro, pSolicitud, CAT, TIR);
            
            }
          }
        }
          
        }, function(err){
            this.loading = false;
            this.dialogRef.close();
            console.log('Hubo un error con la peticion: ' + err);
          });
  }


  
  public async edicionGetCat(pCdgclns, pCdgcl, pClns, pCiclo, pParametro, pSolicitud, cat_bm: Number, cat_tir: Number){
    var jsonSC = {"S_CICLO":pCiclo, "S_CDGCL":pCdgcl, "S_CLNS":pClns, "D_SOLICITUD":pSolicitud, "N_CAT_TIR":cat_tir, "N_CAT_BM":cat_bm}  //"N_CAT_TIR":93.52, "N_CAT_BM":152.6}
    var jsonPGV = {"S_CODIGO_EM":""}
    var jsonPPOT= {"S_NUEVOCDGCLNS":pCdgclns,"S_FIL01":pParametro}
    this.edicion(jsonSC, jsonPGV,jsonPPOT).toPromise().then((result: any)=> {
     
        
      }, function(err){
          this.loading = false;
          this.dialogRef.close();
          console.log('Hubo un error con la peticion: ' + err);
        });
  }
}