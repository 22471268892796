import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SolicitudDeCreditoService {

  constructor(private http: HttpClient) {}

  // Inserta solicitud de crédito del cliente
  public scPost(scBody: any): Observable<any> {
    return this.http.post('api/general/sc/post', scBody).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
