import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayTipoProductoCredito } from "@comp/object/control-cartera/operacion/prestamos/tipo-producto-credito/arraytiposproductocredito.interface";


@Injectable()
export class TipoProductoCreditoService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getTipoProductoCredito(): Observable<ArrayTipoProductoCredito> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/tipo-producto-credito/consultar', {}

        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}