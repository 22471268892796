import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';


@Injectable()
export class CalenMontosUsualmenteAltosService {

    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getCMUs(consultaCMUJson: string): Observable<String> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/cmu/consultar', { consultaCMUJson: consultaCMUJson }
        
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    insertarCMU(insercionCMUJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/cmu/insertar', { insercionCMUJson: insercionCMUJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    eliminarSolicitud(eliminarCMUJson: string): Observable<string> {
        return this.http.post('api/control-cartera/operacion/prestamos/individual/cmu/eliminar', { eliminarCMUJson: eliminarCMUJson }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }



}