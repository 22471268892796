import { Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LabelOptions, MAT_LABEL_GLOBAL_OPTIONS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { DynamicFormControlComponent } from "@comp/dynamic-form/core/component/dynamic-form-control.component";
import { DynamicFormLayout, DynamicFormLayoutService } from "@comp/dynamic-form/core/service/dynamic-form-layout.service";
import { DynamicFormControlLayout } from "@comp/dynamic-form/core/model/misc/dynamic-form-control-layout.model";
import { DynamicDatePickerModel } from "@comp/dynamic-form/core/model/datepicker/dynamic-datepicker.model";
import { DynamicFormControlCustomEvent } from "@comp/dynamic-form/core/component/dynamic-form-control-event";
import { DynamicFormValidationService } from "@comp/dynamic-form/core/service/dynamic-form-validation.service";

@Component({
    selector: "dynamic-material-datepicker",
    templateUrl: "./dynamic-material-datepicker.component.html"
})
export class DynamicMaterialDatePickerComponent extends DynamicFormControlComponent {

    @Input() formLayout: DynamicFormLayout;
    @Input() group: FormGroup;
    @Input() layout: DynamicFormControlLayout;
    @Input() model: DynamicDatePickerModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matDatepicker") matDatePicker: MatDatepicker<any>;
    @ViewChild(MatInput) matInput: MatInput;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions) {

        super(layoutService, validationService);
    }
}
