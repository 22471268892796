import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValorInterface} from '@comp/object/general/bitacora/valor.interface';

@Injectable()
export class BitacoraService {

  constructor(private http: HttpClient) {}

  /**
  * Funcion que realiza el registro del usuario en la bitacora Yunius Web
  */
    public setBitacora(version: string, cdgpe: string, nomcomp: string, cdgmod: string): Observable<ValorInterface> {
      return this.http.post('api/general/bitacora/set', { "version": version, "cdgpe": cdgpe, "nomcomp": nomcomp, "cdgmod": cdgmod }
          ).pipe(
          map(res => JSON.parse(JSON.stringify(res)) )
      );
  }

}
