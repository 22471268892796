import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DynamicCheckboxModel } from '@comp/dynamic-form/core/model/checkbox/dynamic-checkbox.model';
import { DynamicRadioGroupModel } from '@comp/dynamic-form/core/model/radio/dynamic-radio-group.model';
import { Injectable } from '@angular/core';
import { DynamicFormModel } from '@comp/dynamic-form/core/model/dynamic-form.model';
import { DynamicInputModel } from '@comp/dynamic-form/core/model/input/dynamic-input.model';
import { DynamicFormCustomControlModel } from '@comp/dynamic-form/core/model/custom/dynamic-custom.model';
import { DynamicSelectModel } from '@comp/dynamic-form/core/model/select/dynamic-select.model';
import { DynamicDivGroupModel } from '@comp/dynamic-form/core/model/div/dynamic-div-group.model';
import { DynamicDatePickerModel } from '@comp/dynamic-form/core/model/datepicker/dynamic-datepicker.model';
import { DynamicHiddenModel } from '@comp/dynamic-form/core/model/hidden/dynamic-hidden.model';
import { MatTableDataSource } from '@angular/material/table';
import { EstiloColumnas } from '@comp/reporte/pdf/reporte-pdf.service';

@Injectable()
export class AmortizacionSinSolicitudService {

    public result: TASS;
    public request: any;    
    protected extraInfo: any;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: member-ordering
    public static DIA_JUNTA_SEL = new DynamicRadioGroupModel<string>({

        id: 'diajunta',
        label: 'Día de junta/pago',
        value: '1',
        options: [
            {
                label: 'Domingo',
                value: '0'
            },
            {
                label: 'Lunes',
                value: '1'
            },
            {
                label: 'Martes',
                value: '2'
            },
            {
                label: 'Miércoles',
                value: '3'
            },
            {
                label: 'Jueves',
                value: '4'
            },
            {
                label: 'Viernes',
                value: '5'
            },
            {
                label: 'Sábado',
                value: '6'
            }
        ]
    });

    // tslint:disable-next-line: member-ordering
    public static DIA_JUNTA_INP_Q = new DynamicInputModel({

        id: 'diajunta',
        label: 'Día de la quincena',
        inputType: 'number',
        hint: '[1-15]',
        value: 1,
        min: 1,
        max: 15
    });

    // tslint:disable-next-line: member-ordering
    public static DIA_JUNTA_INP_M = new DynamicInputModel({

        id: 'diajunta',
        label: 'Día de mes',
        inputType: 'number',
        hint: '[1-30]',
        value: 1,
        min: 1,
        max: 30
    });

    // tslint:disable-next-line: member-ordering
    public static DIAS = new DynamicInputModel({

        id: 'plazodia',
        label: 'Número de días',
        inputType: 'number',
        value: 7,
        min: 7
    });

    // tslint:disable-next-line: member-ordering
    public static UTIL_DIA = new DynamicCheckboxModel({

        id: 'ultimodia',
        label: 'Utilizar el último día',
        disabled: true
    });

    // TODO: get from a remote source of question metadata
    getElementos() {
        const flg16 = {
            def: '16px',
            md: '0px'
        };
        const fecha = new Date();
        fecha.setDate(fecha.getDate() + 1);
        const elementos: DynamicFormModel = [

            new DynamicDivGroupModel({
                id: 'root',
                group: [

                    new DynamicFormCustomControlModel({
                        id: 'titulo1'
                    }),

                    new DynamicDivGroupModel({
                        id: 'user-data',
                        group: [

                            new DynamicInputModel({
                                id: 'cdgclns',
                                label: 'Código',
                                placeholder: '000001',
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'El código es necesario'
                                },
                                mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                            }),

                            new DynamicInputModel({
                                id: 'nombre',
                                label: 'Nombre',
                                placeholder: 'Nombre del cliente',
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'El nombre es necesario'
                                },
                                flex : '500px',
                             })
                        ],
                        fxLayoutGap: flg16,
                        fxLayoutAling: {
                            def: 'start stretch',
                            md: 'start stretch'
                        }
                    }),

                    new DynamicFormCustomControlModel({
                        id: 'titulo2'
                    }),

                    new DynamicDivGroupModel({
                        id: 'prod',
                        group: [

                            new DynamicSelectModel<string>({

                                id: 'cdgtpc',
                                placeholder: 'Tipo de Producto',
                                options: []
                            }),

                            new DynamicSelectModel<string>({

                                id: 'cdgpcr',
                                placeholder: 'Producto de Crédito',
                                options: []
                            })
                        ],
                        fxLayoutGap: flg16
                    }),

                    new DynamicDivGroupModel({
                        id: 'fechuno',
                        group: [

                            new DynamicDatePickerModel({
                                id: 'fechainicio',
                                label: 'Inicio',
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'La fecha es necesaria'
                                },
                                value: new Date(),
                                min: new Date()
                            }),

                            new DynamicInputModel({
                                id: 'cantentre',
                                label: 'Cantidad a prestar',
                                inputType: 'number',         
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'La cantidad es necesaria'
                                },
                                prefix: '$&nbsp;',
                                min: 0
                            })

                        ]
                    }),

                    new DynamicDivGroupModel({
                        id: 'fechdos',
                        group: [

                            new DynamicDatePickerModel({
                                id: 'fecinipag',
                                label: 'Fecha de Inicio de Pago',
                                min: fecha,
                                validators: {
                                    min: null
                                },
                                errorMessages: {
                                    min: 'Seleccione una fecha posterior a la de inicio.'
                                }
                            }),

                            new DynamicInputModel({
                                id: 'montopr',
                                label: 'Monto de la Parcialidad',
                                inputType: 'number',
                                prefix: '$&nbsp;',
                                min: 0,
                                disabled: true
                            })
                        ]
                    }),

                    new DynamicDivGroupModel({
                        id: 'mult',
                        group: [

                            new DynamicInputModel({
                                id: 'multper',
                                label: 'Múltiplo de la periodicidad',
                                inputType: 'number',
                                validators: {
                                    required: null
                                },
                                readOnly: true,
                                value: 1,
                                errorMessages: {
                                    required: 'La cantidad es necesaria'
                                },
                                min: 1,
                                max: 99
                            }),

                            new DynamicRadioGroupModel<string>({

                                id: 'periodicidad',
                                label: 'Periodicidad del préstamo',
                                options: [
                                    {
                                        label: 'Semanal',
                                        value: 'S'
                                    },
                                    {
                                        label: 'Catorcenal',
                                        value: 'C'
                                    },
                                    {
                                        label: 'Quincenal',
                                        value: 'Q'
                                    },
                                    {
                                        label: 'Mensual',
                                        value: 'M'
                                    },
                                    {
                                        label: 'Al vencimiento',
                                        value: 'A'
                                    }
                                ]
                            }),

                            AmortizacionSinSolicitudService.DIA_JUNTA_SEL
                        ],
                        fxLayoutGap: flg16
                    }),

                    new DynamicDivGroupModel({
                        id: 'met',
                        group: [

                            new DynamicSelectModel<string>({

                                id: 'cdgmci',
                                placeholder: 'Método de cobro de interes',
                                options: []
                            }),

                            new DynamicInputModel({

                                id: 'tasa',
                                label: 'Interés',
                                inputType: 'number',
                                placeholder: '0.00',
                                suffix: '%',
                                min: 0,
                                validators: {
                                    required: null
                                },
                                errorMessages: {
                                    required: 'La tasa es necesaria'
                                },

                            }),

                            new DynamicInputModel({

                                id: 'descuento',
                                label: 'Descuento',
                                inputType: 'number',
                                min: 0,
                                disabled: true
                            })

                        ]
                    }),

                    new DynamicDivGroupModel({
                        id: 'tasa_sec',
                        label: '',
                        group: [

                            new DynamicCheckboxModel({

                                id: 'imprimir',
                                label: 'Imprimir',
                                value: true
                            }),

                            new DynamicRadioGroupModel<string>({
                                id: 'tasa-tiempo',
                                label: 'Especificar tasa',
                                value: 'M',
                                options: [
                                    {
                                        label: 'Semanal',
                                        value: 'S'
                                    },
                                    {
                                        label: 'Mensual',
                                        value: 'M'
                                    },
                                    {
                                        label: 'Anual',
                                        value: 'A'
                                    }
                                ]
                            }),

                            new DynamicDivGroupModel({

                                id: 'tasa-div',
                                group: [
                                    new DynamicCheckboxModel({

                                        id: 'redondea',
                                        label: 'Redondear',
                                        value: false
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'iva',
                                        label: 'Mostrar IVA',
                                        value: true
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'opccomision',
                                        label: 'Incluir comisiones opcionales',
                                        value: true
                                    })
                                ],
                                fxLayoutAling: {
                                    def: 'start stretch',
                                    md: 'start stretch'
                                },
                                fxLayout: {
                                    def: 'column',
                                    md: 'column'
                                }
                            })

                        ],
                        fxLayoutGap: flg16
                    }),

                    new DynamicDivGroupModel({
                        id: 'tasa_sec_dos',
                        group: [

                            new DynamicInputModel({

                                id: 'plazo',
                                label: 'Plazo',
                                inputType: 'number',
                                min: 0
                            }),

                            new DynamicRadioGroupModel<string>({

                                id: 'calendar',
                                label: 'Calendario',
                                value: 'T',
                                options: [
                                    {
                                        label: 'Meses de 30 días',
                                        value: 'T'
                                    },
                                    {
                                        label: 'Real',
                                        value: 'R'
                                    }
                                ]
                            }),

                            new DynamicRadioGroupModel<string>({

                                id: 't_int',
                                label: 'Tasa de interés',
                                value: 'c_iva',
                                options: [
                                    {
                                        label: 'Con IVA',
                                        value: 'c_iva'
                                    },
                                    {
                                        label: 'Sin IVA',
                                        value: 's_iva'
                                    }
                                ]
                            })
                        ]
                    }),

                    new DynamicFormCustomControlModel({
                        id: 'titulo3'
                    }),

                    new DynamicDivGroupModel({
                        id: 'periodo',
                        group: [

                            new DynamicInputModel({

                                id: 'periograciacap',
                                label: 'Para pago de capital',
                                inputType: 'number',
                                value: 0,
                                min: 0
                            }),

                            new DynamicInputModel({

                                id: 'periograciaint',
                                label: 'Para pago de interés',
                                inputType: 'number',
                                value: 0,
                                min: 0
                            }),

                            new DynamicDivGroupModel({

                                id: 'pagos-check',
                                group: [
                                    new DynamicCheckboxModel({

                                        id: 'cobperigrint',
                                        label: 'Cobrar el interés del periodo de gracia'
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'cobpericero',
                                        label: 'Cobrar interés transcurrido entre día de inicio y día de pago'
                                    }),
                                    new DynamicCheckboxModel({

                                        id: 'periprimpago',
                                        label: 'Primer pago a partir de periodo inmediato',
                                        disabled: true
                                    }),
                                ],
                                fxLayoutAling: {
                                    def: 'start stretch',
                                    md: 'start stretch'
                                },
                                fxLayout: {
                                    def: 'column',
                                    md: 'column'
                                }
                            }),

                        ]
                    })
                ],
                fxLayout: {
                    def: 'column',
                    md: ''
                },
                fxLayoutGap: flg16
            }),

            new DynamicHiddenModel({
                id: 'version',
                value: '0'
            }),

            new DynamicHiddenModel({
                id: 'ciclo',
                value: '01'
            }),

            new DynamicHiddenModel({
                id: 'aplicarec',
                value: '2'
            }),

            new DynamicHiddenModel({
                id: 'cdgti',
                value: 'GLOB'
            }),

            new DynamicHiddenModel({
                id: 'cdgfdi',
                value: '11'
            })
        ];

        return elementos;
    }

    getLayout() {
        const w100 = {

            grid: {
                control: 'width100'
            }
        };
        const flexdcol = {
            grid: {
                control: 'flexdcol'
            }
        };
        const flexdw100 = {
            grid: {
                control: 'width100',
                children: 'flex'
            }
        };
        return {
            root: {
                grid: {
                    control: 'width100',
                    children: 'width100'
                }
            },
            'user-data': {
                grid: {
                    control: 'width100',
                    childrennombre: 'flex'
                }
            },
            cdgclns: w100,
            nombre: w100,
            prod: flexdw100,
            cdgtpc: w100,
            cdgpcr: w100,
            sec_dos: w100,
            met: w100,
            tasa_sec: {
                grid: {
                    control: 'width100',
                    container: 'seccion',
                    children: 'width100'
                }
            },
            tasa_sec_int: w100,
            periodicidad: flexdcol,
            diajunta: flexdcol,
            calendar: flexdcol,
            t_int: flexdcol,
            'tasa-tiempo': flexdcol
        };
    }

    public getResult(data: any) {
        this.request = data;
        return this.http.post('api/control-cartera/reportes/amortizacionsinsolicitud/buscar', data).pipe(map((val: TASS) => {
            this.result = val;
            return val;
        }));
    }

    public getEncData() {
        const result = this.result;
        const data = [];
        const data1 = [];
        data1.push({
            titulo: 'Acreditado:',
            valor: result.codigo + ' ' + result.nombre,
        });
        data1.push({
            titulo: 'Ciclo:',
            valor: result.ciclo
        });
        data1.push({
            titulo: result.tatinic,
            valor: result.fechainicio,
        });
        data1.push({
            titulo: 'Fecha fin',
            valor: result.amortizacionArray[result.amortizacionArray.length -1].vencimiento,
        });
        data1.push({
            titulo: 'Crédito:',
            valor: this.numero(result.montopr).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        });
        const sp = (this.request.nmmci as string).split(' ');
        if (sp.length > 2) {
            data1.push({
                titulo: 'Método de cálculo de interés:',
                valor: result.cdgmci + ' ' + sp[0] + ' ' + sp[1],
            });
            let tex = '';
            for (let i = 2; i < sp.length; i++) {
                tex += sp[i] + ' ';
            }
            data1.push({
                titulo: '',
                valor: tex,
            });
        } else {
            data1.push({
                titulo: 'Método de cálculo de interés:',
                valor: result.cdgmci + ' ' + this.request.nmmci,
            });
        }
        data.push(data1);
        const data2 = [];
        if (result.periodicidad === 'A') {
            data2.push({
                titulo: 'Plazo:',
                valor: result.plazodia,
            });
            data2.push({
                titulo: 'Número de días:',
                valor: result.plazodia,
            });
        } else {
            data2.push({
                titulo: 'Plazo:',
                valor: result.plazo,
            });
        }
        data2.push({
            titulo: 'Periodicidad:',
            valor: this.getPeriodicidad()
        });
        if(this.extraInfo.tapergrc == 'S'){
            data2.push({
                titulo: 'Periodos de gracia al capital:',
                valor: result.periograciacap + ' ' + this.getPeriodicidadT(result.periograciacap)
            });
        } 
        if(this.extraInfo.tapergri == 'S'){
            data2.push({
                titulo: 'Periodo de gracia al interés:',
                valor: result.periograciaint + ' ' + this.getPeriodicidadT(result.periograciaint)
            });
        }  
        const proc = (this.request.nmpcr as string).split(' ');
        if (proc.length > 2) {
            data2.push({
                titulo: 'Producto de crédito:',
                valor:  proc[0] + ' ' + proc[1],
            });
            let tex = '';
            for (let i = 2; i < proc.length; i++) {
                tex += proc[i] + ' ';
            }
            data2.push({
                titulo: '',
                valor: tex,
            });
        } else {
            data2.push({
                titulo: 'Producto de crédito:',
                valor: this.request.nmpcr,
            });
        }
       /* data2.push({
            titulo: 'Producto de crédito',
            valor: this.request.nmpcr
        });*/
        data.push(data2);
        const data3 = [];
        if (this.request.imprimir == 'S') {
            const nombTasa = [];
            let valTasa = '';
            if (this.request.t_int === 'c_iva') {
                nombTasa.push('Tasa de interés', 'ordinario con IVA:');
                if (this.request['tasa-tiempo'] === 'S') {
                    valTasa = (this.request.tasa / 4).toFixed(2) + '% semanal';
                } else if (this.request['tasa-tiempo'] === 'M') {
                    valTasa = this.request.tasa.toFixed(2) + '% mensual';
                } else {
                    valTasa =  (this.request.tasa * 12).toFixed(2) + '% anual';
                }
            } else {
                nombTasa.push('Tasa de interés', 'ordinario sin IVA:');
                if (this.request['tasa-tiempo'] === 'S') {

                    valTasa = (result.tasasiniva / 4).toFixed(2) + '% semanal';
                } else if (this.request['tasa-tiempo'] === 'M') {
                    valTasa = result.tasasiniva.toFixed(2) + '% mensual';
                } else {
                    valTasa = (result.tasasiniva * 12).toFixed(2)  + '% anual';
                }
            }
            data3.push({
                titulo: nombTasa[0],
                valor: '',
            });
            data3.push({
                titulo: nombTasa[1],
                valor: valTasa,
            });
        }
        data3.push({
            titulo: 'Seguro de vida:',
            valor: result.comsegin,
        });
        data3.push({
            titulo: 'Comición inicial:',
            valor: result.comini,
        });
        data.push(data3);
        return data;
    }

    public getDysplayColumns() {
        return ['periodo', 'vencimiento', 'parcialidadcap', 'parcialidadint', 'parcialidadcom', 'seguro', 'saldocap', 'cantidadtotal'];
    }

    public getTituloColumns() {
        return ['No. Periodo',
                this.result.tatifven,
                this.result.taticap,
                this.result.tatiin,
                'Comisión',
                'Seguro',
                this.result.tatiscap,
                this.result.tatiparc];
    }

    public getStyleColumns() {
        return {
            0: { halign: 'center' },
            1: { halign: 'center' },
            2: { halign: 'right' },
            3: { halign: 'right' },
            4: { halign: 'right' },
            5: { halign: 'right' },
            6: { halign: 'right' },
            7: { halign: 'right' }
        };
    }

    public getDataSource() {
        const result = this.result;
        const data = [];
        let comisionTotal = 0;
        let total = 0;
        let intTotal = 0;
        let seguro = 0;
        const temporal: ItemTASS[] = [];
        result.amortizacionArray.forEach(val => {
            const clone = { ...val };
            comisionTotal += +val.parcialidadcom;
            total += +val.parcialidadcap + +val.parcialidadint + +val.parcialidadcom;
            intTotal += +val.parcialidadint;
            seguro += +val.parcialidadseg;

            clone.parcialidadcap = this.numero(val.parcialidadcap).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            clone.parcialidadint = this.numero(val.parcialidadint).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            clone.parcialidadcom = this.numero(val.parcialidadcom).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            clone.parcialidadseg = this.numero(val.parcialidadseg).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            clone.saldocap = this.numero(val.saldocap).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (this.request.redondea == 'S') {
                // val.cantidadtotal = this.numero(val.cantidadtotal).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            }
            temporal.push(clone);
        });
        const comisionTotal1 = this.numero(comisionTotal).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        const total1 = this.numero(total).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        const intTotal1 = this.numero(intTotal).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        const seguro1 = this.numero(seguro).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        const parcialiad = this.numero(result.montopr).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        temporal.forEach((val, index) => {
            const tem = {
                periodo: val.periodo,
                vencimiento: val.vencimiento,
                parcialidadcap: val.parcialidadcap,
                parcialidadint: val.parcialidadint,
                parcialidadcom: val.parcialidadcom,
                parcialidadseg: val.parcialidadseg,
                saldocap: val.saldocap,
                cantidadtotal: +result.amortizacionArray[index].parcialidadcap +
                               +result.amortizacionArray[index].parcialidadint +
                               +result.amortizacionArray[index].parcialidadcom
            } as { [key: string]: any };
            if (this.request.iva == 'S') {
                if (this.request.redondea == 'S') {
                    tem.cantidadtotal = this.numero(Math.ceil(tem.cantidadtotal)).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: this.numero(val.ivainteres).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        parcialidadcom: this.numero(val.ivacomision).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        parcialidadseg: this.numero(val.ivaseguro).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        saldocap: this.numero(val.ivainteres).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);

                } else {
                    tem.cantidadtotal = this.numero(tem.cantidadtotal).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                    data.push(tem);
                    const ivaInteres = {
                        periodo: '',
                        vencimiento: '',
                        parcialidadcap: 'IVA',
                        parcialidadint: this.numero(val.ivainteres).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        parcialidadcom: this.numero(val.ivacomision).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        parcialidadseg: this.numero(val.ivaseguro).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        saldocap: this.numero(val.ivainteres).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                        cantidadtotal: ''
                    };
                    data.push(ivaInteres);
                }
            } else {
                if (this.request.redondea == 'S') {
                    tem.cantidadtotal = this.numero(Math.ceil(tem.cantidadtotal)).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                    data.push(tem);
                } else {
                    tem.cantidadtotal = this.numero(tem.cantidadtotal).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                    data.push(tem);
                }
            }
        });
        data.push({
            periodo: '', vencimiento: '', parcialidadcap: parcialiad, parcialidadint: intTotal1,
            parcialidadcom: comisionTotal1, parcialidadseg: seguro1, saldocap: '', cantidadtotal: total1
        });
        return new MatTableDataSource<any>(data);
    }

    private getPeriodicidad() {
        switch (this.result.periodicidad) {
            case 'M':
                return 'Mensual';
            case 'S':
                return 'Semanal';
            case 'C':
                return 'Catorcenal';
            case 'Q':
                return 'Quincenal';
            case 'A':
                return 'Al vencimiento';
        }
    }

    private getPeriodicidadT(val: number) {
        switch (this.result.periodicidad) {
            case 'M':
                return val === 1 ? 'Mes' : 'Meses';
            case 'S':
                return val === 1 ? 'Semana' : 'Semanas';
            case 'C':
                return val === 1 ? 'Catorcena' : 'Catorcenas';
            case 'Q':
                return val === 1 ? 'Quincena' : 'Quincenas';
            case 'A':
                return 'Al vencimiento';
        }
    }

    private numero(numero: any): number {
        return (numero === null || numero === undefined) ? 0 : +numero;
    }

    public setextraInfo(info: any) {
        this.extraInfo = info;
    }
}

export interface TASS {
    cdgmci: string;
    ciclo: string;
    codigo: string;
    comini: number;
    comsegin: number;
    encabeza: string;
    etiquetafecha: string;
    fechainicio: string;
    lycomini: string;
    lysegini: string;
    montopr: number;
    multper: number;
    nombre: string;
    periodicidad: string;
    periograciacap: number;
    periograciaint: number;
    plazo: number;
    plazodia: number;
    reca: string;
    tacontra: string;
    tapergrc: string;
    tapergri: string;
    tasa: number;
    tasaanual: number;
    tasaanualsiniva: number;
    tasasiniva: number;
    tasegsd: string;
    tatasa: string;
    taticap: string;
    tatifin: string;
    tatifven: string;
    tatiin: string;
    tatinic: string;
    tatiparc: string;
    tatipcr: string;
    tatiper: string;
    tatiscap: string;
    tit_ahorro: string;
    amortizacionArray: Array<ItemTASS>;
    nmmci: string;
}

export interface ItemTASS {
    ahorro: number;
    ivacomision: number;
    ivainteres: number;
    ivarecargos: number;
    ivaseguro: number;
    parcialidad: number;
    parcialidadcap: number | string;
    parcialidadcom: number | string;
    parcialidadint: number | string;
    parcialidadseg: number | string;
    periodo: number;
    saldocap: number | string;
    vencimiento: string;
}
