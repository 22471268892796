import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsInvArrayInterface } from '@comp/object/inversionistas/operaciones/migracionInv/insinv_array.interface';



@Injectable()
export class MigracionInvService {

    constructor(private http: HttpClient) {}

    /**
     * Funcion que inserta los clientes
     */
    public getInv(nombre1: string, nombre2: string, primape: string, segape: string, sexo: string,
                nacimiento: string, naciopai: string, nacioef: string, RFC: string, edocivil: string,
                CURP: string, IFE: string, regmarital: string, escolaridad: string, cdgext: string, cdgpostal: string, colonia: string, calle: string,
                noext: string, noint: string, tipoVialidad: string, numero: string, celular: string, 
                email: string, expuestopld: string, ocupacionPld: string, cdgco: string, falta: string, fdocumentacion: string,
                pmoral: string, cdgban: string, clabe: string, ctabancaria: string, numtar: string, 
                clasificacion: string ): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/migracionInv/setinv', {
            "primerNombre": nombre1, "segundoNombre": nombre2, "primerApellido": primape, "segundoApellido": segape, "sexo":sexo, 
            "fechaNacimiento": nacimiento, "paisNacimiento": naciopai, "entidadNacimiento":nacioef, "rfc": RFC, "curp": CURP, "ife": IFE, 
            "estadoCivil": edocivil, "regimenMarital": regmarital,"escolaridad": escolaridad, "CampoVariable1":cdgext, "codigoPostal": cdgpostal, "colonia": colonia,
            "calle": calle, "numeroExterior": noext, "numeroInterior": noint, "tipoVialidad": tipoVialidad, "telefonoFijo": numero, 
            "celular":celular, "email":email, "expuestopld": expuestopld, "ocupacion": ocupacionPld, "coordinacion": cdgco, "fechaAlta": falta,
            "fechaRecepcionDocumentos": fdocumentacion, "personaMoral":pmoral, "banco": cdgban,"clabe": clabe, "numeroCuenta":ctabancaria,
            "numeroTarjeta":numtar, "clasificacion": clasificacion}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
}