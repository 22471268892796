import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RecaudoDiarioRangosService {

    constructor(private http: HttpClient) {}

    // Muestra registros Recaudo Diario Rangos
    public rdrGet(): Observable<any> {
        return this.http.get('api/gestion-cobranza/administracion/parametrosdelsistema/recaudodiariorangos/get').pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    // Inserta / Actualiza tabla Recaudo Diario Rangos
    public rdrPost(tabla: any[]): Observable<any> {
        return this.http.post('api/gestion-cobranza/administracion/parametrosdelsistema/recaudodiariorangos/post/tabla', {tabla: tabla}).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

}