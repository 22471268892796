import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TraspasoFondosInterface } from '@comp/object/general/traspasofondos/traspasofondos.interface';


@Injectable()
export class TraspasoFondosService {

  constructor(private http: HttpClient) {}

  // Regresa el listado clientes traspaso de fondos de un préstamo
  public traspasofondosGet(cdgclns: string, ciclo: string, clns: string): Observable<TraspasoFondosInterface> {
    return this.http.post('api/general/traspasofondos/get', {cdgclns: cdgclns, ciclo: ciclo, clns: clns}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }
  

}
