import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class ProductoCreditoService {

    constructor(private http: HttpClient) {}


     /**
     * Insertar de productos de credito
     */
      public agregarPcr(jsonPCR): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/insertPcr', 
        {  "jsonPCR": jsonPCR })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Editar de productos de credito
     */
      public editarPcr(jsonPCR): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/editarPcr', 
            {  "jsonPCR": jsonPCR })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Eliminar productos de credito
     */
      public eliminarPcr(jsonPcr): Observable<any> {
        return this.http.post('api/control-cartera/catalogos/productos-credito/eliminarPcr', 
            {  "jsonPCR": jsonPcr, "jsonPGV": {} })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

         /**
     *  Metodo para obtener la infomarción de EM
     */

         public getEmpresa(): Observable<any[]> {
            return this.http.post("api/general/em/get", 
                {}).pipe(
                map(res => JSON.parse(JSON.stringify(res)) )
            )
        }
}