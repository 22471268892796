import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { map, concatMap } from 'rxjs/operators';
import { ArrayProgramas } from '@comp/object/general/programas/arrayprogramas.interface';



@Injectable()
export class ProgramaService {

  procesado: Observable<string>;

  constructor(private http: HttpClient) { }

  getProgramas(): Observable<ArrayProgramas> {
    return this.http.post('api/general/programas/consultar', {}
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }

  getProgramasFiltrados(codigo: string, nombre: string): Observable<ArrayProgramas> {
    return this.http.post('api/general/programas/filtrar', {
      'codigo': codigo,
      'nombre': nombre
    }
    ).pipe(
      map(res => JSON.parse(JSON.stringify(res)))
    );
  }
}
