import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { MovimientoDeInversion } from "@app/inversionistas/models/movimineto-inversion.model";
import { MovimientoInversionInterface } from "@comp/object/inversionistas/operaciones/movimientos-inversion/movimientoInversion.interface";
import { ArrayMovimientosInversion } from "@comp/object/inversionistas/operaciones/movimientos-inversion/arraymovimientosinversion.interface";
import { ArrayInversiones } from "@comp/object/inversionistas/operaciones/entrega-rendimientos/arraymovimientosinversion.interface";
import { MovimientoEntregaRendimiento } from "@app/inversionistas/models/entrega-rendimientos/moviminetoentregarendimiento.model";


@Injectable()
export class EntregaRendimientosService {

    movimientosInversion: Observable<MovimientoInversionInterface>;
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getMovimientosInversion(cdgcl: string, secuencia: string, cdgdif: string, cdgtmi: string): Observable<ArrayMovimientosInversion> {
        return this.http.post('api/inversionistas/operaciones/movimientos-inversion/consultar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }

    consultarInversiones(cdgcl: string, secuendif: string): Observable<ArrayInversiones> {
        return this.http.post('api/inversionistas/operaciones/entrega-rendimientos/consultar', { cdgcl: cdgcl, secuendif: secuendif }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }


    entregarRendimeintos(movimientoEntregaRendimiento: MovimientoEntregaRendimiento): Observable<string> {
        return this.procesado = this.http.post('api/inversionistas/operaciones/entrega-rendimientos/insertar', {
           id: {
                cdgcl: movimientoEntregaRendimiento.id.cdgcl,
                fecha: movimientoEntregaRendimiento.id.fecha
            },
            cdgdif: movimientoEntregaRendimiento.cdgdif,
            descripcion: movimientoEntregaRendimiento.descripcion,
            monto: movimientoEntregaRendimiento.monto,
            cdgtmi: movimientoEntregaRendimiento.cdgtmi,
            ftransac: movimientoEntregaRendimiento.ftransac,
            cdgcb: movimientoEntregaRendimiento.cdgcb,
            formapago: movimientoEntregaRendimiento.formaDePago,
            cdgfin: movimientoEntregaRendimiento.cdgfin,
            nocred: movimientoEntregaRendimiento.nocred,
            secuenapf: movimientoEntregaRendimiento.secuenapf
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );

    }


    buscarDetalleDeInversion(vCdgcl: string): Observable<any> {
        return this.http.post('api/inversionistas/operaciones/detalle-inversion/buscar', { cdgcl: vCdgcl, cdgpif: null }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}