'use strict';

import { CookieService } from 'ngx-cookie-service';

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserPe, DatosU } from '@comp/object/items/Users/UserPe';
import { UserCl, DatosC } from '@comp/object/items/Users/UserCl';
import { Datos, Role } from '@comp/object/items/Users/Datos';
import { Personal } from '@comp/object/items/Login/Personal';
import { Cliente } from '@comp/object/items/Login/Cliente';
import { Registro } from '@comp/object/items/Users/Registro';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { RouterService } from '@serv/router.service';
import { Recuperar } from '@comp/object/items/Users/Recuperar';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private currentUser: DatosU|DatosC|Datos;
  contador: any;

  constructor(router: RouterService, private httpClient: HttpClient, private cookies: CookieService) {
    this.currentUser = new Datos(null, null);
    if (cookies.get('token') && router.getPath() !== '/logout') {
      if (cookies.check('admi')) {
        try {
          this.currentUser = new DatosU(JSON.parse(cookies.get('admi')), Role.PERSONAL);
        } catch (error) {
          console.log('Invalid JSON string');
        }
      } else {
        try {
          this.currentUser = new DatosC(JSON.parse(cookies.get('user')), Role.CLIENTE);
        } catch (error) {
          console.log('Invalid JSON string');
        }
      }
    }
  }

  ngOnDestroy() {
    this.contador.unsubscribe();
  }

  /**
   * Authenticate userAdmin and save token
   *
   * @param  {Personal}   user     - login info
   * @return {Observable<UserPe>}
   */
  public loginAdmin(user: Personal): Observable<UserPe> {
    const tem = JSON.parse(JSON.stringify(user));
    return this.httpClient.post<UserPe>('/auth/yunius/pe/login', tem)
    .pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => {
          if (!event.error) {
            this.cookies.set('admi', JSON.stringify(event.datos), 1, '/');
            this.cookies.set('token', event.token, 1, '/');
            localStorage.setItem("rt",(event as any).refresh_token);
            try {
              this.currentUser = new DatosU(JSON.parse(this.cookies.get('admi')), Role.PERSONAL);
            } catch (error) {
              console.log('Invalid JSON string');
            }
          }
        },
        // Operation failed; error is an HttpErrorResponse
        error => {
          this.logout();
        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {

      })
    );
  }

  /**
   * Authenticate user and save token
   *
   * @param  {Cliente}   user     - login info
   * @return {Observable<UserCl>}
   */
  public login(user: Cliente): Observable<UserCl> {
    // const tem = JSON.parse(JSON.stringify(user));
    return this.httpClient.post<UserCl>('/auth/yunius/cl/login', user)
    .pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => {
          // console.log(event)
          if (!event.error) {
            this.cookies.set('user', JSON.stringify(event.datos), 1, '/');
            this.cookies.set('token', event.token, 1, '/');
            localStorage.setItem("rt",(event as any).refresh_token);
            try {
              this.currentUser = new DatosC(JSON.parse(this.cookies.get('user')), Role.CLIENTE);
            } catch (error) {
              console.log('Invalid JSON string');
            }
          }
        },
        // Operation failed; error is an HttpErrorResponse
        error => {
          this.logout();
        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {

      })
    );
  }

  /**
   * Re-Authenticate user and save token
   *
   * @return {Observable<any>}
   */
   public refreshToken(): Observable<any> {
    return this.httpClient.post<any>('/auth/yunius/refresh_token', {refresh_token:localStorage.getItem('rt')})
    .pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => {
          if (!event.error) {
            this.cookies.set('token', event.token, 1, '/');
          }
        },
        // Operation failed; error is an HttpErrorResponse
        error => {
          this.logout();
        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {

      })
    );
  }

  /**
   * Registry client into system
   *
   * @param  {Registro}   registro     - información de registro
   * @return {Observable<UserCl>}
   */
  public registro(user: Registro): Observable<any> {
    const tem = JSON.parse(JSON.stringify(user));
    return this.httpClient.post<any>('/auth/yunius/cl/registro_', tem)
    .pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => {

        },
        // Operation failed; error is an HttpErrorResponse
        error => {

        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {

      })
    );
  }

  // Recuperar contraseña
  public recuperar(token: string, contrasena: string): Observable<any> {
    return this.httpClient.post('auth/yunius/cl/cambio_contrasena', { token: token, contrasena: contrasena }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }


  // Generar token de cliente
  public genraTokenCl(usuario: string): Observable<any> {
    return this.httpClient.post('auth/yunius/cl/generar_token', { usuario: usuario }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Validar token de cliente
  public validaTokenCl(token: string): Observable<any> {
    return this.httpClient.post('auth/yunius/cl/validar_token', { token: token }).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  /**
   * Delete access token and user info
   */
  public logout(): void {
    this.cookies.delete('user', '/');
    this.cookies.delete('admi', '/');
    this.cookies.delete('token', '/');
    localStorage.removeItem("rt");
    this.cookies.delete('cont'); // Módulo PLD - Operacionesacumuladas
    this.currentUser = new Datos(null, null);
  }

  /**
   * Check if a user is logged in
   *
   * @return {Bool}
   */
  public isLoggedIn(): boolean {
    return (this.currentUser.role === Role.CLIENTE || this.currentUser.role === Role.PERSONAL) && this.tieneAcceso();
  }

  /**
   * Check if a user is logged in Admin
   *
   * @return {Bool}
   */
  public isLoggedInAdmin(): boolean {
    return this.currentUser.role === Role.PERSONAL && this.tieneAcceso();
  }

  /**
   * Get auth token
   *
   * @return {String} - a token string used for authenticating
   */
  public getToken(): string {
    return this.cookies.get('token');
  }

  /**
   * Actualiza el currentUser
   *
   * @returns {void}
   */
  private updateCurrentUser(): void {
    if (this.cookies.check('admi')) {
      try {
        this.currentUser = new DatosU(JSON.parse(this.cookies.get('admi')), Role.PERSONAL);
      } catch (error) {
        console.log('Invalid JSON string');
      }
    }
    if (this.cookies.check('user')) {
      try {
        this.currentUser = new DatosC(JSON.parse(this.cookies.get('user')), Role.CLIENTE);
      } catch (error) {
        console.log('Invalid JSON string');
      }
    }
  }

  /**
   * Get admi
   *
   * @return {DatosU|bool} - el administrador
   */
  public getAdmi(): DatosU|boolean {
    this.updateCurrentUser();
    if (this.currentUser instanceof DatosU) {
      return this.currentUser;
    } else {
      return false;
    }
  }

  /**
   * Get user
   *
   * @return {DatosC|bool} - el usuario
   */
  public getUser(): DatosC|boolean {
    this.updateCurrentUser();
    if (this.currentUser instanceof DatosC) {
      return this.currentUser;
    } else {
      return false;
    }
  }

  /**
   * Verifica si tiene acceso al sistema
   *
   * @returns {boolean} true tiene acceso, false no tiene
   */
  private tieneAcceso(): boolean {
    return this.cookies.check('token');
  }

  /**
     *  Busqueda logo
     */
    public getLogo(): Observable<any[]>{
      return this.httpClient.get('api/ahorros/empresa/logo', { }
          ).pipe(
          map(res => JSON.parse(JSON.stringify(res)))
      );
  } 

   /**
     *  Busqueda logo para el cliente
     */
   public getLogoCl(): Observable<any[]>{
    return this.httpClient.get('api/ahorros/empresa/logocl', { }
        ).pipe(
        map(res => JSON.parse(JSON.stringify(res)))
    );
} 

  async timer(time: number) {
    console.log('Tiempo permitido: ', moment('2000-01-01 00:00:00').add(moment.duration(time)).format('HH:mm:ss'));
    this.contador = timer(time);
    this.contador.subscribe(() => {
      console.log('Sesión terminada');
      this.logout();
      window.location.href = '/';
    });
  }

   // Validar credenciales de cliente
   public verificarCredenciales(user: Cliente): Observable<UserCl>  {
    return this.httpClient.post('api/accesoPublico/verificar/', user).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  public confirmarPIN(datos: any): Observable<any> {
    return this.httpClient.post('auth/yunius/moffin/confirmar', datos).pipe(
      map(res => {
        return res;
      })
    );
  }
}


interface Token{
  access_token: string;
  refresh_token: string;
  error: string;
}