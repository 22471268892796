import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RepMovimientosInversionService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de movimientos de inversion
     */
    public getRepMovInv(fdesde: string, fhasta:any, inicio:number, limite:number ): Observable<any[]> {
        return this.http.post("api/inversionistas/reportes/repmovimientosinversion/getReporte", 
        {  "fdesde": fdesde,
            "fhasta": fhasta,
            "inicio": inicio,
            "limite": limite
        }).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        )
    }

    /**
     *  Genera excel
     */
    public generaExcel(body): Observable<any[]> {
        return this.http.post('api/inversionistas/reportes/repmovimientosinversion/getXlsx', 
        body, {responseType: 'blob'}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
}