import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ArrayMontosIdentificacion } from "@comp/object/pld/administracion/parametros-sistema/montos-identificacion/arraymontosidentificacion.interface";
import { MontosIdentificacionInterface } from "@comp/object/pld/administracion/parametros-sistema/montos-identificacion/montosidentificacion.interface";
import { MontoIdentificacion } from "@app/pld/models/parametros-del-sistema/montoidentificacion.model";
import { VpArrayInterface } from '@comp/object/general/valorparametro/vpArray.interface';

@Injectable()
export class MontosIdentificacionService {

    montosIdentificacion: Observable<MontosIdentificacionInterface>;
    procesado: Observable<string>;

    constructor(private http: HttpClient) { }

    getMontosIdentificacion(): Observable<ArrayMontosIdentificacion> {
        return this.http.post('api/pld/administracion/parametros-sistema/montos-identificacion/consultar', {}
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }

    procesarMontosIdentificacion(valorParametro:MontoIdentificacion, editar: boolean): Observable<string> {
        if (!editar) {
            return this.procesado = this.http.post('api/pld/administracion/parametros-sistema/montos-identificacion/insertar', {
                id: {
                    cdgps: valorParametro.cdgps,
                    desde: valorParametro.desde,
                },
                hasta: valorParametro.hasta,
                valor: ""+valorParametro.valor,
                utilizado: valorParametro.utilizado,
                descripcion: valorParametro.descripcion
            }
            ).pipe(
                map(res => JSON.parse(JSON.stringify(res)))
            );
        } else {
            return this.procesado = this.http.post('api/pld/administracion/parametros-sistema/montos-identificacion/editar', {
                id: {
                    cdgps: valorParametro.cdgps,
                    desde: valorParametro.desde,
                },
                valor: ""+valorParametro.valor,
            }
            ).pipe(
                map(res => JSON.parse(JSON.stringify(res)))
            );
        }
    }

  // Consulta el vaolor de una parámetro
  public valorParametro(cdgps: string, desde: Date): Observable<VpArrayInterface> {
    return this.http.post('api/general/vp/get', {cdgps: cdgps, desde: desde}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

    
}