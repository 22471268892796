import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CTCInterface } from '@comp/object/general/companias/ctc.interface';

@Injectable()
export class CompaniasService {

  constructor(private http: HttpClient) {}

  // Regresa el catálogo de Giro
  public companiasGet(codigo: string): Observable<CTCInterface> {
    return this.http.post('api/general/ctc/get', {codigo: codigo}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
