import { RouterService } from '@serv/router.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import { UtilService } from '@comp/util/util.service';

import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { throwError } from 'rxjs/internal/observable/throwError';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor(public cookies: CookieService, public util: UtilService, public router: RouterService, private authService: AuthService) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookies.get('token') && this.util.isSameOrigin(request.url)) {
      request = this.addToken(request, this.cookies.get('token'));
    }
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if(event.body.hasOwnProperty('error')){
            if(event.body.error === "invalid_token"){
              throw new Error('invalid_token');
            }
          }
        }
      }),
      catchError(error=>{
      if(error instanceof HttpErrorResponse && error.status === 401){
        if(error.error.error === "Unauthorized"){
          this.router.irLogin();
          // remove any stale tokens
          this.cookies.delete('token', '/');
          this.cookies.delete('user', '/');
          this.cookies.delete('admi', '/');
          localStorage.removeItem("rt");
          return throwError(error);
        }else{
          return this.handle401Error(request,next);
        }
      }else{
        if(error.message === "invalid_token"){
          return this.handle401Error(request,next);
        }else{
          return throwError(error);
        }
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
  
      return this.authService.refreshToken().pipe(
        switchMap((token) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }));
  
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}