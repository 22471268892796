import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FotoImagenService {

  constructor(private http: HttpClient) {}

  // Inserta imagen IDue
  public fotoImagenPost(cdgcl: string, url_array: any[]): Observable<any> {
    return this.http.post('api/clientes/idue/impFiIdue', {cdgcl: cdgcl, url_array: url_array}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

  // Inserta imagen IDue_v2
  public fotoImagenPost_v2(cdgcl: string, url_array: any[]): Observable<any> {
    return this.http.post('api/clientes/idue/impFiIdue_v2', {cdgcl: cdgcl, url_array: url_array}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
