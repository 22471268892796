import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoInterface } from '@comp/object/general/moneda/mo.interface';
import { AEInterface } from '@comp/object/general/actividadeconomica/ae.interface';

@Injectable()
export class ActividadEconomicaService {

  constructor(private http: HttpClient) {}

  // Obtiene el catálogo de Actividad Económica
  public actividadGet(codigo: string, cdgse: string, cdggi: string, nombre: string): Observable<AEInterface> {
    return this.http.post('api/general/ae/get', {codigo: codigo, cdgse: cdgse, cdggi: cdggi, nombre: nombre}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
