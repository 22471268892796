'use strict';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class Toolbar {
    [x: string]: any;
    /**
     * Variable que nos ayuda con el ToolBar
     */
    private type = new Subject<number>();
    /**
     * Variable que nos ayuda con el titulo del app
     */
    private title = new Subject<string>();

    /**
     * Variable que indica cuando se hace clik en el back arrow
     */
    private back = new Subject();

    public getType(): Subject<number> {
        return this.type;
    }

    public getTitle(): Subject<string> {
        return this.title;
    }

    private setType(value: number): void {
        this.type.next(value);
    }

    public setTitle(value: string): void {
        this.title.next(value);
    }

    /**
     * @returns el objeto suscriptor para saber cuando hacen click en el back arrow
     */
    public getBack(): Subject<any>{
      return this.back;
    }

    /**
     * Funcion para emitir un evento de click en el back arrow
     */
    public onBack(): void{
      //this.back.next();
      window.history.back();
    }

    /**
     * Muestra el toolbar con menú Hamburguesa
     */
    public showToolbarBurger(): void {
      this.setType(0);
    }

    /**
     * Muestra el toolbar con x de cerrar
     */
    public showToolbarCerrar(): void {
      this.setType(1);
    }

    /**
     * Muestra el toolbar con flecha de regreso
     */
    public showToolbarRegreso(): void {
      this.setType(2);
    }

    /**
     * Muestra el toolbar vacio
     */
    public showToolbarLimpio(): void {
      this.setType(-1);
    }
}

