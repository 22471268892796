import { EncData } from '../../../services/control-cartera/cc-reportes/amortizacion/amortizacion-base';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { DatosU } from '@comp/object/items/Users/UserPe';
import { MatTableDataSource } from '@angular/material/table';
import { ReportePdf, EstiloColumnas } from '../pdf/reporte-pdf.service';
import { AuthService } from '@serv/auth/auth.service';
import { ReportesAccesoClientesService } from '@serv/reportes-clientes/reportes-acceso-clientes.service';
import { DatosC } from '@comp/object/items/Users/UserCl';
moment.locale('es');

@Component({
  selector: 'app-reporte-viewcl',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})

export class ViewClReporteComponent  implements OnInit {
  public logo = '';
  private logo64 = '';
  public nombreEM = '';
  @Input() titulo = '';
  @Input() encabeza = '';
  @Input() reca = ''; 
  @Input() etiquetafecha = '';
  @Input() nombre = '';
  @Input() descargar = true;
  @Input() encdata: Array<Array<EncData>> = [];
  @Input() set comicion(val: number) {
      this.comini = 'Adicional a los pagos mencionados en la tabla ' +
                    'anterior, se debe considerar el pago de las comisiones iniciales por: ' +
                    this.numero(val).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    
  }
  public comini = '';
  public hora = moment().format('h:mm:ss a') + '   ';
  public fecha = moment().format('DD/MM/YYYY') + ' ';
  @Input() tituloFecha = 'Fecha de generación';
  @Input() headerTitle = '';
  @Input() displayedColumns: Array<string> = [];
  @Input() tituloColumns: Array<string> = [];
  @Input() dataSource = new MatTableDataSource<any>();
  @Input() totales: Array<string> = [];
  @Input() styleColumns: EstiloColumnas;
  @Input() reca_pcr = '';
  @Input() tafegeen = '';
  @Input() clarren: string;
  tituloColumns_A = ['No.', 'Fecha', 'Renta', 'IVA', 'Pago total'];
  @Input() dataSource_A = new MatTableDataSource<any>();
  displayedColumns_A = ['periodo', 'vencimiento', 'parcialidadcap', 'parcialidadint', 'cantidadtotal'];
  data: any;
  codigoEM= null;

  datosReporte: any
  constructor(private auth: AuthService, public reportesService: ReportesAccesoClientesService,) {
    this.setUserName()
    this.obtEmpresa()
    this.getLogoCl();


   // this.nombreEM = "DEMO";  
    this.data = this.dataSource  
    var obj = localStorage.getItem('reportePdf');
    this.datosReporte = JSON.parse(obj);
   // this._toolbar.showToolbarRegreso();
  }
  ngOnInit(): void {
    this.etiquetafecha = this.etiquetafecha.concat(' ',this.fecha,this.hora);
    //this.displayedColumns.push("Total Parcialidad");
    // this.displayedColumns[7] = "Saldo Capital";
    // this.displayedColumns[2] = "Parcialidad Capital";
    // this.displayedColumns[3] = "Parcialidad Interes";
    for (let i = 0; i < this.data.length; i += 2) {
      let segundoValor = parseFloat(this.dataSource[i][2].replace(",", "")); // Convertir a número y quitar comas
      let tercerValor = parseFloat(this.dataSource[i][3].replace(",", "")); // Convertir a número y quitar comas
    
      let suma = segundoValor + tercerValor;
    
      this.dataSource[i].nuevoValor = suma.toFixed(2); // Redondear a 2 decimales y convertir a cadena
    }
    //console.log(this.dataSource)
    // if (this.clarren == 'S') {
    //   this.dataSource.data.forEach(element => {
    //     if (element.periodo) {
    //       this.dataSource_A.data.push({periodo: element.periodo, vencimiento: element.vencimiento, parcialidadcap: element.parcialidadcap, parcialidadint: element.parcialidadint, cantidadtotal: element.primaseg})
    //     }
    //   });
    // }
  }

  getLogoCl() {
    this.auth.getLogoCl().toPromise().then((result: any) => {
      this.logo = result.logo;
      this.logo64 = result.logo64;
    }, (error) => {
      console.log('Hubo un error con la peticion: ' + error);
    });

    
  }

  obtEmpresa(){
        // Se manda llamar la funcion para obtener la empresa con el codigo de la empresa que tiene el cliente
        this.obtenerEmpresa(this.codigoEM).then((nombreEM) => {
          this.nombreEM = nombreEM
        }).catch((error) => {
          console.error(error);
        });
  }

    // Se obtiene el objeto del usuario y se filtra solo su codigo de empresa
    private setUserName() {
      const user = this.auth.getUser();
      
      if (user instanceof DatosC) {
        this.codigoEM = user.cdgem;
  
      } 
    }

  
    //Metodo para obtener la empresa mediante su codigo 
    obtenerEmpresa(cdgem: any) {
      // Se construlle el json mediante un valor obtenido cuando se llama la funcion
      const object = {
        "id": {
          "cdgem": cdgem
        }
      };
      let nombreEM;
      return this.reportesService.obtenerEmpresa(object).toPromise().then((result: any) => {
        nombreEM = result.emArray[0].nombre;
        return nombreEM;  // Devolver el valor del nombre de la empresa
      }).catch((err) => {
        //this.loading = false;
        throw err; 
      });
    }
  numero(numero: any): number {
    return (numero === null || numero === undefined) ? 0 : numero;
  }

  imprimirReporte() {
    const data = [];
    const data_A = [];
    
    if (this.clarren == 'N') {
      this.dataSource.data.forEach(ren => {
        const rent = [];
        for (const item of this.displayedColumns) {
          rent.push(ren[item]);
        }
        data.push(rent);
      });
    } else {
      this.dataSource_A.data.forEach(ren => {
        const rent = [];
        for (const item of this.displayedColumns_A) {
          rent.push(ren[item]);
        }
        data_A.push(rent);
      });
      data_A.push(this.totales);
    }
    data.push(this.totales);
    const reportePdf = new ReportePdf(this.logo64,
                                      this.nombreEM,
                                      this.clarren =  this.titulo,
                                      this.clarren = this.encabeza,
                                      this.clarren = this.reca_pcr,
                                      this.tafegeen = this.etiquetafecha ,
                                      true,
                                      this.encdata,
                                      this.tituloColumns,
                                       data,
                                      this.styleColumns,
                                      this.headerTitle,
                                      this.tituloFecha,
                                      this.nombre,
                                      this.comini);
    reportePdf.generarPDF();

  }

  sumaDeValores(row: any): number {
    const valor1 = Number(row[3]);
    const valor2 = Number(row[4]);

    // Verificar si los valores son números válidos antes de sumarlos
    if (!isNaN(valor1) && !isNaN(valor2)) {
      return valor1 + valor2;
    } else {
      // Devolver algún valor predeterminado o manejar el caso de error según tus necesidades
      return 0; // En este ejemplo, se devuelve 0 si uno de los valores no es un número válido
    }
  }

}
