import { MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource, MatPaginator} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { OnInit } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { AmortizacionService } from "@serv/control-cartera/cc-reportes/amortizacionconcredito/amortizacionconcredito.service";
import { AmortizacionConSolicitudService } from "@serv/control-cartera/cc-reportes/amortizacionconcredito/amortizacionconsolicitud/amortizacionconsolicitud.service";

@Component({
    selector: 'app-ahorros-dialog',
    templateUrl: './busquedaDialog.component.html',
    styleUrls: ['./busquedaDialog.component.css']
  })
  export class BusquedaDialogOverview implements OnInit, AfterViewInit {

    public nombre: string;
    public cdgcl: string;
    public ciclo: string;
    public cdgns: string;
    public grupo: string;
    public loading = true;
    public codigo: string;
    public displayedColumns: string[] = ['select', 'codigo', 'nombre', 'ciclo'];
    public dataSource = new MatTableDataSource<DatosI>();
    public selection = new SelectionModel<DatosI>(true, []);
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public displayedColumnsG: string[] = ['select', 'cdgns', 'grupo', 'ciclo'];
    public dataSourceG = new MatTableDataSource<DatosG>();
    public selectionG = new SelectionModel<DatosG>(true, []);
    @ViewChild(MatPaginator) paginatorG: MatPaginator;

    public displayedColumnsIG: string[] = ['select', 'codigo', 'nombre','cdgns','grupo','ciclo'];
    public dataSourceIG = new MatTableDataSource<DatosIG>();
    public selectionIG = new SelectionModel<DatosIG>(true, []);
    @ViewChild(MatPaginator) paginator2: MatPaginator;
  
  ngOnInit(): void {
    if(this.data.tipo == "I" || this.data.tipo == "IG"){
      this.busquedaGet(this.data.cdgcl);
    }else if(this.data.tipo == "G"){  
      this.busquedaGet(this.data.cdgns);
    }
    
  }


    constructor(
      public dialogRef: MatDialogRef<BusquedaDialogOverview>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData, 
      private amorCredito: AmortizacionService,
      private amorSolicitud: AmortizacionConSolicitudService) {
      
      }

    onNoClick(): void {
      this.dialogRef.close();
      this.nombre = "";
      this.cdgcl = "";
      this.cdgns = "";
      this.ciclo = "";
      this.grupo = "";
    }
   
  
    guardarDatos(row: any){
      if(this.data.tipo == "I"){
          this.data.cdgcl = row.codigo;
          this.data.ciclo = row.ciclo;
          this.data.nombre = row.nombre;
          this.data.usaarrenc = row.usaarrenc;
          this.data.solicitud = row.solicitud;
      }else if(this.data.tipo == "G"){
        this.data.cdgns = row.cdgns;
        this.data.grupo = row.grupo;
        this.data.ciclo = row.ciclo;
        this.data.usaarrenc = row.usaarrenc;
        this.data.solicitud = row.solicitud;
      }else if(this.data.tipo == "IG"){
        this.data.cdgcl = row.codigo;
        this.data.cdgns = row.cdgns;
        this.data.nombre = row.nombre;
        this.data.grupo = row.grupo;
        this.data.ciclo = row.ciclo;
        this.data.usaarrenc = row.usaarrenc;
        this.data.solicitud = row.solicitud;
      }

      this.dialogRef.close(this.data);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSourceG.paginator = this.paginator;
        this.dataSourceIG.paginator = this.paginator;
    }
 // Tablas //
   applyFilterI(filterValue: string) {
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase(); 
        this.dataSource.filter = filterValue;
      }

      applyFilterIG(filterValue: string) {
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase(); 
        this.dataSourceIG.filter = filterValue;
      }
      applyFilterG(filterValue: string) {
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase(); 
        this.dataSourceG.filter = filterValue;
      }
 
  /* ---------------Endpoint -------------------------- */
  busquedaGet(cdg: string){

    if(this.data.amortizacion == 'solicitud'){
      this.loading = true;
      if(this.data.tipo == "I" || this.data.tipo == "IG"){
        this.amorSolicitud.getBusqueda(cdg, "", this.data.tipo ).toPromise().then((result: any)=> {
          if( this.data.tipo == "I"){
            this.dataSource.data = result.prestamosTipoArray;
            this.loading =false;
          }else if(this.data.tipo == "IG"){
            //var array = this.sortJSON(result.prestamosTipoArray,'ciclo','asc');
            this.dataSourceIG.data = result.prestamosTipoArray;
            this.loading =false;
          }
        });
      }else if(this.data.tipo == "G"){
        this.loading = true;
        this.amorSolicitud.getBusqueda("", cdg,  this.data.tipo ).toPromise().then((result: any)=> {
          this.dataSourceG.data = result.prestamosTipoArray;
          this.loading = false;
        });
      }  
    }else{
        this.loading = true;
      if(this.data.tipo == "I" || this.data.tipo == "IG"){
        // this.amorCredito.getBusqueda(cdg, "", this.data.tipo ).toPromise().then((result: any)=> {
        this.amorCredito.getBusquedaV2(cdg, "", this.data.tipo ).toPromise().then((result: any)=> {
          // console.log(result);
          if (result.generalArray) {
            result.generalArray.forEach(element => {
              if (element.cdgcl) {
                element.codigo = element.cdgcl;
              }
            });
          }
          if( this.data.tipo == "I"){
            this.dataSource.data = result.generalArray;
            this.loading =false;
          }else if(this.data.tipo == "IG"){
            this.dataSourceIG.data = result.generalArray;
            this.loading =false;
          }
        });
      }else if(this.data.tipo == "G"){
        this.loading = true;
        // this.amorCredito.getBusqueda("", cdg,  this.data.tipo ).toPromise().then((result: any)=> {
        this.amorCredito.getBusquedaV2("", cdg,  this.data.tipo ).toPromise().then((result: any)=> {
          // console.log(result);
          if (result.generalArray) {
            result.generalArray.forEach(element => {
              if (element.cdgcl) {
                element.codigo = element.cdgcl;
              }
            });
            this.dataSourceG.data = result.generalArray;
          }
          this.loading = false;
        });
      }  
    }
      
  }

  public busquedaGetFiltro(cdg: string){
    this.loading = true;
    if(this.data.tipo == "I" || this.data.tipo == "IG"){
      // this.amorCredito.getBusqueda(this.codigo, "", this.data.tipo ).toPromise().then((result: any)=> {
      this.amorCredito.getBusquedaV2(this.codigo, "", this.data.tipo ).toPromise().then((result: any)=> {
        result.generalArray.forEach(element => {
          element.codigo = element.cdgcl;
        });
        if( this.data.tipo == "I"){
          this.dataSource.data = result.generalArray;
          this.loading =false;
        }else if(this.data.tipo == "IG"){
          this.dataSourceIG.data = result.generalArray;
          this.loading =false;
        }
      });
    }else if(this.data.tipo == "G"){
      this.loading = true;
      // this.amorCredito.getBusqueda(this.codigo, cdg,  this.data.tipo ).toPromise().then((result: any)=> {
      this.amorCredito.getBusquedaV2(this.codigo, cdg,  this.data.tipo ).toPromise().then((result: any)=> {
        result.generalArray.forEach(element => {
          element.codigo = element.cdgcl;
        });
        this.dataSourceG.data = result.generalArray;
        this.loading = false;
      });
    }    
  }

  public sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
        var x = a[key],
        y = b[key];

        if (orden === 'asc') {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }

        if (orden === 'desc') {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
    });
}
    
  
  }

  export interface DatosI {
    codigo: string;
    nombre: string;
    ciclo: string;
  }

  export interface DatosG {
    cdgns: string;
    grupo: string;
    ciclo: string;
  }

  export interface DatosIG {
    codigo: string;
    nombre: string;
    cdgns: string;
    grupo: string;
    ciclo: string;
  }
  
  export interface DialogData {
    ciclo: string;
    tipo: string;
    nombre: string;
    grupo: string;
    cdgcl: string
    cdgns: string;
    amortizacion:string;
    usaarrenc: string;
    solicitud: string;
  }