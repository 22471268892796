import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayDepositosBancarios } from '@comp/object/control-cartera/operacion/movimientos/importaciones/depositosbancarios/depositosBancarios_array.interface';

@Injectable()
export class DepositosBancariosService {

    constructor(private http: HttpClient) { }

  public insertarImportacion(version: string, cdgFo: string, cdgCb: string, generarMovBan: string, nomImp: string, conciliado: string, clave: string, tipoImp: string, cdgPv: string): Observable<ArrayDepositosBancarios> {
      return this.http.post('api/control-cartera/operacion/movimientos/importaciones/depositosbancarios', {
        'version': version,
        'cdgfo': cdgFo,
        'cdgcb': cdgCb,
        'generarmovban': generarMovBan,
        'nomimp': nomImp,
        'conciliado': conciliado,
        'clave': clave,
        'tipoimp': tipoImp,
        'cdgpv': cdgPv
        }
        ).pipe(
            map(res => JSON.parse(JSON.stringify(res)))
        );
    }
}
