import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayInterface} from '@comp/object/ahorros/reportes/cuentasAhorro/array.interface'
import { CodigoInterface} from '@comp/object/ahorros/reportes/cuentasAhorro/codigo.interface'
import { EstatusInterface} from '@comp/object/ahorros/reportes/cuentasAhorro/estatus.interface';
import { CuentaAhorroInterface} from '@comp/object/ahorros/reportes/cuentasAhorro/cuentaahorro.interface';
import { ClArrayInterface } from '@comp/object/general/acreditados/cl/clarray.interface';

@Injectable()
export class CuentasAhorroService {

    constructor(private http: HttpClient) {}

    /**
     * Funcion obtener los esquemas
     */
    public getEsquema(): Observable<ArrayInterface> {
        return this.http.post('api/ahorros/reportes/reportecuentasahorro/buscar', { }
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que crea un nuevo esquema
     */
    public setEsquema(codigo: string, nombre: string, descripcion: string, duenope: string, registro: Date, modificado: Date, publico: string): Observable<CodigoInterface> {
        return this.http.post('api/ahorros/reportes/reportecuentasahorro/crearEsquema', { codigo, nombre, descripcion, duenope, registro, modificado, publico}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que elimina un esquema
     */
    public eliminarEsquema(codigo: string): Observable<EstatusInterface> {
        return this.http.post('api/ahorros/reportes/reportecuentasahorro/eliminarEsquema', { codigo}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que obtiene las cuentas de ahorro
     */
    public cuentasAhorroGet(cdgcl: string, tipo: string): Observable<CuentaAhorroInterface> {
        return this.http.post('api/ahorros/cuentasdeahorro/get', { cdgcl, tipo}
            ).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

    /**
     * Funcion que obtiene las cuentas de ahorro
     */
    public reporteEsquemaGet(params: any): Observable<CuentaAhorroInterface> {
        return this.http.post('api/ahorros/reportes/reportecuentasahorro/reporteesquema', params).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }
    
    public ahorradoresIndGet(cdgcl: string, clns: string, nombre: string, cdgns: string, grupo: string): Observable<ClArrayInterface> {
        return this.http.post('api/clientes/buecar/individuales', { cdgcl: cdgcl, clns: clns, nombre: nombre, cdgns: cdgns, grupo: grupo }).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    public ahorradoresGpoGet(cdgcl: string, clns: string, nombre: string, cdgns: string, grupo: string): Observable<ClArrayInterface> {
        return this.http.post('api/clientes/buecar/grupos', { cdgcl: cdgcl, clns: clns, nombre: nombre, cdgns: cdgns, grupo: grupo }).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }

    public ahorradoresIndGpoGet(cdgcl: string, clns: string, nombre: string, cdgns: string, grupo: string): Observable<ClArrayInterface> {
        return this.http.post('api/clientes/buecar/individualdegrupo', { cdgcl: cdgcl, clns: clns, nombre: nombre, cdgns: cdgns, grupo: grupo }).pipe(
            map(res => {
            return JSON.parse(JSON.stringify(res));
            })
        );
    }
   
}