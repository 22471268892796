import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoInterface } from '@comp/object/general/moneda/mo.interface';

@Injectable()
export class MonedaService {

  constructor(private http: HttpClient) {}

  // Regresa el catálogo de moneda
  public monedaGet(): Observable<MoInterface> {
    return this.http.get('api/general/mo/get', {}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
