import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AcreditadosIndCredService {
    public cl: any = new Object();
    public domicilio: any = new Object();
    public mediosCelular: any = new Object();
    public mediosEmail: any = new Object();;
    public medios: any [] = new Array();
    public paises: any = new Array();
    public paises2: any = new Array();
    public nivRiesgo: string;
    public nivelRiesgo: any = new Array();
    public pldCoinc: string;
    public comentario: string;
    public perfil: any = new Object();
    public alta: Date;
    public modifica: Date;
    public recepdoc: Date;
    public ipoliticapld: Date;
    public tpoliticapld: Date;
    public labelRFC
    public editableRFC
    public labelCURP
    public editableCURP
    public activar
    public domArray: any = new Object();

    constructor(private http: HttpClient) {}

    /**
     * Consulta de acreditados
     */
    public get(jsonCL ): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/acreditados-inf-cred/get', 
            {"jsonCL": jsonCL, "jsonPGV": {"S_CODIGO_EM":""}})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Edicion de fechabcc
     */
    public editar(jsonPOT): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/acreditados-inf-cred/editar', 
            { "jsonPOT": jsonPOT})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


    /**
     * Edicion de clientes
     */
    public getCl(codigo): Observable<any[]> {
        return this.http.post('api/clientes/detalle/individual', 
            { cdgcl: codigo})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     * Listado de paises
     */
    public getPaises(): Observable<any[]> {
        return this.http.post('api/control-cartera/listados/paises/get', 
            { })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Listado de entidades
     */
     public getEntidades(naciopai): Observable<any[]> {
        return this.http.post('api/control-cartera/listados/entidades/get', {cdgpai: naciopai ? naciopai : '' })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Listado de entidades
     */
     public getMunicipio(cdgpai, cdgef): Observable<any[]> {
        return this.http.post('api/general/ubicacion/mu/get', {cdgpai: cdgpai, cdgef: cdgef})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Listado de entidades
     */
     public getLocalidad(cdgpai,cdgef, cdgmu): Observable<any[]> {
        return this.http.post('api/control-cartera/listados/localidades/get', {cdgpai: cdgpai ? cdgpai : '', cdgef: cdgef ? cdgef : '', cdgmu: cdgmu ? cdgmu : '' })
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

     /**
     * Listado de entidades
     */
     public getColonia(cdgpai, cdgef, cdgmu, cdglo): Observable<any[]> {
        return this.http.post('api/control-cartera/listados/colonias/get', {cdgpai: cdgpai ? cdgpai : '', cdgef: cdgef ? cdgef : '', cdgmu: cdgmu ? cdgmu : '', cdglo: cdglo ? cdglo : ''})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


    
     /**
     * Listado de CDG POSTAL
     */
     public getCdgPostal(cp): Observable<any[]> {
        return this.http.post('api/control-cartera/codigopostal/get', {cdgpostal: cp ? cp : ''})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

      /**
     * Listado de medios de comunicacion
     */
      public getMCO(cdgcl): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/acreditados/medios/get',
      {codigo: cdgcl, origen: 'I'})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


       /**
     * Insert y editar de medios de comunicacion
     */
       public setMCO(medios): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/acreditados/medios/post',
      {medios})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
 * Insert y editar de Domicilios
 */
    public getDOM(cdgcl): Observable<any[]> {
        return this.http.post('api/control-cartera/operacion/acreditados/domicilio/get', {codigo: cdgcl, origen: 'I'})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }


        /**
     * Insert y editar de Domicilios
     */
        public setDOM(domicilio): Observable<any[]> {
            return this.http.post('api/control-cartera/operacion/acreditados/domicilio/post', {domicilio})
            .pipe(
            map(res => {
                return JSON.parse(JSON.stringify(res));
            })
            );
        }

    valoresDeParametro(cdgps: string, desde: Date, model: string) {
        this.http.post('api/general/vp/get', { cdgps: cdgps, desde: desde })
        .subscribe((result: any) => {
          // console.log(model, result);
          if (result.vpArray) {
            if (result.vpArray.length > 0) {
              if (model === 'cvlcl1' || model === 'cvlcl2' || model === 'cvlcl3' || model === 'cvlcl4' || model === 'cvlcl5') {
                const num: boolean = isNaN(parseInt(result.vpArray[0].valor, 0));
                this[model] = num ? 30 : parseInt(result.vpArray[0].valor, 0);
              } else {
                this[model] = result.vpArray[0].valor;
              }
              if (model === 'cantNR') {
                this.lstNivelDeRiesgo(result.vpArray[0].valor);
              }
            }
          }
        }, (error) => {
          console.log(error);
        });
    }

    lstNivelDeRiesgo(cantNR: string) {
        switch (cantNR) {
          case '2':
            this.nivelRiesgo = [
              {codigo: '3', nombre: 'Bajo riesgo'}, {codigo: '7', nombre: 'Alto riesgo'}
            ];
            // tslint:disable-next-line:max-line-length
            if (this.nivRiesgo === '1' || this.nivRiesgo === '' || this.nivRiesgo === '5') {
              this.nivRiesgo = '3';
            }
            if (this.nivRiesgo !== '') {
              if (parseInt(this.nivRiesgo, 0) > 7) {
                this.nivRiesgo = '7';
              }
            }
          break;
          case '3':
            this.nivelRiesgo = [
              {codigo: '3', nombre: 'Bajo riesgo'}, {codigo: '5', nombre: 'Riesgo medio'}, {codigo: '7', nombre: 'Alto riesgo'}
            ];
            if (this.nivRiesgo === '1' || this.nivRiesgo === '') {
              this.nivRiesgo = '3';
            }
            if (this.nivRiesgo !== '') {
              if (parseInt(this.nivRiesgo, 0) > 7) {
                this.nivRiesgo = '7';
              }
            }
          break;
          case '5':
            this.nivelRiesgo = [
              {codigo: '1', nombre: 'Riesgo nulo'}, {codigo: '3', nombre: 'Bajo riesgo'}, {codigo: '5', nombre: 'Riesgo medio'},
              {codigo: '7', nombre: 'Alto riesgo'}, {codigo: '9', nombre: 'Muy alto riesgo'}
            ];
            if (this.nivRiesgo === '') {
              this.nivRiesgo = '1';
            }
          break;
        }
      }

      perfilTransaccional(cdgcl: string, sexo: string, ocupacionpld: string) {
        this.http.post('api/control-cartera/operacion/acreditados/pld/perfilT/get', {cdgcl: cdgcl, sexo: sexo, ocupacionpld: ocupacionpld})
        .subscribe((result: any) => {
          // console.log(result);
          if (result) {
            this.perfil.sexo = result.sexo;
            this.perfil.rgsocie = result.rgsocie;
            this.perfil.rangoedad = result.rangoedad;
            this.perfil.ocupacion2 = result.ocupacion;
            this.perfil.ocupasubcat2 = result.ocupasubcat;
            // this.cl.ocupacionpld = result.ocupacionpld;
          }
        }, (error) => {
          console.log(error);
        });
      }





}