import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class productosDeCreditoService {

  constructor(private http: HttpClient) {}

  // Regresa el Listado de Productos de credito pot tipo de producto
  public productoDeCreditoGet(cdgtpc : string, desglosepago: string, validohasta: string): Observable<any> {
    return this.http.post('api/general/pcr/get', {cdgtpc : cdgtpc, desglosepago: desglosepago, validohasta: validohasta}).pipe(
      map(res => {
        return JSON.parse(JSON.stringify(res));
      })
    );
  }

}
