import { DynamicInputControlModel, DynamicInputControlModelConfig } from "../dynamic-input-control.model";
import { DynamicFormControlLayout } from "../misc/dynamic-form-control-layout.model";
import { serializable } from "../../decorator/serializable.decorator";
import { isBoolean } from "../../utils/core.utils";
import { Observable, isObservable, of } from "rxjs";
import { tap } from "rxjs/operators";

export const DYNAMIC_FORM_CONTROL_TYPE_INPUT = "INPUT";

export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_HIDDEN = "hidden";

export interface DynamicHiddenModelConfig extends DynamicInputControlModelConfig<string | number | Date | string[]> {

    list?: any[] | Observable<any[]>;
    multiple?: boolean;
}

export class DynamicHiddenModel extends DynamicInputControlModel<string | number | Date | string[]> {

    @serializable() inputType: string;
    list$: Observable<any[]> | null = null;
    @serializable() multiple: boolean | null;

    @serializable("list") private _list: any[] | null = null;
    private readonly _listId: string | null = null;

    @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_INPUT;

    constructor(config: DynamicHiddenModelConfig, layout?: DynamicFormControlLayout) {

        super(config, layout);

        this.inputType = DYNAMIC_FORM_CONTROL_INPUT_TYPE_HIDDEN;
        this.multiple = isBoolean(config.multiple) ? config.multiple : null;

        if (config.list !== undefined) {

            this.list = config.list;
            this._listId = `${this.id}List`;
        }
    }

    get listId(): string | null {
        return this._listId;
    }

    get hasList(): boolean {
        return isObservable(this.list$);
    }

    set list(list: any[] | Observable<any[]> | null) {

        if (Array.isArray(list)) {

            this._list = list;
            this.list$ = of(this._list);

        } else if (isObservable(list)) {

            this.list$ = list.pipe(tap(list => this._list = list));

        } else {

            this._list = null;
            this.list$ = null;
        }
    }
}
